import * as types from '../actions/types';

const initialState = {
  isError: false,
  cancelDate: {},
  isRefresh: false
};

const cancelDateReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.CANCEL_DATE_REQUESTED:
      return {
        ...state,
        error: null
      };
    case types.CANCEL_DATE_SUCCEEDED:
      return {
        ...state,
        cancelDate: action.data || {},
        error: null
      };
    case types.CANCEL_DATE_FAILED:
      return {
        ...state,
        isError: true,
        error: action.error
      };
    case types.HIDE_ERROR:
      return {
        ...state,
        isError: false
      };
    default:
      return state;
  }
};

export default cancelDateReducer;

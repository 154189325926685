import { call, put, takeLatest } from 'redux-saga/effects';
import * as types from '../actions/types';
import { requestAPI } from '../services/request';
import { API } from '../services/api';
import { ResponseStatus } from '../../constants/constant';
import { store } from '../store';
import { showLoading, hideLoading } from '../actions';

function* loginFacebok(action) {
  let response = null;
  store.dispatch(showLoading());
  try {
    response = yield call(requestAPI, API.API_LOGIN, API.POST, action.params);
    if (
      response.status === ResponseStatus.SUCCESS ||
      response.status === ResponseStatus.INCOMPLETE_PROFILE_USER ||
      response.status === ResponseStatus.PROFILE_AWAITING_USER
    ) {
      yield put({
        type: types.CURRENT_USER_LOGIN_FACEBOOK_SUCCEEDED,
        data: response.data
      });
    } else if (response.status === ResponseStatus.FAILURE) {
      yield put({
        type: types.CURRENT_USER_LOGIN_FACEBOOK_FAILED,
        error: response
      });
    } else {
      yield put({
        type: types.CURRENT_USER_LOGIN_FACEBOOK_FAILED,
        error: response
      });
    }

    if (action.onComplete) {
      action.onComplete(response);
    }
  } catch (error) {
    alert(error.message);
    yield put({ type: types.CURRENT_USER_LOGIN_FACEBOOK_FAILED, error });
  }
  store.dispatch(hideLoading());
}

function* reActivateReApprove(action) {
  let response = null;
  store.dispatch(showLoading());
  try {
    response = yield call(
      requestAPI,
      API.API_REACTIVATE_RE_APPROVE,
      API.POST,
      action.params
    );

    if (response.status === ResponseStatus.SUCCESS) {
      yield put({
        type: types.REACTIVATION_RE_APPROVE_SUCCEEDED,
        data: response.data
      });
    } else {
      yield put({
        type: types.REACTIVATION_RE_APPROVE_FAILED,
        error: response
      });
    }
    if (action.onComplete) {
      action.onComplete(response);
    }
  } catch (error) {
    yield put({ type: types.REACTIVATION_RE_APPROVE_FAILED, error });
  }
  store.dispatch(hideLoading());
}

export function* watchLoginFacebook() {
  yield takeLatest(types.CURRENT_USER_LOGIN_FACEBOOK_REQUESTED, loginFacebok);
}

export function* watchReActivationReApprove() {
  yield takeLatest(types.REACTIVATION_RE_APPROVE_REQUEST, reActivateReApprove);
}

function* getFacebook(action) {
  const accessToken = action.accessToken;
  const gender = action.gender;

  let response = null;
  store.dispatch(showLoading());
  try {
    response = yield call(
      requestAPI,
      'https://graph.facebook.com/v2.5/me?fields=email,name,first_name,last_name,gender',
      API.GET,
      action.params
    );

    yield put({
      type: types.CURRENT_USER_LOGIN_FACEBOOK_INFO_SUCCEEDED
    });

    if (action.onComplete) {
      action.onComplete(response);
    }
  } catch (error) {
    alert(error.message);
    yield put({ type: types.CURRENT_USER_LOGIN_FACEBOOK_FAILED, error });
  }
  store.dispatch(hideLoading());
}

export function* watchGetFacebook() {
  yield takeLatest(
    types.CURRENT_USER_LOGIN_FACEBOOK_INFO_REQUESTED,
    getFacebook
  );
}

// XXX: For testing purposes only
function* logoutFacebook(action) {
  let response = null;
  store.dispatch(showLoading());
  try {
    response = yield call(requestAPI, API.API_LOGOUT, API.GET, action.params);

    if (response.status === ResponseStatus.SUCCESS) {
      yield put({
        type: types.CURRENT_USER_LOGOUT_FACEBOOK_SUCCEEDED,
        data: response.data
      });
    } else if (response.status === ResponseStatus.FAILURE) {
      yield put({
        type: types.CURRENT_USER_LOGOUT_FACEBOOK_FAILED,
        error: response
      });
    } else {
      yield put({
        type: types.CURRENT_USER_LOGOUT_FACEBOOK_FAILED,
        error: response
      });
    }
    if (action.onComplete) {
      action.onComplete(response);
    }
  } catch (error) {
    alert(error.message);
    yield put({ type: types.CURRENT_USER_LOGOUT_FACEBOOK_FAILED, error });
  }
  store.dispatch(hideLoading());
}

export function* watchLogoutFacebook() {
  yield takeLatest(
    types.CURRENT_USER_LOGOUT_FACEBOOK_REQUESTED,
    logoutFacebook
  );
}

//SMS login

function* smsSendCode(action) {
  let response = null;
  store.dispatch(showLoading());
  try {
    response = yield call(
      requestAPI,
      API.API_SMS_SEND_CODE,
      API.POST,
      action.params,
      false
    );

    if (
      response.status === ResponseStatus.SUCCESS ||
      response.status === ResponseStatus.INCOMPLETE_PROFILE_USER ||
      response.status === ResponseStatus.PROFILE_AWAITING_USER
    ) {
      yield put({
        type: types.SMS_LOGIN_SEND_CODE_SUCCEEDED,
        data: response.data
      });
    } else if (response.status === ResponseStatus.FAILURE) {
      yield put({
        type: types.SMS_LOGIN_SEND_CODE_FAILED,
        error: response
      });
    } else {
      yield put({
        type: types.SMS_LOGIN_SEND_CODE_FAILED,
        error: response
      });
    }
    if (action.onComplete) {
      action.onComplete(response);
    }
  } catch (error) {
    alert(error.message);
    yield put({ type: types.SMS_LOGIN_SEND_CODE_FAILED, error });
  }
  store.dispatch(hideLoading());
}

export function* watchSMSSendCode() {
  yield takeLatest(types.SMS_LOGIN_SEND_CODE_REQUESTED, smsSendCode);
}

function* smsVerificationCode(action) {
  let response = null;
  const showGeneralPopup = false;
  store.dispatch(showLoading());
  try {
    response = yield call(
      requestAPI,
      API.API_SMS_VERIFICATION_CODE,
      API.POST,
      action.params,
      showGeneralPopup
    );

    if (
      response.status === ResponseStatus.SUCCESS ||
      response.status === ResponseStatus.INCOMPLETE_PROFILE_USER ||
      response.status === ResponseStatus.PROFILE_AWAITING_USER
    ) {
      yield put({
        type: types.SMS_LOGIN_VERIFICATION_CODE_SUCCEEDED,
        data: {
          ...response.data
        }
      });
    } else if (response.status === ResponseStatus.FAILURE) {
      yield put({
        type: types.SMS_LOGIN_VERIFICATION_CODE_FAILED,
        error: response
      });
    } else {
      yield put({
        type: types.SMS_LOGIN_VERIFICATION_CODE_FAILED,
        error: response
      });
    }
    if (action.onComplete) {
      action.onComplete(response);
    }
  } catch (error) {
    alert(error.message);
    yield put({ type: types.SMS_LOGIN_VERIFICATION_CODE_FAILED, error });
  }
  store.dispatch(hideLoading());
}

export function* watchSmsVerificationCode() {
  yield takeLatest(
    types.SMS_LOGIN_VERIFICATION_CODE_REQUESTED,
    smsVerificationCode
  );
}

import React from 'react';
import Popup from '../../components/Popup';
import classnames from 'classnames';
import {
  ButtonComponent,
  TitleComponent,
  MessagesComponent
} from '../../components/ChatFeature/CafeMessagePopup';
import {
  ButtonComponent as MeetingTimeButton,
  MessagesComponent as MeetingTimeMessages
} from '../../components/ChatFeature/MeetingTimePopup';
import { CancelDateMessagesComponent } from '../../components/MatchProfile/ConfirmCancelDatePopup';
import { MessagesComponent as StillUnreadMessages } from '../../components/ChatFeature/StillUnreadPopup';
import { MessagesComponent as CancelMessages } from '../../components/ChatFeature/CancelPopup';
import { MessagesComponent as AfterFivePmMessages } from '../../components/ChatFeature/AfterFivePmPopup';
import { MessagesComponent as GotCancelledMessages } from '../../components/ChatFeature/GotCancelledPopup';
import { TitleComponent as UnavailableTitleComponent } from '../../components/ChatFeature/UnavailableChatPopup';
import { withRouter } from 'react-router-dom';
import { Trans, withNamespaces } from 'react-i18next';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import { path, isEmpty, pathOr, last } from 'ramda';
import {
  chatRoom as requestChatRoom,
  getMatchDetail as requestMatchDetail,
  getCafe as requestGetCafeSuggestion,
  getChatMessages as requestChatMessages,
  showLoading,
  hideLoading,
  chatHistory,
  cancelDate,
  cancelDateByPartner,
  participateRematch,
  getCurrentServerTime,
  getMatchProfileDetail,
  getDatingDayAbleToChat,
  requestUserGlobalInfo,
  accessChatRoom
} from '../../redux/actions';
import ChatFeature from '../../components/ChatFeature';
import { getPartnerProfileImage, hasChatRoomMessage } from './helpers';
import TwilioChatManager from './twilioChatManager';
import {
  ChatFeature as ChatFeatureConstants,
  redirectVideoDateSuggestion,
  UserGender,
  TrialStatus,
  ResponseStatus,
  PaymentGateway
} from '../../constants/constant';
import './styles.css';
import styles from '../../components/CancellationApplication/index.module.css';
import {
  convertMatchingDayToJapanese,
  timeNow,
  checkDatingDayBefore,
  formatMatchProfile
} from '../../utils';
import moment from 'moment';
import { matchDataMock } from '../../__mocks__/data';

const POPUP_CAFE_INFORMATION = 1;
const POPUP_MEETING_TIME = 2;
const POPUP_STILL_UNREAD = 3;
const POPUP_CONFIRM_SCHEDULE = 4;
const POPUP_CANCEL = 5;
const POPUP_AFTER_FIVE_PM = 6;
const POPUP_UNAVAILABLE_TIME = 7;
const POPUP_TRANSFER_TICKET = 8;
const POPUP_WANT_CANCEL = 9;
const POPUP_GOT_CANCELLED = 10;
const POPUP_GOT_CANCEL_SUCCESS = 11;
const POPUP_WANT_TO_CANCEL_BY_OTHER = 12;
const POPUP_PASSED_REMATCH_TIME = 13;
const POPUP_ERROR = 14;
const POPUP_SUCCESS_USE_COUPON_PARTNER_CANCEL = 15;
const POPUP_GOT_REMATCHING = 16;
const POPUP_COMPLETE_REMATCHING = 17;

const {
  USER_TYPE_BASE_USER,
  USER_TYPE_PARTNER_USER,
  USER_TYPE_CAFE_INFO,
  USER_TYPE_CAFE_SUGGESTION,
  USER_TYPE_PARK_SUGGESTION,
  USER_TYPE_VIDEO_SUGGESTION,
  USER_TYPE_CAFE_SUGGESTION_LINK,
  USER_TYPE_VIDEO_SUGGESTION_LINK,
  LOCATION_CATEGORY_PARK
} = ChatFeatureConstants;

let datingId = null;

class ChatFeatureContainer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      popupCafeInformationVisible: false,
      popupMeetingTimeVisible: false,
      popupStillUnread: false,
      popupConfirmSchedule: false,
      popupCancel: false,
      popupAfterFivePm: false,
      popupUnavailableTime: false,
      popupERROR: false,
      popupTransferTicket: false,
      popupWantCancelPopup: false,
      popupGotCancelled: false,
      popupGotCancelSuccess: false,
      popupConfirmGotCancelled: false,
      popupSuccessUseCouponPartnerCancel: false,
      popupOneMoreTrial: false,
      popupPassedRematchTime: false,
      // CHAT related
      bachelorChatManager: null,
      chatRoomMessages: [],
      partnerData: null,
      baseUserIsPresent: false,
      hasFirstBachelorMessage: false,
      shouldPaginate: false,
      shouldScrollToLatestMessage: false,
      userStatus: false,
      chatRoomIsReady: false,
      allowRematchingApplication: false,
      // Cafe type
      typeCafe: LOCATION_CATEGORY_PARK,
      matchData: null,
      matchingDay: [],
      chatfeatureHeaderContent: '',
      chatfeatureHeaderLine: false,
      popupWantToCancelByOther: false,
      popupWantRematching: false,
      popupCompleteRematching: false,
      isTrialOrCoupon: false,
      isRematching: false,
      currentServerTime: '',
      beforeReMatchingTime: true,
      trialStatus: TrialStatus.ACTIVE,
      isWhiteMode: false,
      dateTimeText: '',
      isLunchTime: false,
      matchingDateData: {},
      isShowUnreadMessage: true
    };

    this.chatFeatureRef = React.createRef();
  }

  componentDidMount = async () => {
    const isWhiteMode = JSON.parse(localStorage.getItem('isWhiteMode'));
    this.setState({
      isWhiteMode: isWhiteMode
    });
    this.requestGetDatingDayAbleToChatAPI();
    this.getUserGlobalInfo();
    this.requestGetCurrentServerTimeAPI();
  };

  validateChatRoom = () => {
    // TODO:
    const roomId = path(['room_id', 'data'], this.props.roomData);

    const matchData = this.props.matchData;

    const datingDay = this.state.matchingDay;
    // const datingDay = path(['location', 'state', 'matchingDate'], this.props);
    this.setState({ matchData: matchData });
    const matchingDate = formatMatchProfile(this.props.matchDataChat)
      .weeks.reduce((acc, key) => [...acc, ...key], [])
      .find(matchDay => matchDay.dating_day_id === datingDay.dating_day_id);
    this.setState({
      matchingDateData: matchingDate
    });
    //
    if (datingDay) {
      const { datetime_text, is_lunch_time } = datingDay;
      this.setState({
        dateTimeText: datetime_text,
        isLunchTime: is_lunch_time
      });
      const checkDayBefore = checkDatingDayBefore(datetime_text);
      const chatHeaderText = checkDayBefore
        ? this.props.t('chat-message-header-bf-date')
        : `${moment().format('hh:mm')} ${this.props.t(moment().format('A'))}`;
      this.setState({ chatfeatureHeaderContent: chatHeaderText });
      if (checkDayBefore) {
        this.setState({ chatfeatureHeaderLine: true });
      }
      datingId = datingDay.dating_id;
      const { partner_details, dating_status, partner_id } = datingDay;

      // If dating_id == null, the chat is not available, then redirect to home screen
      if (!datingId) {
        this.openPopup(POPUP_UNAVAILABLE_TIME);
        return;
      }

      // If a date is not confirmed, the chat is not available, then redirect to home screen
      // if (dating_status !== MatchDetails.STATUS_DATING_MATCHED_CONFIRM) {
      //   this.openPopup(POPUP_UNAVAILABLE_TIME);
      //   return;
      // }

      const params = {
        user_id: this.props.userData.id,
        partner_id: partner_id,
        dating_id: datingId,
        room_id: roomId || ''
      };

      // TODO:
      // Check available chat time
      // If chat time is not available, redirect to homescreen page
      // const current_server_time = matchData.current_server_time;
      const current_server_time = timeNow(
        moment.utc(matchData.current_server_time).toDate()
      );
      const { chat_start_time, chat_end_time } = datingDay;

      this.setState({
        partnerData: partner_details
      });
      //chat_id is user_id of partner user
      const chatId = path(['id'], this.props.userData);
      const roomId = this.state.roomId;
      const token = pathOr(null, ['token'], this.props.chatRoomData);

      const options = {
        chatRoomData: {
          user_id: chatId,
          room_id: roomId,
          user_token: token
        }
      };

      console.log(options);

      // const bachelorChatManager = new BachelorChatManager(options);
      const bachelorChatManager = new TwilioChatManager(options);
      this.setState(
        {
          bachelorChatManager
        },
        () => {
          this.handleChatManager();
        }
      );
      // return;
    } else {
      this.openPopup(POPUP_UNAVAILABLE_TIME);
    }
    // });
  };

  shouldComponentUpdate = (nextProps, nextState) => {
    return this.state.userStatus === nextState.userStatus;
  };

  checkBeforeFivePm = currentServerTime => {
    const currentTime = new Date(currentServerTime);
    const fivePMTime =
      currentTime.getFullYear() +
      '-' +
      (currentTime.getMonth() + 1) +
      '-' +
      currentTime.getDate() +
      ' ' +
      '17:00:00';
    return currentServerTime < fivePMTime;
  };

  initFirstBachelorMessage = datingInformation => {
    if (!datingInformation && this.state.hasFirstBachelorMessage) return;
    const { t, lng } = this.props;
    const {
      day_of_week,
      datetime_text,
      dating_place_phone,
      dating_place_name,
      dating_place_station,
      dating_place_link,
      dating_place_category
    } = datingInformation;
    const convertDay =
      lng === 'jp'
        ? convertMatchingDayToJapanese(day_of_week, true)
        : `(${day_of_week.substring(0, 3)})`;
    const dtLength = datetime_text.length;
    const datingDay = datetime_text.substring(0, 5);
    const datingTime = datetime_text.substring(dtLength - 9, dtLength - 4);
    const space = lng === 'jp' ? '' : ' ';
    const checkDayBefore = checkDatingDayBefore(
      this.state.currentServerTime,
      datetime_text
    );
    const combineDateTime = checkDayBefore
      ? `${datingDay}${space}${convertDay}${space}${datingTime}`
      : datingTime;
    const cafeInfoMessage = {
      userType: USER_TYPE_CAFE_INFO,
      data: {
        datingDateTime: t(
          checkDayBefore
            ? 'cafe-info-message-line-1-bf-date'
            : 'cafe-info-message-line-1-on-date',
          { datingDateTime: combineDateTime }
        ),
        cafeName: dating_place_name,
        cafePhone: dating_place_phone,
        stationName: dating_place_station,
        cafeLink: dating_place_link,
        cafeCat: dating_place_category
      }
    };

    const cafeSuggestionLinkMessage = {
      userType: USER_TYPE_CAFE_SUGGESTION_LINK,
      data: {
        text:
          this.state.typeCafe === LOCATION_CATEGORY_PARK
            ? t('chat-link-park-suggestion')
            : t('chat-link-cafe-suggestion'),
        onChatLinkClick: this.onCafeSuggestionLinkClick
      }
    };

    const videoSuggestionLinkMessage = {
      userType: USER_TYPE_VIDEO_SUGGESTION_LINK,
      data: {
        videoMessage: t('chat-link-video-suggestion'),
        onVideoLinkClick: this.onVideoSuggestionLinkClick
      }
    };

    this.setState(prevState => {
      return {
        hasFirstBachelorMessage: true,
        chatRoomMessages: [
          cafeInfoMessage,
          cafeSuggestionLinkMessage,
          videoSuggestionLinkMessage,
          ...prevState.chatRoomMessages
        ]
      };
    });
  };

  initCafeInfoMessage = datingInformation => {
    if (!datingInformation) return;

    const cafeInfoMessage = {
      userType: USER_TYPE_CAFE_INFO,
      data: {
        datingTime: pathOr('', ['time'], datingInformation),
        cafeName: pathOr('', ['cafe_details', 'name'], datingInformation),
        cafePhone: pathOr(
          '',
          ['cafe_details', 'display_phone'],
          datingInformation
        )
      }
    };

    this.setState(prevState => {
      return {
        chatRoomMessages: [...prevState.chatRoomMessages, cafeInfoMessage]
      };
    });
  };

  initCafeSuggestionMessage = data => {
    if (!data) return;

    const cafeSuggestionMessage = {
      userType:
        this.state.typeCafe === LOCATION_CATEGORY_PARK
          ? USER_TYPE_PARK_SUGGESTION
          : USER_TYPE_CAFE_SUGGESTION,
      data: {
        text1: data.text1,
        text2: data.text2,
        text3: data.text3,
        cafeList: data.cafeData
      }
    };

    this.setState(prevState => {
      return {
        shouldScrollToLatestMessage: true,
        chatRoomMessages: [...prevState.chatRoomMessages, cafeSuggestionMessage]
      };
    });
  };

  initCafeSuggestionLink = () => {
    const { t } = this.props;

    const cafeSuggestionLinkMessage = {
      userType: USER_TYPE_CAFE_SUGGESTION_LINK,
      data: {
        text:
          this.state.typeCafe === LOCATION_CATEGORY_PARK
            ? t('chat-link-park-suggestion')
            : t('chat-link-cafe-suggestion'),
        onChatLinkClick: this.onCafeSuggestionLinkClick
      }
    };

    this.setState(prevState => {
      return {
        shouldScrollToLatestMessage: true,
        chatRoomMessages: [
          ...prevState.chatRoomMessages,
          cafeSuggestionLinkMessage
        ]
      };
    });
  };

  initVideoSuggestionMessage = () => {
    const { t } = this.props;

    const videoSuggestionMessage = {
      userType: USER_TYPE_VIDEO_SUGGESTION,
      data: {
        videoSuggestion: '',
        onClickVideoMessage: this.onVideoMessageLinkClick
      }
    };

    this.setState(prevState => {
      return {
        shouldScrollToLatestMessage: true,
        chatRoomMessages: [
          ...prevState.chatRoomMessages,
          videoSuggestionMessage
        ]
      };
    });
  };

  onCafeSuggestionLinkClick = async () => {
    if (!(await this.state.bachelorChatManager.isCancelDating())) {
      if (!datingId) return;

      let params = {
        query: {
          dating_id: datingId
        }
      };

      if (this.state.typeCafe === LOCATION_CATEGORY_PARK) {
        params.query.category = LOCATION_CATEGORY_PARK;
      }

      this.props.requestGetCafeSuggestion(params, response => {
        if (!isEmpty(response)) {
          this.initCafeSuggestionMessage(response.data.data);
        }
      });
    } else {
      this.props.history.push('/matchprofile');
    }
  };

  onVideoSuggestionLinkClick = async () => {
    if (!(await this.state.bachelorChatManager.isCancelDating())) {
      if (!datingId) return;
      this.initVideoSuggestionMessage();
    } else {
      this.props.history.push('/matchprofile');
    }
  };

  onVideoMessageLinkClick = () => {
    redirectVideoDateSuggestion(this.props.userGender);
  };

  onUnreadClick = () => {
    this.openPopup(POPUP_STILL_UNREAD);
  };

  closeAvailableTimePopup = () => {
    this.props.history.push('/');
  };

  handleChatManager = async () => {
    const chatMessages = []; // sort by id lowest to highest (oldest to newest)
    const LIMIT = '20';

    if (chatMessages.length < LIMIT || chatMessages.length === 0) {
      this.setState({ shouldPaginate: false });
      const datingDay = this.state.matchingDay;
      // this.props.getMatchProfileDetail({ id: datingDay.dating_id }, response => {
      //   const matchData = response.data;
      // });
      // this.initData(matchData);
      this.initFirstBachelorMessage(datingDay);
    }

    const { bachelorChatManager } = this.state;
    if (!bachelorChatManager.isConnected) {
      await bachelorChatManager.connect();

      const params = {
        query: {
          room_id: bachelorChatManager.chatRoomData.room_id,
          partner_id: this.state.partnerId,
          user_id: this.props.userData.id,
          direction: 'older',
          limit: LIMIT
        }
      };
      await new Promise(resolve => {
        //Will make webapp broken when calling
        this.props.requestChatMessages(params, resolve);
      });

      this.setState({ isShowUnreadMessage: false });

      // Get list old messages
      await this._getListOldMessages();

      bachelorChatManager.subscribeToRoom({
        messageAdded: this.onChatMessageAdded,
        userUpdated: this.onUserUpdated,
        memberUpdated: this.onChatMessageUpdated
      });

      if (path(['location', 'state', 'fromMyTime'], this.props) !== undefined) {
        this.props.history.replace({
          ...this.props.history.location,
          state: null
        });
        this.openPopup(POPUP_MEETING_TIME);
      }

      this.setState(prevState => {
        return {
          chatRoomIsReady: true,
          shouldScrollToLatestMessage: true,
          shouldPaginate: !this.state.hasFirstBachelorMessage,
          currentOldestMessageId:
            chatMessages.length === 0 ? '' : chatMessages[0].id,
          chatRoomMessages: [
            ...prevState.chatRoomMessages,
            ...chatMessages.map(msg => {
              return {
                ...msg,
                avatar:
                  `${msg.user_id}` === bachelorChatManager.baseUserId
                    ? null
                    : getPartnerProfileImage(this.state.partnerData),
                userType:
                  `${msg.user_id}` === bachelorChatManager.baseUserId
                    ? USER_TYPE_BASE_USER
                    : USER_TYPE_PARTNER_USER,
                onUnreadClick: this.onUnreadClick,
                shouldBlurIcon: !!path(['profileImage'], this.state.partnerData)
              };
            })
          ]
        };
      });
      this.setupListeners();
    }
  };

  setupListeners = () => {
    // handle when user is viewing chat room session
    window.addEventListener('focus', async () => {
      console.log('window was focused');
      this._readLastMessage();
    });

    // handle when user is not viewing chat room session
    window.addEventListener('blur', () => {
      console.log('window was blurred');
    });
  };

  _getListOldMessages = async () => {
    let messages = await this.state.bachelorChatManager.getMessages();
    if (messages.length) {
      let oldMessages = messages
        .map((msg, i) =>
          this._getIncomingMessage(msg, i === 0 ? null : messages[i - 1])
        )
        .filter(msg => !!msg);

      if (oldMessages.length) {
        this.setState(
          prevState => {
            return {
              chatRoomMessages: [...prevState.chatRoomMessages, ...oldMessages],
              shouldScrollToLatestMessage: true
            };
          },
          () => {
            // set cursor position to latest message
            if (document.hasFocus()) {
              this._readLastMessage();
            }
          }
        );
      }
    }
  };

  _readLastMessage = () => {
    let { bachelorChatManager, chatRoomMessages } = this.state;
    let lastMessage = last(chatRoomMessages);
    let { index, userType } = lastMessage;

    // Just only update read message of other
    if (userType === USER_TYPE_PARTNER_USER) {
      bachelorChatManager.setReadCursor(index);
    }
  };

  _getIncomingMessage = (data, beforeMsg, useStateMessage = false) => {
    const { index, sid, author, body, timestamp } = data;
    const { bachelorChatManager, chatRoomMessages } = this.state;

    if (hasChatRoomMessage(sid, chatRoomMessages)) {
      return;
    }

    if (!(timestamp instanceof Date)) {
      return;
    }

    const { partnerData } = this.state;
    const { lng } = this.props;

    let partnerLastConsumedMessageIndex = pathOr(
      null,
      ['partnerLastConsumedMessageIndex'],
      bachelorChatManager
    );

    const getUserType = author =>
      author === bachelorChatManager.baseUserId
        ? USER_TYPE_BASE_USER
        : USER_TYPE_PARTNER_USER;

    const currentUserType = getUserType(author);

    const locale = lng === 'jp' ? 'ja-JA' : 'en-US';
    const dateOptions = {
      weekday: 'long',
      year: 'numeric',
      month: 'long',
      day: 'numeric'
    };
    const timeOptions = { hour: 'numeric', minute: 'numeric' };

    let createDate = timestamp.toLocaleDateString(locale, dateOptions);
    let createTime = timestamp.toLocaleTimeString(locale, timeOptions);
    if (beforeMsg) {
      const bfMsgTimestamp = useStateMessage
        ? beforeMsg.createAt
        : beforeMsg.timestamp;
      const bfMsgUserType = useStateMessage
        ? beforeMsg.userType
        : getUserType(beforeMsg.author);

      // create date will show when there is no message before or this message starts at new day
      createDate =
        moment(timestamp, 'MM/DD/YYYY').diff(
          moment(bfMsgTimestamp, 'MM/DD/YYYY'),
          'days'
        ) >= 1
          ? createDate
          : null;

      // create time will show when there exists `createDate` or this message's userType is different with before one's
      createTime =
        createDate || bfMsgUserType !== currentUserType ? createTime : null;
    }

    const incomingMessage = {
      index: index,
      id: sid,
      text: body,
      createAt: timestamp,
      createDate,
      createTime,
      avatar:
        author === bachelorChatManager.baseUserId
          ? null
          : getPartnerProfileImage(partnerData),
      userType: currentUserType,
      onUnreadClick: this.onUnreadClick,
      shouldBlurIcon: !!path(['profileImage'], partnerData),
      read: partnerLastConsumedMessageIndex
        ? index <= partnerLastConsumedMessageIndex
        : false
    };

    return incomingMessage;
  };

  onChatMessageAdded = data => {
    const chatMsg = this.state.chatRoomMessages.filter(
      msg =>
        msg.userType == USER_TYPE_BASE_USER ||
        msg.userType == USER_TYPE_PARTNER_USER
    );

    const incomingMessage = this._getIncomingMessage(data, last(chatMsg), true);

    this.setState(
      prevState => {
        return {
          chatRoomMessages: [...prevState.chatRoomMessages, incomingMessage],
          shouldScrollToLatestMessage: true
        };
      },
      () => {
        // set cursor position to latest message
        if (document.hasFocus()) {
          this._readLastMessage();
        }
      }
    );
  };

  onChatMessageUpdated = data => {
    const { updateReasons, member } = data;
    if (
      updateReasons &&
      updateReasons.indexOf('lastConsumedMessageIndex') !== -1
    ) {
      // set cursor position to latest message when partner read messages
      const updatedMessages = this.state.chatRoomMessages.map(message => {
        if (message.index <= member.lastConsumedMessageIndex) {
          return { ...message, read: true };
        }
        return message;
      });

      this.setState({ chatRoomMessages: updatedMessages });
    }
  };

  onPresenceChanged = (state, user) => {
    this.setState({
      userStatus: state.current === 'online'
    });
  };

  onUserUpdated = data => {
    // Listen when user status update to online
    const { updateReasons, user } = data;
    if (updateReasons && updateReasons.indexOf('online')) {
      this.setState({
        userStatus: user.online === true
      });
    }
  };

  saveChatHistory = message => {
    const { bachelorChatManager } = this.state;
    const params = {
      room_id: bachelorChatManager.chatRoomData.room_id,
      message: message
    };
    //Send message
    this.props.chatHistory(params);
    // this.props.chatHistory(params);
  };

  fetchMoreMessages = async () => {
    const LIMIT = '20';
    const {
      bachelorChatManager,
      currentOldestMessageId,
      shouldPaginate
    } = this.state;

    if (!bachelorChatManager.isConnected || !shouldPaginate) {
      return;
    }
    const params = {
      query: {
        room_id: bachelorChatManager.chatRoomData.room_id,
        message_id: currentOldestMessageId,
        direction: 'older',
        limit: LIMIT
      }
    };
    this.props.showLoading();
    const response = await new Promise(resolve => {
      this.props.requestChatMessages(params, resolve);
    });

    const newMessages = response.data.sort((a, b) => a.id - b.id); // sort by id lowest to highest (oldest to newest)

    this.setState(
      prevState => {
        return {
          shouldScrollToLatestMessage: false,
          currentOldestMessageId:
            newMessages.length === 0 ? '' : newMessages[0].id,
          chatRoomMessages: [
            // TODO: Tidy this up / Make this cleaner
            ...newMessages.map(msg => {
              return {
                ...msg,
                avatar:
                  `${msg.user_id}` === bachelorChatManager.baseUserId
                    ? null
                    : getPartnerProfileImage(this.state.partnerData),
                userType:
                  `${msg.user_id}` === bachelorChatManager.baseUserId
                    ? USER_TYPE_BASE_USER
                    : USER_TYPE_PARTNER_USER,
                onUnreadClick: this.onUnreadClick,
                shouldBlurIcon: !!path(['profileImage'], this.state.partnerData)
              };
            }),
            ...prevState.chatRoomMessages
          ]
        };
      },
      () => {
        this.props.hideLoading();
      }
    );

    if (newMessages.length < LIMIT || newMessages.length === 0) {
      const datingDay = this.state.matchingDay;
      this.setState({ shouldPaginate: false }, () => {
        this.initFirstBachelorMessage(datingDay);
      });
    }
  };

  onConfirmTrialOrCouponProcess = () => {
    const { userGender, hasAppliedSubscription } = this.props;

    if (userGender === UserGender.MALE) {
      hasAppliedSubscription
        ? this.setState({
            popupSuccessUseCouponPartnerCancel: true,
            popupWantToCancelByOther: false
          })
        : this.setState({
            popupOneMoreTrial: true,
            popupWantToCancelByOther: false
          });
    } else {
      // User is female and "bachelor coupon"
      this.setState({
        popupSuccessUseCouponPartnerCancel: true,
        popupWantToCancelByOther: false
      });
    }
  };

  onSuccessTrialOrCouponWhenCancelledClick = gender => {
    this.props.history.push('/participation');
  };

  requestCancelDateByPartnerAPI = () => {
    const datingDay = this.state.matchingDay;
    const { dating_id } = datingDay;
    const params = {
      datingId: dating_id,
      requestRematching: this.state.isRematching, // 1 || 0 ==> true || false in PHP
      paymentGateway: PaymentGateway.STRIPE
    };
    if (this.state.isRematching) {
      this.openPopup(POPUP_GOT_REMATCHING);
    } else {
      this.props.cancelDateByPartner(params, response => {
        if (response.status === ResponseStatus.SUCCESS) {
          // trial extend or use coupon
          if (this.state.isTrialOrCoupon) {
            this.onConfirmTrialOrCouponProcess();
          }
          this.state.bachelorChatManager.cancelDating();
        }
      });
    }
  };

  requestRematchingAPI = () => {
    const datingDay = this.state.matchingDay;
    const { dating_id, dating_day_id } = datingDay;
    const paramsParticipateRematch = {
      datingDayId: dating_day_id
    };

    const paramsCancelDateByPartner = {
      datingId: dating_id,
      requestRematching: this.state.isRematching, // 1 || 0 ==> true || false in PHP
      paymentGateway: PaymentGateway.STRIPE
    };

    this.props.cancelDateByPartner(paramsCancelDateByPartner, response => {
      if (response.status === ResponseStatus.SUCCESS) {
        this.state.bachelorChatManager.cancelDating();
        this.props.participateRematch(paramsParticipateRematch, response => {
          if (response.status === ResponseStatus.SUCCESS) {
            this.setState({ popupWantRematching: false });
            this.openPopup(POPUP_COMPLETE_REMATCHING);
          }
        });
      }
    });
  };

  requestGetCurrentServerTimeAPI = () => {
    this.props.getCurrentServerTime({}, response => {
      if (response.status === ResponseStatus.SUCCESS) {
        const isBeforeRematch =
          this.state.matchingDay.rematching_time >
          response.data.data.current_server_time;
        this.setState({
          currentServerTime: response.data.data.current_server_time,
          beforeReMatchingTime: isBeforeRematch
        });
      }
    });
  };

  handleResponseAbleToChat = response => {
    if (response.status === ResponseStatus.SUCCESS) {
      const { data } = response.data;
      this.setState({
        roomId: data.room_id,
        partnerId: data.partner_id
      });
      if (Object.keys(data).length > 0) {
        this.setState({ matchingDay: data });
        this.validateChatRoom();
      } else {
        this.props.history.push('/matchprofile');
      }
    }
  };

  requestGetDatingDayAbleToChatAPI = () => {
    const pathname = this.props.location.pathname;
    const dayOnSegment = pathname.substring(pathname.lastIndexOf('/') + 1);
    const dataTmp = path(['location', 'state', 'matchingDate'], this.props);
    let params = dataTmp
      ? {
          datingDayOfWeek: dayOnSegment,
          roomId: dataTmp.chat_id,
          datingDayId: dataTmp.dating_day_id
        }
      : { datingDayOfWeek: dayOnSegment };
    const url = window.location.href;
    if (url.includes('chat/room/')) {
      params = {
        ...params,
        id: url.split('/').pop()
      };
      this.props.accessChatRoom(params, response => {
        this.handleResponseAbleToChat(response);
      });
    } else {
      this.props.getDatingDayAbleToChat(params, response => {
        this.handleResponseAbleToChat(response);
      });
    }
  };

  getUserGlobalInfo = () => {
    this.props.requestUserGlobalInfo({}, response => {
      if (response.status === ResponseStatus.SUCCESS) {
        const { data } = response.data;
        if (data.trial_status) {
          this.setState({
            trialStatus: data.trial_status
          });
        }
      }
    });
  };

  onCancelSuccess = () => {
    const datingDay = this.state.matchingDay;

    if (this.state.allowRematchingApplication) {
      this.props.requestMatchDetail();
      this.props.history.push(
        `/12pm-rematching-application?id=${datingDay.dating_day_id}`,
        {
          allowOpenBecausePartnerCancel: true,
          is_lunch_time: datingDay.is_lunch_time
        }
      );
    } else {
      this.props.history.push('/matchprofile');
    }
  };

  renderCafeInformationPopup = () => {
    const { t } = this.props;
    return (
      <Popup
        visible={this.state.popupCafeInformationVisible}
        TitleComponent={
          <TitleComponent
            title1={t('popup-cafe-title-1')}
            title2={t('popup-cafe-title-2')}
          />
        }
        MessagesComponent={
          <MessagesComponent message={t('popup-cafe-message')} />
        }
        ButtonComponent={
          <ButtonComponent
            cafeButtonTitle={t('popup-cafe-button')}
            note={t('popup-cafe-note')}
            onCafeButtonClick={this.onCafeButtonClick}
          />
        }
        onCancelClicked={this.closePopup}
      />
    );
  };

  renderMeetingTimePopup = () => {
    const { t } = this.props;
    return (
      <Popup
        visible={this.state.popupMeetingTimeVisible}
        title={t('popup-meeting-time-title')}
        MessagesComponent={
          <MeetingTimeMessages
            messages={[
              t('popup-meeting-time-message-1'),
              t('popup-meeting-time-message-2'),
              t('popup-meeting-time-message-3')
            ]}
          />
        }
        ButtonComponent={
          <MeetingTimeButton
            note={t('popup-meeting-time-text-link')}
            onTextLinkClick={this.onMeetingTimeLinkClick}
          />
        }
        onCancelClicked={this.closePopup}
      />
    );
  };

  renderStillUnreadPopup = () => {
    const { t } = this.props;
    return (
      <Popup
        visible={this.state.popupStillUnread}
        title={t('popup-still-unread-title')}
        MessagesComponent={
          <StillUnreadMessages
            message1={t('popup-still-unread-message-1')}
            message2={t('popup-still-unread-message-2')}
            message3={t('popup-still-unread-message-3')}
            linkText={t('popup-still-unread-link-text')}
            gender={this.props.userGender}
            contactMessage={t('popup-still-unread-message-contact')}
            onContactLinkClick={this.onContactLinkClick}
          />
        }
        ButtonComponent={<></>}
        onCancelClicked={this.closePopup}
      />
    );
  };

  renderConfirmSchedulePopup = () => {
    const { t } = this.props;
    return (
      <Popup
        visible={this.state.popupConfirmSchedule}
        title={t('popup-confirm-schedule-title')}
        messages={[
          t('popup-confirm-schedule-message-1'),
          t('popup-confirm-schedule-message-2')
        ]}
        positiveButtonTitle={t('popup-confirm-schedule-button-participate')}
        negativeButtonTitle={t('popup-confirm-schedule-button-cancel')}
        onPositiveClicked={this.onParticipateButtonClick}
        onNegativeClicked={this.onCancelButtonClick}
        onCancelClicked={this.closePopup}
      />
    );
  };

  renderCancelNoticePopup = () => {
    const { t } = this.props;
    return (
      <Popup
        visible={this.state.popupCancel}
        title={t('popup-cancel-title')}
        MessagesComponent={<CancelMessages />}
        ButtonComponent={<></>}
        onCancelClicked={this.closePopup}
      />
    );
  };

  renderAfterFivePmPopup = () => {
    const { t } = this.props;
    return (
      <Popup
        visible={this.state.popupAfterFivePm}
        title={t('popup-after-five-pm-title')}
        MessagesComponent={<AfterFivePmMessages />}
        positiveButtonTitle={t('popup-after-five-pm-button-ok')}
        negativeButtonTitle={t('popup-after-five-pm-button-cancel')}
        onPositiveClicked={this.onStillOkAfterFivePmClick}
        onNegativeClicked={this.onCancelAfterFivePmClick}
        onCancelClicked={this.closePopup}
      />
    );
  };

  renderUnavailableTimePopup = () => {
    const { t } = this.props;
    return (
      <Popup
        visible={this.state.popupUnavailableTime}
        TitleComponent={
          <UnavailableTitleComponent
            title1={t('popup-chat-unavailable-1')}
            title2={t('popup-chat-unavailable-2')}
          />
        }
        okButtonTitle={t('btn-text-ok')}
        MessagesComponent={<></>}
        // onOkClicked={this.closePopup}
        // onCancelClicked={this.closePopup}
        onOkClicked={this.closeAvailableTimePopup}
        onCancelClicked={this.closeAvailableTimePopup}
      />
    );
  };

  renderTransferTicketPopup = () => {
    const { t } = this.props;
    return (
      <Popup
        visible={this.state.popupTransferTicket}
        title={t('popup-transfer-title')}
        messages={[t('popup-transfer-content')]}
        positiveButtonTitle={t('popup-transfer-positive-btn')}
        negativeButtonTitle={t('popup-transfer-negative-btn')}
        onPositiveClicked={() => {
          window.open('https://wi.bachelorapp.net/re-matching');
          this.closePopup();
        }}
        onNegativeClicked={() => {
          if (this.props.userGender === UserGender.MALE) {
            window.open('https://wi.bachelorapp.net/dating-ticket');
          } else {
            window.open('https://wi.bachelorapp.net/bachelor-ticket');
          }
          this.closePopup();
        }}
        onCancelClicked={this.closePopup}
      />
    );
  };

  renderWantCancelPopup = () => {
    const { t } = this.props;
    const datingDayData = this.state.matchingDay;
    // example of datingDateText = `06/09 (Wed)17:00~`
    const datingDateText = datingDayData.datetime_text;
    const checkDayBefore = checkDatingDayBefore(
      this.state.currentServerTime,
      datingDateText
    );

    /**
     * "popup-want-cancel-title": "Are you sure？",
     "popup-want-cancel-content-1": "Cancellation fee",
     "popup-want-cancel-content-2": "3,000 yen",
     "popup-want-cancel-content-7": "2,000 yen",
     "popup-want-cancel-content-3": "You'll not able to see the dating detail after you cancel it.",
     "popup-want-cancel-positive-btn": "Yes",
     "popup-want-cancel-negative-btn": "Back"
     */
    return (
      <Popup
        visible={this.state.popupWantCancelPopup}
        title={t('popup-want-cancel-title')}
        MessagesComponent={
          <CancelDateMessagesComponent
            message1={t('popup-want-cancel-content-1')}
            //before dating day: 2,000
            //on dating day: 3,000
            message2={t(
              checkDayBefore
                ? 'popup-want-cancel-content-7'
                : 'popup-want-cancel-content-2'
            )}
            message3={t('popup-want-cancel-content-3')}
            message4={t('popup-want-cancel-content-4')}
            message5={t('popup-want-cancel-content-5')}
            message6={t('popup-want-cancel-content-6')}
          />
        }
        positiveButtonTitle={t('popup-want-cancel-positive-btn')}
        negativeButtonTitle={t('popup-want-cancel-negative-btn')}
        onPositiveClicked={() => {
          this.closePopup();
          if (datingDayData) {
            this.props.history.push({
              pathname: `/cancel-date-application`,
              state: { matchItem: datingDayData }
            });
          }
        }}
        onNegativeClicked={this.closePopup}
        onCancelClicked={this.closePopup}
      />
    );
  };

  renderGotCancelledPopup = () => {
    const { t, userGender, hasAppliedSubscription } = this.props;
    let title = t('got-cancelled-popup-line-1');
    let positiveButtonTitle = false;
    let neutralButtonTitle = false;
    let negativeButtonTitle = false;
    let positiveButtonAction = () => {
      this.wantToCancelByOtherClick();
    };
    let neutralButtonAction = () => {
      this.wantToCancelByOtherClick();
    };
    if (userGender === UserGender.MALE) {
      if (hasAppliedSubscription) {
        if (this.state.beforeReMatchingTime) {
          positiveButtonTitle = this.state.matchingDay.is_lunch_time
            ? t('find-another-text-link-2-for-lunch')
            : t('find-another-text-link-2');
          positiveButtonAction = () => {
            this.setState({ isRematching: true, isTrialOrCoupon: false });
            this.wantToCancelByOtherClick();
          };
          neutralButtonTitle = t('get-a-coupon-ticket');
          neutralButtonAction = () => {
            this.setState({ isRematching: false, isTrialOrCoupon: true });
            this.wantToCancelByOtherClick();
          };
        } else {
          title = t('get-date-ticket');
          positiveButtonTitle = t('get-a-coupon-ticket');
          positiveButtonAction = () => {
            this.setState({ isRematching: false, isTrialOrCoupon: true });
            this.wantToCancelByOtherClick();
          };
          negativeButtonTitle = t('popup-want-cancel-negative-btn');
        }
      } else {
        if (this.state.beforeReMatchingTime) {
          positiveButtonTitle = t('find-another-text-link-3');
          positiveButtonAction = () => {
            this.setState({ isRematching: true, isTrialOrCoupon: false });
            this.wantToCancelByOtherClick();
          };
          neutralButtonTitle = t('extend-trial-term');
          neutralButtonAction = () => {
            this.setState({ isRematching: false, isTrialOrCoupon: true });
            this.wantToCancelByOtherClick();
          };
        } else {
          title = t('popup-title-extend-trial');
          positiveButtonTitle = t('extend-trial-term');
          positiveButtonAction = () => {
            this.setState({ isRematching: false, isTrialOrCoupon: true });
            this.wantToCancelByOtherClick();
          };
          negativeButtonTitle = t('popup-want-cancel-negative-btn');
        }
      }
    } else {
      if (this.state.beforeReMatchingTime) {
        positiveButtonTitle = this.state.matchingDay.is_lunch_time
          ? t('find-another-text-link-2-for-lunch')
          : t('find-another-text-link-2');
        positiveButtonAction = () => {
          this.setState({ isRematching: true, isTrialOrCoupon: false });
          this.wantToCancelByOtherClick();
        };
        neutralButtonTitle = t('get-bachelor-ticket');
        neutralButtonAction = () => {
          this.setState({ isRematching: false, isTrialOrCoupon: true });
          this.wantToCancelByOtherClick();
        };
      } else {
        title = t('popup-female-report-cancel-after-rematching-title');
        positiveButtonTitle = t('get-bachelor-ticket');
        positiveButtonAction = () => {
          this.setState({ isRematching: false, isTrialOrCoupon: true });
          this.wantToCancelByOtherClick();
        };
        negativeButtonTitle = t('popup-want-cancel-negative-btn');
      }
    }

    return (
      <Popup
        visible={this.state.popupGotCancelled}
        title={title}
        messages={['', '']}
        messageClassName={styles.popupMessageClassName}
        positiveButtonTitle={positiveButtonTitle} // 12pm-rematching
        neutralButtonTitle={neutralButtonTitle} // trial or coupon
        negativeButtonTitle={negativeButtonTitle} // trial or coupon
        onPositiveClicked={positiveButtonAction}
        onNeutralClicked={neutralButtonAction}
        onNegativeClicked={this.closePopup}
        onCancelClicked={this.closePopup}
      />
    );
  };

  renderUnpntPopup() {
    const { t } = this.props;
    return (
      <Popup
        visible={this.state.unpaid_payment_popup}
        title={'12時の締め切りを過ぎたため\n' + '申請ができません'}
        messages={['未払いがあるため、現在ご利用できません。']}
        okButtonTitle={'お支払い確認ページへ'}
        messageStyle={{ textAlign: 'left' }}
        onCancelClicked={this.closePopup}
        onOkClicked={() =>
          this.setState({
            payment_processing_popup: true,
            unpaid_payment_popup: false
          })
        }
      />
    );
  }

  renderGotCancelSuccessPopup() {
    const { t } = this.props;

    return (
      <Popup
        visible={this.state.popupGotCancelSuccess}
        title={t('popup-cancel-success-title')}
        okButtonTitle={t('popup-btn-cancel')}
        onOkClicked={this.onCancelSuccess}
        disableClose
      />
    );
  }

  renderPopupWantToCancelByOther() {
    const { t } = this.props;
    const { isRematching, isTrialOrCoupon } = this.state;
    return isRematching || isTrialOrCoupon ? (
      <Popup
        visible={this.state.popupWantToCancelByOther}
        title={t('popup-confirm-got-cancelled-title')}
        titleClassName={classnames('title', 'title--pre-line')}
        messages={[
          t('popup-confirm-got-cancelled-line-1'),
          <Trans i18nKey="popup-confirm-got-cancelled-line-2">
            <strong>Bold part</strong>
          </Trans>
        ]}
        contentStyle={classnames('content', 'cancel-popup')}
        positiveButtonTitle={t('popup-confirm-got-cancelled-positive-btn')}
        negativeButtonTitle={t('popup-confirm-got-cancelled-negative-btn')}
        onPositiveClicked={this.requestCancelDateByPartnerAPI}
        onNegativeClicked={() => {
          this.setState({ isRematching: false, isTrialOrCoupon: false });
          this.closePopup();
        }}
        onCancelClicked={() => {
          this.setState({ isRematching: false, isTrialOrCoupon: false });
          this.closePopup();
        }}
      />
    ) : null;
  }

  renderPopupRematching() {
    const { t, hasAppliedSubscription } = this.props;
    return (
      <Popup
        visible={this.state.popupWantRematching}
        title={t('popup-confirm-got-rematching-title')}
        titleClassName={classnames('title', 'title--pre-line')}
        messages={[
          <Trans i18nKey="popup-confirm-got-rematching-line-1">
            <strong>Bold part</strong>
          </Trans>,
          <Trans
            i18nKey={
              this.props.userGender === UserGender.MALE
                ? hasAppliedSubscription
                  ? 'popup-confirm-got-rematching-male-subscription-line-2'
                  : 'popup-confirm-got-rematching-male-no-subscription-line-2'
                : 'popup-confirm-got-rematching-female-line-2'
            }
          >
            <strong>Bold part</strong>
          </Trans>,
          <Trans i18nKey="popup-confirm-got-rematching-line-3">
            {this.props.userGender === UserGender.MALE ? (
              <a
                style={{ textDecoration: 'underline' }}
                href="https://bachelorapp.zendesk.com/hc/ja/articles/4404707677209"
                target="_blank"
              >
                Bold part
              </a>
            ) : (
              <a
                style={{ textDecoration: 'underline' }}
                href="https://bachelorapp.zendesk.com/hc/ja/articles/4404733266073"
                target="_blank"
              >
                Bold part
              </a>
            )}
          </Trans>,
          <Trans i18nKey="popup-confirm-got-rematching-line-4">
            <strong>Bold part</strong>
          </Trans>
        ]}
        contentStyle={classnames('content', 'cancel-popup')}
        positiveButtonTitle={t('popup-confirm-got-rematching-positive-btn')}
        negativeButtonTitle={t('popup-confirm-got-cancelled-negative-btn')}
        onPositiveClicked={this.requestRematchingAPI}
        onNegativeClicked={() => this.wantToCancelByOtherClick()}
        onCancelClicked={() => {
          this.setState({ isRematching: false, isTrialOrCoupon: false });
          this.closePopup();
        }}
      />
    );
  }

  renderCompleteRematchingPopup() {
    const { t } = this.props;
    const datingDay = this.state.matchingDay;
    return (
      <Popup
        disableClose={true}
        visible={this.state.popupCompleteRematching}
        title={t('popup-title-free-period')}
        messageStyle={{ textAlign: 'left' }}
        messages={
          datingDay.is_lunch_time
            ? [t('popup-content-free-period-lunch')]
            : [t('popup-content-free-period')]
        }
        positiveButtonTitle={t('popup-confirm-free-period')}
        onPositiveClicked={this.onSuccessCompleteRematching}
        onCancelClicked={this.closePopup}
      />
    );
  }
  onSuccessCompleteRematching = () => {
    this.props.history.push('/matchprofile');
  };

  renderSuccessPopupUseCouponPartnerPopup() {
    const { t, userGender } = this.props;
    const matchingDay = this.state.matchingDay.rematching_time
      ? moment(this.state.matchingDay.rematching_time).format('MM/DD/YYYY')
      : undefined;
    const currentDay = this.state.currentServerTime
      ? moment(this.state.currentServerTime).format('MM/DD/YYYY')
      : moment().format('MM/DD/YYYY');
    let msgPopup = t('popup-confirm-get-ticket-day-dating');
    if (matchingDay && moment(currentDay).diff(matchingDay, 'days') === -1)
      msgPopup = t('popup-confirm-get-ticket-before-day-dating');
    return (
      <Popup
        disableClose={true}
        visible={this.state.popupSuccessUseCouponPartnerCancel}
        title={t('popup-confirm-get-ticket-title')}
        messageStyle={{ textAlign: 'left' }}
        messages={[msgPopup]}
        positiveButtonTitle={t('popup-confirm-get-ticket-button')}
        onPositiveClicked={() =>
          this.onSuccessTrialOrCouponWhenCancelledClick(userGender)
        }
        onCancelClicked={this.closePopup}
      />
    );
  }

  onSuccessFreePeriodExtended = () => {
    this.props.history.push('/participation');
  };

  renderOneMoreTrialPopup() {
    const { t } = this.props;
    return (
      <Popup
        disableClose={true}
        visible={this.state.popupOneMoreTrial}
        title={t('popup-extend-trial-title')}
        messageStyle={{ textAlign: 'left' }}
        messages={[t('popup-extend-trial-message')]}
        positiveButtonTitle={t('popup-extend-trial-confirm')}
        onPositiveClicked={this.onSuccessFreePeriodExtended}
        onCancelClicked={this.closePopup}
      />
    );
  }

  renderPassedRematchTimePopup = () => {
    const { t } = this.props;
    return (
      <Popup
        visible={this.state.popupPassedRematchTime}
        TitleComponent={
          <TitleComponent
            title1={t('popup-passed-rematch-time-title-1')}
            title2={t('popup-passed-rematch-time-title-2')}
          />
        }
        messageStyle={{ textAlign: 'left' }}
        messages={[t('popup-passed-rematch-time-content')]}
        okButtonTitle={t('popup-btn-cancel')}
        onOkClicked={() => {
          this.props.history.push('/matchprofile');
        }}
        disableClose
      />
    );
  };

  openPopup = popupId => {
    this.setState({
      popupCafeInformationVisible: popupId === POPUP_CAFE_INFORMATION,
      popupMeetingTimeVisible: popupId === POPUP_MEETING_TIME,
      popupStillUnread: popupId === POPUP_STILL_UNREAD,
      popupConfirmSchedule: popupId === POPUP_CONFIRM_SCHEDULE,
      popupCancel: popupId === POPUP_CANCEL,
      popupAfterFivePm: popupId === POPUP_AFTER_FIVE_PM,
      popupUnavailableTime: popupId === POPUP_UNAVAILABLE_TIME,
      popupTransferTicket: popupId === POPUP_TRANSFER_TICKET,
      popupWantCancelPopup: popupId === POPUP_WANT_CANCEL,
      popupGotCancelled: popupId === POPUP_GOT_CANCELLED,
      popupGotCancelSuccess: popupId === POPUP_GOT_CANCEL_SUCCESS,
      popupWantToCancelByOther: popupId === POPUP_WANT_TO_CANCEL_BY_OTHER,
      popupWantRematching: popupId === POPUP_GOT_REMATCHING,
      popupCompleteRematching: popupId === POPUP_COMPLETE_REMATCHING,
      popupSuccessUseCouponPartnerCancel:
        popupId === POPUP_SUCCESS_USE_COUPON_PARTNER_CANCEL,
      popupPassedRematchTime: popupId === POPUP_PASSED_REMATCH_TIME,
      popupERROR: popupId === POPUP_ERROR
    });
  };

  closePopup = () => {
    this.setState({
      popupCafeInformationVisible: false,
      popupMeetingTimeVisible: false,
      popupStillUnread: false,
      popupConfirmSchedule: false,
      popupCancel: false,
      popupAfterFivePm: false,
      popupUnavailableTime: false,
      popupTransferTicket: false,
      popupWantCancelPopup: false,
      popupGotCancelled: false,
      popupGotCancelSuccess: false,
      popupConfirmGotCancelled: false,
      popupSuccessUseCouponPartnerCancel: false,
      popupPassedRematchTime: false,
      popupOneMoreTrial: false,
      popupWantToCancelByOther: false,
      isTrialOrCoupon: false,
      isRematching: false,
      popupWantRematching: false,
      popupCompleteRematching: false
    });
  };

  onCafeButtonClick = () => {
    console.log('onCafeButtonClick');
  };

  onMeetingTimeLinkClick = () => {
    this.openPopup(POPUP_STILL_UNREAD);
  };

  wantToCancelByOtherClick = () => {
    this.openPopup(POPUP_WANT_TO_CANCEL_BY_OTHER);
  };

  onContactLinkClick = () => {
    console.log('onContactLinkClick');
  };

  onParticipateButtonClick = () => {
    const datingDay = this.state.matchingDay;
    this.props.history.push({
      pathname: '/cancel-date-application',
      state: { datingDay: datingDay }
    });
    // this.closePopup();
  };

  onCancelButtonClick = () => {
    this.openPopup(POPUP_CANCEL);
  };

  onStillOkAfterFivePmClick = () => {
    this.closePopup();
  };

  onCancelAfterFivePmClick = () => {
    this.openPopup(POPUP_CONFIRM_SCHEDULE);
  };

  openMeetingTimePopup = () => {
    this.openPopup(POPUP_MEETING_TIME);
  };

  openTransferTicketPopup = () => {
    this.requestGetCurrentServerTimeAPI();
    this.openPopup(POPUP_GOT_CANCELLED);
  };

  openWantCancelPopup = () => {
    this.requestGetCurrentServerTimeAPI();
    this.openPopup(POPUP_WANT_CANCEL);
  };

  onTransferTicketClick = () => {
    window.open('https://wi.bachelorapp.net/contact');
    this.closePopup();
  };

  onUserAvatarClick = () => {
    const currentPath = this.props.location.pathname;

    let isSaturday = false;

    // Check saturday or sunday
    if (currentPath.indexOf('chat/saturday') > -1) {
      isSaturday = true;
    }

    this.props.history.push({
      pathname:
        '/partnerdetail' +
        (isSaturday ? '/saturday' : '/sunday') +
        (this.props.userGender === UserGender.MALE
          ? '?has_card=' + this.props.userHasCard
          : '')
    });
  };

  onBackButtonClick = () => {
    this.props.history.push('/matchprofile');
  };

  handleTitle(txtTitle) {
    const { t } = this.props;
    return txtTitle.includes('Wed')
      ? txtTitle.replace('Wed', t('common:wed'))
      : txtTitle.includes('Sat')
      ? txtTitle.replace('Sat', t('common:sat'))
      : txtTitle.replace('Sun', t('common:sun'));
  }

  handelClickGoToDetailPartner = () => {
    const datingDayChat = this.state.matchingDay;
    const weeks = this.props.matchDataChat.weeks;
    const matchingDate = formatMatchProfile(this.props.matchDataChat)
      .weeks.reduce((acc, key) => [...acc, ...key], [])
      .find(matchDay => matchDay.dating_day_id === datingDayChat.dating_day_id);
    this.props.history.push({
      pathname: '/partnerdetail' + `/${matchingDate.day.toLowerCase()}`,
      state: { matchItem: matchingDate, weeks: weeks }
    });
  };
  render() {
    return (
      <>
        <ChatFeature
          isWhiteMode={this.state.isWhiteMode}
          chatRoomIsReady={this.state.chatRoomIsReady}
          partnerProfileImage={getPartnerProfileImage(this.state.partnerData)}
          fetchMoreMessages={this.fetchMoreMessages}
          partnerData={this.state.partnerData}
          chatRoomMessages={this.state.chatRoomMessages}
          bachelorChatManager={this.state.bachelorChatManager}
          meetingTimePopup={this.openMeetingTimePopup}
          wantCancelPopup={this.openWantCancelPopup}
          transferTicketPopup={this.openTransferTicketPopup}
          textAgeS={this.props.t('ageS')}
          linkTextChat={this.props.t('chat-meeting-time')}
          linkTextChat1={this.props.t('bottom-text-link-1')}
          linkTextChat2={this.props.t('bottom-text-link-2')}
          linkTextChat3={this.props.t('bottom-text-link-3')}
          shouldScrollToLatestMessage={this.state.shouldScrollToLatestMessage}
          saveChatHistory={this.saveChatHistory}
          onUserAvatarClick={this.onUserAvatarClick}
          onBackButtonClick={this.onBackButtonClick}
          chatfeatureHeaderContent={this.state.chatfeatureHeaderContent}
          chatfeatureHeaderLine={this.state.chatfeatureHeaderLine}
          dateTimeText={this.handleTitle(this.state.dateTimeText)}
          isLunchTime={this.state.isLunchTime}
          handelClickGoToDetailPartner={this.handelClickGoToDetailPartner}
          matchingDateData={this.state.matchingDateData}
          isShowUnreadMessage={this.state.isShowUnreadMessage}
        />
        {this.renderCafeInformationPopup()}
        {this.renderMeetingTimePopup()}
        {this.renderStillUnreadPopup()}
        {this.renderConfirmSchedulePopup()}
        {this.renderCancelNoticePopup()}
        {this.renderAfterFivePmPopup()}
        {this.renderUnavailableTimePopup()}
        {this.renderTransferTicketPopup()}
        {this.renderWantCancelPopup()}
        {this.renderGotCancelledPopup()}
        {this.renderGotCancelSuccessPopup()}
        {this.renderPopupWantToCancelByOther()}
        {this.renderPopupRematching()}
        {this.renderCompleteRematchingPopup()}
        {this.renderSuccessPopupUseCouponPartnerPopup()}
        {this.renderPassedRematchTimePopup()}
        {this.renderOneMoreTrialPopup()}
      </>
    );
  }
}
const mapStateToProps = state => {
  return {
    chatRoomData: state.chatRoom.chatRoomData,
    roomData: state.chatRoom.roomData,
    matchData: matchDataMock.data,
    matchDataChat: state.match.dataMatch,
    loggedIn: state.auth.loggedIn,
    userData: path(['auth', 'userdata', 'userAuth', 'user'], state),
    userGender: path(['userGlobalInfo', 'data', 'user_gender'], state),
    hasAppliedSubscription: path(
      ['userGlobalInfo', 'data', 'has_applied_subscription'],
      state
    ),
    weeks: path(['match', 'dataMatch', 'weeks'], state),
    userHasCard: state.match.dataMatch.user_has_card
  };
};
const mapDispatchToProps = {
  requestChatRoom,
  requestMatchDetail,
  requestGetCafeSuggestion,
  requestChatMessages,
  showLoading,
  hideLoading,
  chatHistory,
  cancelDate,
  cancelDateByPartner,
  participateRematch,
  getCurrentServerTime,
  getMatchProfileDetail,
  getDatingDayAbleToChat,
  accessChatRoom,
  requestUserGlobalInfo
};

export default compose(
  withNamespaces('chat', { wait: true }),
  withRouter,
  connect(mapStateToProps, mapDispatchToProps)
)(ChatFeatureContainer);

import * as types from '../actions/types';

const initialState = {
  isRefresh: false,
  isError: false,
  error: null,
  data: ''
};

const participationReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.SET_PARTICIPATION_REQUESTED:
      return {
        ...state,
        isError: false,
        error: null
      };
    case types.SET_PARTICIPATION_SUCCEEDED:
      return {
        ...state,
        isError: false,
        error: null
      };
    case types.SET_PARTICIPATION_FAILED_NEED_ACTION:
      return {
        ...state,
        isError: false,
        error: null
      };
    case types.GET_OPEN_DATES_REQUESTED:
      return {
        ...state,
        isError: false,
        error: null
      };
    case types.GET_OPEN_DATES_SUCCEEDED:
      return {
        ...state,
        isError: false,
        error: null,
        data: action.data
      };
    case types.GET_OPEN_DATES_FAILED:
      return {
        ...state,
        isError: false,
        error: null,
        data: {}
      };
    case types.GET_OPEN_DATES_STATUS_REQUESTED:
      return {
        ...state,
        isError: false,
        error: null
      };
    case types.GET_OPEN_DATES_STATUS_SUCCEEDED:
      return {
        ...state,
        isError: false,
        error: null,
        data: action.data
      };
    case types.GET_OPEN_DATES_STATUS_FAILED:
      return {
        ...state,
        isError: false,
        error: null,
        data: {}
      };
    case types.GET_AWAITING_PARTICIPANT_DATING_DAYS_REQUESTED:
      return {
        ...state,
        isError: false,
        error: null
      };
    case types.GET_AWAITING_PARTICIPANT_DATING_DAYS_SUCCEEDED:
      return {
        ...state,
        isError: false,
        error: null,
        data: action.data
      };
    case types.GET_AWAITING_PARTICIPANT_DATING_DAYS_FAILED:
      return {
        ...state,
        isError: false,
        error: null,
        data: {}
      };
    case types.REQUEST_PARTICIPATE_REQUESTED:
      return {
        ...state,
        isError: false,
        error: null
      };
    case types.REQUEST_PARTICIPATE_SUCCEEDED:
      return {
        ...state,
        isError: false,
        error: null
      };
    case types.REQUEST_PARTICIPATE_FAILED:
      return {
        ...state,
        isError: false,
        error: null
      };
    case types.CANCEL_PARTICIPATE_REQUESTED:
      return {
        ...state,
        isError: false,
        error: null
      };
    case types.CANCEL_PARTICIPATE_SUCCEEDED:
      return {
        ...state,
        isError: false,
        error: null
      };
    case types.CANCEL_PARTICIPATE_FAILED:
      return {
        ...state,
        isError: false,
        error: null
      };
    case types.CANCEL_SAMPLE_PARTICIPANT_REQUESTED:
      return {
        ...state,
        isError: false,
        error: null
      };
    case types.CANCEL_SAMPLE_PARTICIPANT_SUCCEEDED:
      return {
        ...state,
        isError: false,
        error: null
      };
    case types.CANCEL_SAMPLE_PARTICIPANT_FAILED:
      return {
        ...state,
        isError: false,
        error: null
      };
    case types.GET_RECOMMENDATIONS_REQUESTED:
      return {
        ...state,
        isError: false,
        error: null
      };
    case types.GET_RECOMMENDATIONS_SUCCEEDED:
      return {
        ...state,
        isError: false,
        error: null
      };
    case types.GET_RECOMMENDATIONS_FAILED:
      return {
        ...state,
        isError: false,
        error: null
      };
    case types.SET_PARTICIPATION_FAILED:
      return {
        ...state,
        isError: true,
        error: action.error
      };
    case types.HIDE_ERROR:
      return {
        ...state,
        isError: false,
        error: null
      };
    default:
      return state;
  }
};

export default participationReducer;

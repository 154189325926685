import React from 'react';
import styles from './index.module.css';

const ButtonComponent = ({ note, onTextLinkClick }) => {
  return (
    <div className={styles.buttonContainer}>
      <div className={styles.note} onClick={onTextLinkClick}>
        {note}
      </div>
    </div>
  );
};

export default ButtonComponent;

import { combineReducers } from 'redux';
import servermaintain from './serverRepair';
import app from './appReducer';
import comm from './commonReducer';
import err from './errorReducer';
import auth from './authReducer';
import homescreen from './homescreenReducer';
import match from './matchReducer';
import participation from './participationReducer';
import popups from './popupsReducer';
import userProfile from './userProfileReducer';
import useCoupon from './useCouponReducer';
import cancelDate from './cancelDateReducer';
import chatRoom from './chatRoomReducer';
import chatHistory from './chatHistoryReducer';
import cafe from './cafeReducer';
import chatMessages from './chatMessagesReducer';
import changePlan from './changePlanReducer';
import drawer from './drawerReducer';
import deactiveProfile from './deactiveProfileReducer';
import cancelProfile from './cancelProfileReducer';
import feedback from './feedbackReducer';
import inviteFriends from './inviteFriendsReducer';
import matchProfileInfo from './matchProfileInfoReducer';
import evaluationInfo from './evaluationInfoReducer';
import evaluationList from './evaluationListReducer';
import coupons from './couponsReducer';
import rematch from './rematchReducer';
import secondFormData from './2ndRegistrationformReducer';
import cards from './cardsReducer';
import firstRegistrationForm from './registrationformReducer';
import useImage from './useImageReducer';
import globalErrorPopup from './globalErrorPopupReducer';
import updateEmailReducer from './updateEmailReducer';
import datingDayAbleToChatReducer from './datingDayAbleToChatReducer';
import userGlobalInfo from './userGlobalInfoReducer';
import validateSelecting from './validateSelectingReducer';
import userPlan from './userPlanReducer';
import tempCancelTrial from './tempCancelTrialReducer';
import userInfoFacebookPixel from './userInfoFacebookPixelReducer';
import exception from './exceptionReducer';
import subscriptionPaused from './subscriptionPausedReducer';
import contact from './contactReducer';
import { CURRENT_USER_LOGOUT_FACEBOOK_SUCCEEDED } from '../actions/types';
import feedbackFlexQuestion from './feedbackFlexQuestionReducer';

const appReducer = combineReducers({
  comm,
  servermaintain,
  app,
  err,
  auth,
  secondFormData,
  homescreen,
  match,
  participation,
  popups,
  userProfile,
  useCoupon,
  cancelDate,
  chatRoom,
  chatHistory,
  cafe,
  chatMessages,
  changePlan,
  drawer,
  deactiveProfile,
  cancelProfile,
  feedback,
  inviteFriends,
  matchProfileInfo,
  coupons,
  rematch,
  evaluationInfo,
  firstRegistrationForm,
  useImage,
  evaluationList,
  cards,
  globalErrorPopup,
  updateEmailReducer,
  datingDayAbleToChatReducer,
  userGlobalInfo,
  userPlan,
  tempCancelTrial,
  userInfoFacebookPixel,
  validateSelecting,
  exception,
  subscriptionPaused,
  contact,
  feedbackFlexQuestion
});

const initialState = appReducer({}, {});

export const rootReducer = (state, action) => {
  if (action.type === CURRENT_USER_LOGOUT_FACEBOOK_SUCCEEDED) {
    state = initialState;
    localStorage.removeItem('isWhiteMode');
  }
  return appReducer(state, action);
};

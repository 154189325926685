import { call, put, takeLatest } from 'redux-saga/effects';
import * as types from '../actions/types';
import { requestAPI } from '../services/request';
import { API } from '../services/api';
import { ResponseStatus } from '../../constants/constant';

function* getUserInfoFacebookPixel(action) {
  const showGeneralPopup = false;
  let response = null;
  try {
    response = yield call(
      requestAPI,
      API.API_GET_USER_INFO_FB_PIXEL,
      API.GET,
      action.params,
      showGeneralPopup
    );

    if (response.status === ResponseStatus.SUCCESS) {
      yield put({
        type: types.GET_USER_INFO_FB_PIXEL_SUCCEEDED,
        data: response.data
      });
    } else {
      yield put({ type: types.GET_USER_INFO_FB_PIXEL_FAILD, error: response });
    }

    if (action.onComplete) {
      action.onComplete(response);
    }
  } catch (error) {
    yield put({ type: types.GET_USER_INFO_FB_PIXEL_FAILD, error });
  }
}

export function* watchGetUserInfoFacebookPixel() {
  yield takeLatest(
    types.GET_USER_INFO_FB_PIXEL_REQUESTED,
    getUserInfoFacebookPixel
  );
}

import React from 'react';
import { Trans } from 'react-i18next';
import styles from './index.module.css';

const MessagesComponent = () => (
  <Trans parent="div" i18nKey="chat:popup-after-five-pm-message">
    <div className={styles.messageItem}>
      やむを得ず欠席する場合、キャンセルをしましょう。
    </div>
    <div className={styles.messageListItem}>
      *キャンセル料<span>（自動引き落とし）</span>
    </div>
    <div className={styles.messageListItem}>前日まで：2,000円</div>
    <div className={styles.messageListItem}>当日まで：3,000円</div>
    <div className={styles.messageListItem}>当日の無断欠席：6,000円</div>
  </Trans>
);

export default MessagesComponent;

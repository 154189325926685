import * as types from '../actions/types';

const initialState = {
  data: {},
  isError: false,
  firstInitGender: null
};

const userInfoFacebookPixelReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.GET_USER_INFO_FB_PIXEL_REQUESTED:
      return {
        ...state,
        error: null
      };
    case types.GET_USER_INFO_FB_PIXEL_SUCCEEDED:
      return {
        ...state,
        data: action.data.data,
        error: null,
        isError: false
      };
    case types.GET_USER_INFO_FB_PIXEL_FAILD:
      return {
        ...state,
        isError: true,
        data: {},
        error: action.error
      };
    case types.SET_FIRST_INIT_FB_PIXEL_GENDER:
      return {
        ...state,
        firstInitGender: action.params
      };
    default:
      return state;
  }
};

export default userInfoFacebookPixelReducer;

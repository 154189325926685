import * as types from '../actions/types';

const initialState = {
  isError: false,
  message: [],
  title: ''
};

const globalErrorPopupReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.RESET_GLOBAL_ERROR_POPUP:
      return {
        isError: false,
        message: [],
        title: ''
      };
    case types.UPDATE_GLOBAL_ERROR_POPUP:
      return {
        isError: true,
        message: action.action.message,
        title: action.action.title,
        nextUrl: action.action.nextUrl
      };

    default:
      return state;
  }
};

export default globalErrorPopupReducer;

import { call, put, takeLatest } from 'redux-saga/effects';
import * as types from '../actions/types';
import { requestAPI } from '../services/request';
import { API } from '../services/api';
import { ResponseStatus } from '../../constants/constant';
import { store } from '../store';
import { hideLoading, showLoading } from '../actions';

function* getChatMessages(action) {
  let response = null;
  store.dispatch(showLoading());
  try {
    response = yield call(
      requestAPI,
      API.GET_CHAT_MESSAGE,
      API.GET,
      action.params
    );

    if (response.status === ResponseStatus.SUCCESS) {
      yield put({
        type: types.GET_CHAT_MESSAGES_REQUEST_SUCCEEDED,
        data: response.data
      });
    } else if (response.status === ResponseStatus.FAILURE) {
      console.error('SAGA:', response.message);
      yield put({
        type: types.GET_CHAT_MESSAGES_REQUEST_FAILED,
        error: response
      });
    } else {
      yield put({
        type: types.GET_CHAT_MESSAGES_REQUEST_FAILED,
        error: response
      });
    }
    if (action.onComplete) {
      action.onComplete(response);
    }
  } catch (error) {
    yield put({ type: types.GET_CHAT_MESSAGES_REQUEST_FAILED, error });
  }
  store.dispatch(hideLoading());
}

export function* watchGetChatMessages() {
  yield takeLatest(types.GET_CHAT_MESSAGES_REQUESTED, getChatMessages);
}

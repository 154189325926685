import { UserGender, fbApiVersion } from '../constants/constant';
import FacebookAnalytics from 'react-facebook-pixel';
import isFunction from 'lodash/isFunction';
import moment from 'moment';

function getPixelIdByGender(gender) {
  // return gender == UserGender.MALE ? '378450156107054' : '2407205956175039'; for test env
  return gender == UserGender.MALE ? '2491872270836664' : '2244055569238379';
}

function getFbPixelEventId(eventName, userId) {
  const momentUnix = moment().unix();

  return `${momentUnix}_${userId ? userId + '_' : ''}${eventName}`;
}

export function fbInit(gender) {
  if (gender) {
    FacebookAnalytics.init(getPixelIdByGender(gender));
    FacebookAnalytics.pageView();
  }
}

export function fbPixelTrackEvent(
  uuid,
  eventName,
  gender,
  extEventId = null,
  callback = null
) {
  const eventId = extEventId || getFbPixelEventId(eventName, uuid);

  if (!window.fbq) {
    fbInit(gender);
  }

  FacebookAnalytics.fbq(
    'track',
    eventName,
    {},
    {
      eventID: eventId
    }
  );

  console.log('sent event', {
    eventId: eventId,
    pixelId: getPixelIdByGender(gender)
  });

  if (callback && isFunction(callback)) {
    callback();
  }

  return eventId;
}

import * as types from '../actions/types';

const initialState = {
  isError: false,
  error: null,
  stepOneData: {},
  stepTwoData: {},
  stepThreeData: {},
  stepFourData: {},
  stepFiveData: {},
  stepSixData: {},
  stepSevenData: {},
  stepEightData: {}
};

const secondRegistrationFormReducer = (state = initialState, action) => {
  switch (action.type) {
    //GET REQUEST
    case types.SECOND_REGISTRATION_FORM_GET_STEP_ONE_REQUESTED:
      return {
        ...state
      };
    case types.SECOND_REGISTRATION_FORM_GET_STEP_ONE_SUCCEDDED:
      return {
        ...state,
        stepOneData: action.data.data
      };
    case types.SECOND_REGISTRATION_FORM_GET_STEP_ONE_FAILED:
      return {
        ...state,
        isError: true,
        error: action.error
      };

    case types.SECOND_REGISTRATION_FORM_GET_STEP_TWO_REQUESTED:
      return {
        ...state
      };
    case types.SECOND_REGISTRATION_FORM_GET_STEP_TWO_SUCCEDDED:
      return {
        ...state,
        stepTwoData: action.data
      };
    case types.SECOND_REGISTRATION_FORM_GET_STEP_TWO_FAILED:
      return {
        ...state,
        isError: true,
        error: action.error
      };

    case types.SECOND_REGISTRATION_FORM_GET_STEP_THREE_REQUESTED:
      return {
        ...state
      };
    case types.SECOND_REGISTRATION_FORM_GET_STEP_THREE_SUCCEDDED:
      return {
        ...state,
        stepThreeData: action.data
      };
    case types.SECOND_REGISTRATION_FORM_GET_STEP_THREE_FAILED:
      return {
        ...state,
        isError: true,
        error: action.error
      };

    case types.SECOND_REGISTRATION_FORM_GET_STEP_FOUR_REQUESTED:
      return {
        ...state
      };
    case types.SECOND_REGISTRATION_FORM_GET_STEP_FOUR_SUCCEDDED:
      return {
        ...state,
        stepFourData: action.data
      };
    case types.SECOND_REGISTRATION_FORM_GET_STEP_FOUR_FAILED:
      return {
        ...state,
        isError: true,
        error: action.error
      };

    case types.SECOND_REGISTRATION_FORM_GET_STEP_FIVE_REQUESTED:
      return {
        ...state
      };
    case types.SECOND_REGISTRATION_FORM_GET_STEP_FIVE_SUCCEDDED:
      return {
        ...state,
        stepFiveData: action.data
      };
    case types.SECOND_REGISTRATION_FORM_GET_STEP_FIVE_FAILED:
      return {
        ...state,
        isError: true,
        error: action.error
      };

    case types.SECOND_REGISTRATION_FORM_GET_STEP_SIX_REQUESTED:
      return {
        ...state
      };
    case types.SECOND_REGISTRATION_FORM_GET_STEP_SIX_SUCCEDDED:
      return {
        ...state,
        stepSixData: action.data
      };
    case types.SECOND_REGISTRATION_FORM_GET_STEP_SIX_FAILED:
      return {
        ...state,
        isError: true,
        error: action.error
      };
    case types.SECOND_REGISTRATION_FORM_GET_STEP_SEVEN_REQUESTED:
      return {
        ...state
      };
    case types.SECOND_REGISTRATION_FORM_GET_STEP_SEVEN_SUCCEDDED:
      return {
        ...state,
        stepSevenData: action.data
      };
    case types.SECOND_REGISTRATION_FORM_GET_STEP_SEVEN_FAILED:
      return {
        ...state,
        isError: true,
        error: action.error
      };
    //POST REQUEST

    case types.SECOND_REGISTRATION_FORM_POST_STEP_ONE_REQUESTED:
      return {
        ...state
      };
    case types.SECOND_REGISTRATION_FORM_POST_STEP_ONE_SUCCEDDED:
      return {
        ...state,
        stepOneData: action.data
      };
    case types.SECOND_REGISTRATION_FORM_POST_STEP_ONE_FAILED:
      return {
        ...state,
        isError: true,
        error: action.error
      };

    case types.SECOND_REGISTRATION_FORM_POST_STEP_TWO_REQUESTED:
      return {
        ...state
      };
    case types.SECOND_REGISTRATION_FORM_POST_STEP_TWO_SUCCEDDED:
      return {
        ...state,
        stepTwoData: action.data
      };
    case types.SECOND_REGISTRATION_FORM_POST_STEP_TWO_FAILED:
      return {
        ...state,
        isError: true,
        error: action.error
      };

    case types.SECOND_REGISTRATION_FORM_POST_STEP_THREE_REQUESTED:
      return {
        ...state
      };
    case types.SECOND_REGISTRATION_FORM_POST_STEP_THREE_SUCCEDDED:
      return {
        ...state,
        stepThreeData: action.data
      };
    case types.SECOND_REGISTRATION_FORM_POST_STEP_THREE_FAILED:
      return {
        ...state,
        isError: true,
        error: action.error
      };
    case types.SECOND_REGISTRATION_FORM_POST_STEP_FOUR_REQUESTED:
      return {
        ...state
      };
    case types.SECOND_REGISTRATION_FORM_POST_STEP_FOUR_SUCCEDDED:
      return {
        ...state,
        stepFourData: action.data
      };
    case types.SECOND_REGISTRATION_FORM_POST_STEP_FOUR_FAILED:
      return {
        ...state,
        isError: true,
        error: action.error
      };

    case types.SECOND_REGISTRATION_FORM_POST_STEP_FIVE_REQUESTED:
      return {
        ...state
      };
    case types.SECOND_REGISTRATION_FORM_POST_STEP_FIVE_SUCCEDDED:
      return {
        ...state,
        stepFiveData: action.data
      };
    case types.SECOND_REGISTRATION_FORM_POST_STEP_FIVE_FAILED:
      return {
        ...state,
        isError: true,
        error: action.error
      };

    case types.SECOND_REGISTRATION_FORM_POST_STEP_SIX_REQUESTED:
      return {
        ...state
      };
    case types.SECOND_REGISTRATION_FORM_POST_STEP_SIX_SUCCEDDED:
      return {
        ...state,
        stepSixData: action.data
      };
    case types.SECOND_REGISTRATION_FORM_POST_STEP_SIX_FAILED:
      return {
        ...state,
        isError: true,
        error: action.error
      };

    case types.SECOND_REGISTRATION_FORM_POST_STEP_SEVEN_REQUESTED:
      return {
        ...state
      };
    case types.SECOND_REGISTRATION_FORM_POST_STEP_SEVEN_SUCCEDDED:
      return {
        ...state,
        stepSevenData: action.data
      };
    case types.SECOND_REGISTRATION_FORM_POST_STEP_SEVEN_FAILED:
      return {
        ...state,
        isError: true,
        error: action.error
      };
    case types.SECOND_REGISTRATION_FORM_POST_STEP_EIGHT_REQUESTED:
      return {
        ...state
      };
    case types.SECOND_REGISTRATION_FORM_POST_STEP_EIGHT_SUCCEDDED:
      return {
        ...state,
        stepEightData: action.data
      };
    case types.SECOND_REGISTRATION_FORM_POST_STEP_EIGHT_FAILED:
      return {
        ...state,
        isError: true,
        error: action.error
      };
    case types.SECOND_REGISTRATION_FORM_GET_STEP_EIGHT_REQUESTED:
      return {
        ...state
      };
    case types.SECOND_REGISTRATION_FORM_GET_STEP_EIGHT_SUCCEDDED:
      return {
        ...state,
        stepEightData: action.data
      };
    case types.SECOND_REGISTRATION_FORM_GET_STEP_EIGHT_FAILED:
      return {
        ...state,
        isError: true,
        error: action.error
      };
    default:
      return state;
  }
};

export default secondRegistrationFormReducer;

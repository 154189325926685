import * as types from '../actions/types';
import { pathOr, dropLast } from 'ramda';

const initialState = {
  data: [],
  couponPackages: [],
  total: 0,
  isError: false
};

const couponsReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.COUPON_LIST_RESET:
      return {
        ...state,
        data: [],
        total: 0
      };
    case types.COUPON_LIST_DECREMENT:
      return {
        ...state,
        data: dropLast(1, state.data),
        total: state.total > 0 ? state.total - 1 : 0
      };
    case types.RETRIEVE_COUPON_REQUESTED:
      return {
        ...state
      };
    case types.RETRIEVE_COUPON_SUCCEEDED:
      return {
        ...state,
        data: state.data
      };
    case types.RETRIEVE_COUPON_FAILED:
      return {
        ...state
      };
    case types.GET_COUPON_PACKAGE_REQUESTED:
      return {
        ...state
      };
    case types.GET_COUPON_PACKAGE_SUCCEEDED:
      return {
        ...state,
        couponPackages: state.data
      };
    case types.GET_COUPON_PACKAGE_FAILED:
      return {
        ...state,
        couponPackages: []
      };
    case types.PURCHASE_COUPON_REQUESTED:
      return {
        ...state
      };
    case types.PURCHASE_COUPON_SUCCEEDED:
      return {
        ...state
      };
    case types.PURCHASE_COUPON_FAILED:
      return {
        ...state
      };
    case types.EXCHANGE_COUPON_REQUESTED:
      return {
        ...state,
        error: null
      };
    case types.EXCHANGE_COUPON_SUCCEDDED:
      return {
        ...state,
        isError: false
      };
    case types.EXCHANGE_COUPON_FAILED:
      return {
        ...state,
        isError: true,
        error: action.error
      };
    case types.APPLY_COUPON_REQUESTED:
      return {
        ...state,
        error: null
      };
    case types.APPLY_COUPON_SUCCEEDED:
      return {
        ...state,
        isError: false
      };
    case types.APPLY_COUPON_FAILED:
      return {
        ...state,
        isError: true,
        error: action.error
      };
    case types.COUPON_LIST_SUCCEEDED:
      return {
        ...state,
        data: action.data.data.coupons,
        total: pathOr(0, ['data', 'total'], action),
        error: null
      };
    case types.COUPON_LIST_FAILED:
      return {
        ...state,
        isError: true,
        data: [],
        total: 0,
        error: action.error
      };
    case types.HIDE_ERROR:
      return {
        ...state,
        isError: false
      };
    default:
      return state;
  }
};

export default couponsReducer;

import axios from 'axios';
import { showMaintain, showErrorPopup } from '../actions/index';
import * as api from './api';
import { store } from '../store';
import { initializeReducers } from 'initializable-reducer';
import { getUserLanguage } from '../../helper/LanguageHelper';
import { redirectFB } from '@constants/constant';
import { ResponseStatus } from '../../constants/constant';

const defaultNetworkError = {
  meta: {
    status: 0,
    msg:
      'An error occurred while processing your request. Please try again later.'
  },
  response: null
};

const defaultInvalidToken = {
  meta: {
    status: 401,
    msg: 'Login session expired. Please login again.'
  },
  response: null
};

export default function request(options, type) {
  return axios(options);
}

function* requestAPI(
  path,
  method,
  params = {},
  showGeneralPopup = true,
  timeout = 30000
) {
  //add api want to show global error

  let baseURL = api.API.BASE_URL;
  let additionPath = path;
  let accessToken = yield store.getState().auth.userdata.userAuth
    ? store.getState().auth.userdata.userAuth.token.access_token
    : '';
  //Local
  const lngMap = {
    1: 'en/us',
    2: 'ja/jp'
  };
  const languageId = getUserLanguage();
  let headers = {
    'Content-Type': params.headerContentType || 'application/json',
    'Accept-Language': lngMap[languageId]
  };

  if (accessToken) {
    headers = {
      ...headers,
      Authorization: `Bearer ${accessToken}`
    };
  }

  let response = null;
  try {
    response = yield axios.request({
      url: additionPath,
      method: method,
      baseURL: baseURL,
      headers: headers,
      data: method === api.API.POST ? params : null,
      params: params.query,
      timeout: timeout
    });
    if (response.status === 401) {
      yield store.dispatch(initializeReducers());
      yield localStorage.setItem(
        'redirect_to',
        window.location.pathname.startsWith('/login')
          ? ''
          : window.location.pathname
      );
      // yield redirectFB();
      return response;
      // return response.data;
    } else if (response.status === 20) {
      yield store.dispatch(showMaintain());
      return response;
    }
    return response;
  } catch (e) {
    if (e.response.status === 503) {
      yield store.dispatch(showMaintain());
      return e;
    }
    if (e.response.status === 401) {
      yield store.dispatch(initializeReducers());
      yield localStorage.setItem(
        'redirect_to',
        window.location.pathname.startsWith('/login')
          ? ''
          : window.location.pathname
      );
      window.location.replace('/login');
    }
    if (
      e.response.status == ResponseStatus.VALIDATEFAIL &&
      params.notShowGeneralPopupIfValidateFail
    ) {
      showGeneralPopup = false;
    }
    if (
      //case error
      e.response.status > 400 &&
      showGeneralPopup
    ) {
      //message
      const res = e.response.data;
      let nextUrl = null;
      const message = res.errors
        ? Object.keys(res.errors).reduce((acc, key) => {
            if (res.errors[key].length > 0) {
              let messageCheck = res.errors[key][0].toString();
              if (messageCheck.indexOf('http') > -1) {
                nextUrl = messageCheck;
                return [...acc];
              } else {
                return [...acc, ...res.errors[key]];
              }
            } else {
              return [...acc, ...res.errors[key]];
            }
          }, [])
        : [];

      const action = {
        title: message.length ? message : res.message,
        nextUrl: nextUrl
      };
      yield store.dispatch(showErrorPopup(action));
    }
    return e.response;
  }
}

export { requestAPI };

import React from 'react';
import styles from './index.module.css';

const MessageComponent = ({ messages }) => (
  <div className={styles.messageContainer}>
    {messages.map((message, index) => {
      if (index === messages.length - 1) {
        return (
          <div key={index} className={styles.messageLast}>
            {message.customComponent ? (
              message.customComponent
            ) : (
              <div dangerouslySetInnerHTML={{ __html: message.content }} />
            )}
            {message.icon}
          </div>
        );
      }
      return (
        <div key={index} className={styles.message}>
          {message.customComponent ? (
            message.customComponent
          ) : (
            <div dangerouslySetInnerHTML={{ __html: message.content }} />
          )}
          {message.icon}
        </div>
      );
    })}
  </div>
);

export default MessageComponent;

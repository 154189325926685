import * as types from '../actions/types';

const initialState = {
  isError: false,
  data: {}
};

const evaluationListReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.EVALUATION_LIST_REQUEST:
      return {
        ...state,
        error: null
      };
    case types.EVALUATION_LIST_SUCCEEDED:
      return {
        ...state,
        data: action.data,
        error: null
      };
    case types.EVALUATION_LIST_FAILED:
      return {
        ...state,
        isError: true,
        error: action.error
      };
    case types.HIDE_ERROR:
      return {
        ...state,
        isError: false
      };
    default:
      return state;
  }
};

export default evaluationListReducer;

import React, { Component } from 'react';
import DefaultPopup from '../DefaultPopup';

class DateDecided extends Component {
  renderMessage = () => {
    const messages = (this.props.popup && this.props.popup.message) || '';
    const messagesSplit = messages.split('\n');
    const maxLength = messagesSplit.length;
    return messagesSplit.map((msg, index) => {
      if (msg.length && index + 1 === maxLength) {
        // don't <br/> last msg
        return msg;
      }
      if (msg.length) {
        if (msg.includes('*キャンセル料')) {
          return (
            <>
              <strong>{msg}</strong>
              <br />
            </>
          );
        }
        return (
          <>
            {msg}
            <br />
          </>
        );
      }
      return <br />;
    });
  };
  render() {
    const { popup } = this.props;
    return (
      <DefaultPopup
        popup={popup}
        onButtonClick={this.props.onCloseClick}
        onCloseClick={this.props.onCloseClick}
        renderMessage={this.renderMessage}
      />
    );
  }
}

export default DateDecided;

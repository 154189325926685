import * as types from '../actions/types';

const initialState = {
  chatHistoryData: null,
  isError: false,
  isRefresh: false
};

const chatHistoryReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.CHAT_HISTORY_REQUESTED:
      return {
        ...state,
        error: null
      };
    case types.CHAT_HISTORY_REQUEST_SUCCEEDED:
      return {
        ...state,
        chatHistoryData: action.data,
        error: null
      };
    case types.CHAT_HISTORY_REQUEST_FAILED:
      return {
        ...state,
        isError: true,
        chatHistoryData: null,
        error: action.error
      };

    case types.HIDE_ERROR:
      return {
        ...state,
        isError: false
      };
    default:
      return state;
  }
};

export default chatHistoryReducer;

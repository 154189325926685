import * as types from '../actions/types';
const INITIAL = {
  isRepair: false
};

const serverRepair = (state = INITIAL, action) => {
  switch (action.type) {
    case types.SHOW_MAINTAIN:
      return {
        ...state,
        isRepair: true
      };
    default:
      return state;
  }
};

export default serverRepair;

import i18n from 'i18next';
import { reactI18nextModule } from 'react-i18next';
import backend from 'i18next-xhr-backend';
import { getUserLanguage } from '../helper/LanguageHelper';

const lngMap = {
  1: 'en',
  2: 'jp'
};

const languageId = getUserLanguage();

const localLangId = localStorage.getItem('lang');
if (localLangId != languageId) {
  localStorage.setItem('requestChangeLanguage', '1');
  localStorage.setItem('lang', languageId);
}

const i18nConfig = {
  lng: 'jp',
  fallbackLng: 'jp',
  keySeparator: false,
  interpolation: {
    escapeValue: false
  },
  react: {
    wait: true
  }
};

i18n
  .use(backend)
  .use(reactI18nextModule)
  .init(i18nConfig, (err, t) => {});

export default i18n;

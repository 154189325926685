import { call, put, takeLatest } from 'redux-saga/effects';
import * as types from '../actions/types';
import { requestAPI } from '../services/request';
import { API } from '../services/api';
import { ResponseStatus } from '../../constants/constant';
import { store } from '../store';
import { hideLoading, showLoading } from '../actions';

function* cancellationAccount(action) {
  let response = null;
  store.dispatch(showLoading());
  try {
    response = yield call(
      requestAPI,
      API.CANCEL_ACCOUNT,
      API.POST,
      action.params
    );

    if (response.status === ResponseStatus.SUCCESS) {
      yield put({
        type: types.CANCEL_PROFILE_SUCCEEDED
      });
    } else if (response.status === ResponseStatus.FAILURE) {
      console.error('SAGA:', response.message);
      yield put({ type: types.CANCEL_PROFILE_FAILED, error: response });
    } else {
      yield put({ type: types.CANCEL_PROFILE_FAILED, error: response });
    }
    if (action.onComplete) {
      action.onComplete(response.status ? response : response.response);
    }
  } catch (error) {
    yield put({ type: types.CANCEL_PROFILE_FAILED, error });
  }
  store.dispatch(hideLoading());
}

export function* watchCancelProfile() {
  yield takeLatest(types.CANCEL_PROFILE_REQUESTED, cancellationAccount);
}

import React, { PureComponent } from 'react';
import leftArrow from '@assets/arrowLeft.png';
import styles from './index.module.css';

class BackArrowIcon extends PureComponent {
  render() {
    return (
      <img
        src={leftArrow}
        alt="go back"
        className={this.props.className || styles.navIcon}
        onClick={this.props.onClick}
      />
    );
  }
}

export default BackArrowIcon;

import * as types from '../actions/types';

const initialState = {
  isError: false,
  data: [],
  error: []
};

const datingDayAbleToChatReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.GET_DATING_DAY_ABLE_TO_CHAT_REQUESTED:
      return {
        ...state
      };
    case types.GET_DATING_DAY_ABLE_TO_CHAT_SUCCEEDED:
      return {
        ...state,
        data: action.data.data
      };
    case types.GET_DATING_DAY_ABLE_TO_CHAT_FAILED:
      return {
        ...state,
        isError: true,
        data: [],
        error: action.error
      };
    default:
      return state;
  }
};

export default datingDayAbleToChatReducer;

import React, { Component } from 'react';
import { compose } from 'recompose';
import { withNamespaces } from 'react-i18next';
import styles from './index.module.css';
import classNames from 'classnames';

import bachelorUser from '../../../assets/bachelorUser.png';

class ParkSuggestionMessage extends Component {
  render() {
    const { t, parkSuggestion, isWhiteMode } = this.props;
    const { text2 = '', text3 = '', cafeList = [] } = parkSuggestion;

    return (
      <span className={styles.partnerTextMessage}>
        <img alt="" src={bachelorUser} className={styles.chatUserIcon} />
        <div
          className={classNames(styles.chatPartnerTextBubble, {
            [styles.textBlack]: isWhiteMode
          })}
        >
          <div className={styles.text2}>
            {text3}
            {text2}
          </div>
          <div className={styles.text1}>
            ({t('cafe-suggestion-message-not-visible')})
          </div>
          {cafeList.map((cafe, idx) => (
            <React.Fragment key={idx}>
              <br />
              <div className={styles.cafeDetail}>
                <a
                  href={cafe.cafe_link}
                  className={classNames(styles.parkName, {
                    [styles.textBlack]: isWhiteMode
                  })}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <u>{cafe.name}</u>
                </a>
                <br />
                {t('cafe-suggestion-message-station')} : {cafe.station_name}
              </div>
            </React.Fragment>
          ))}
        </div>
      </span>
    );
  }
}

export default compose(withNamespaces('chat', { wait: true }))(
  ParkSuggestionMessage
);

import React from 'react';
import styles from './index.module.css';

const MessageComponent = ({ messages }) => (
  <div className={styles.messageContainer}>
    <ul>
      {messages.map(message => (
        <li className={styles.message}>{message}</li>
      ))}
    </ul>
  </div>
);

export default MessageComponent;

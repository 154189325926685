import { convert } from '@js-joda/core';
import {
  REGISTRATION_STEPS,
  RegistrationFormKey,
  UserGender,
  UserStatus,
  LpQueryStr,
  DateSlot,
  Colors
} from '../constants/constant';
import isString from 'lodash/isString';
import moment from 'moment';
import findIndex from 'lodash/findIndex';
import queryString from 'query-string';
import { ReactComponent as ArrowLeft } from '@assets/newSystem/squareItem/svg/arrow_left.svg';
import { ReactComponent as Menu } from '@assets/newSystem/squareItem/svg/menu.svg';
import { ReactComponent as ArrowLeftChat } from '@assets/arrow_left_chat.svg';
import React from 'react';
import classNames from 'classnames';
import styleSvg from '../styles/index.module.css';

export const formatMatchingDate = originalDate => {
  const date = new Date(originalDate);
  if (!isNaN(date.getTime())) {
    // Months use 0 index.
    return date.getMonth() + 1 + '/' + date.getDate();
  }
};

export const showTimeRemaining = secs => {
  const date = secs / 86400;
  let newDate = '';
  if (date <= 1) {
    newDate = secs;
  } else {
    newDate = `${Math.round(date)}日`;
  }
  return newDate;
};

export const generateSelectedIndexes = (data, value) => {
  return data
    .map(item => {
      return parseInt(item.value);
    })
    .indexOf(parseInt(value));
};

export const generateYearData = () => {
  const data = [];
  const currentYear = new Date().getFullYear();
  for (let i = currentYear; i <= currentYear + 10; i++) {
    data.push({ value: i.toString(), label: i });
  }
  return data;
};

export const showStatusUser = status => {
  switch (status) {
    case 4:
      return '本合格';
    case 5:
      return '休会';
    default:
      return '--';
  }
};

export const resizeIcon = item => {
  switch (item.title) {
    case '面白い':
      return { width: 43, height: 30 };
    case '明るい':
      return { width: 44, height: 44 };
    case 'ときどき飲む':
      return { width: 40, height: 35 };
    case '飲む':
      return { width: 30, height: '35.09px' };
    case 'ドライブ':
      return { maxWidth: 'unset', width: 61, height: 26 };
    case 'キャンプ':
      return { maxWidth: 'unset', width: 44, height: '31.94px' };
    case '旅行':
      return { width: 36, height: 34 };
    case 'ランニング':
      return { width: 45, height: 35, maxWidth: 'unset' };
    case 'サーフィン':
      return { width: 43, height: 46, maxWidth: 'unset', maxHeight: 'unset' };
    case 'ゴルフ':
      return { width: 39, height: 42, maxHeight: 'unset' };
    case 'ダーツ':
      return { width: 39, height: 40, maxHeight: 'unset' };
    case 'サイクリング':
      return { maxHeight: '50%' };
    case '趣味':
      return { width: 39, height: '30.12px', maxWidth: 'unset' };
    case '年齢':
      return { width: '17.46px', height: 41 };
    case '仕事':
      return {
        width: '17.45px',
        height: 49,
        maxWidth: 'unset',
        maxHeight: 'unset'
      };
    case '仕事好き':
      return {
        width: '17.45px',
        height: 49,
        maxWidth: 'unset',
        maxHeight: 'unset'
      };
    case '写真・カメラ':
      return { width: 44, height: 31, maxWidth: 'unset' };
    case '家で':
      return { width: 39, height: '30.12px' };
    case '優しい':
      return { width: 33, height: 31 };
    case 'グルメ':
      return { width: 19, height: 46, maxHeight: 'unset' };
    case '家庭的':
      return { width: 40, height: 44, maxHeight: 'unset' };
    case '体型':
      return { width: 21, height: 51, maxHeight: 'unset' };
    case 'お酒':
      return { width: 21, height: 42, maxHeight: 'unset' };
    case '身長':
      return { width: 20, height: 49, maxHeight: 'unset' };
    case 'ときどき吸う':
      return { marginBottom: 11 };
    case '吸う':
      return { marginBottom: 11 };
    case 'タバコ':
      return { marginBottom: 13 };
    case '車・ドライブ':
      return { width: 70, height: 36, maxWidth: '100%' };
    default:
      return;
  }
};

export const renderMultiLabel = item => {
  switch (item.title) {
    case '落ち着きが':
      return [item.title, 'ある'];
    case 'ぱっちりした':
      return [item.title, '目'];
    case '結婚に対する':
      return [item.title, '意思'];
    case 'スポーツ':
      return [item.title, '観戦'];
    case 'カフェ':
      return [item.title, 'スイーツ'];
    case '(電子タバコ)':
      return ['吸う', item.title];
    case '相手が嫌':
      return [item.title, 'なら辞める'];
    case '今は考えて':
      return [item.title, 'いない'];
    case 'いい人がいたら':
      return [item.title, 'したい'];
    case 'すぐにでも':
      return [item.title, 'したい'];
    case '(子供なし)':
      return ['あり', item.title];
    case '(子供あり:別居)':
      return ['あり', item.title];
    case '(子供あり:同居)':
      return ['あり', item.title];
    case 'ドライブ':
      return ['車', item.title];
    case 'ダーツ':
      return [item.title, 'ビリヤード'];
    case 'サーフィン':
      return [item.title, 'スノボ'];
    case 'インテリア':
      return [item.title, 'DIY'];
    case 'ゲーム':
      return [item.title, '漫画'];
    case '読書':
      return [item.title, '小説'];
    case '映画':
      return [item.title, 'ドラマ'];
    case '料理':
      return [item.title, 'お菓子作り'];
    case '家で':
      return [item.title, 'まったり'];
    case '旅行':
      return [item.title, '温泉'];
    default:
      return;
  }
};

export const formatMatchProfile = resp => {
  let thisWeek = [];
  let nextWeek = [];
  let nextNextWeek = [];
  let newWeeks = [];
  let response;
  const revertDating = day => {
    if (day.length > 1 && day[0].dating_day_slot === DateSlot.EVENING_TIME) {
      day.reverse();
    }
    return day;
  };
  const weeks = resp.weeks;
  const wedThisWeek =
    weeks[0] && weeks[0].Wednesday ? revertDating(weeks[0].Wednesday) : '';
  const satThisWeek =
    weeks[0] && weeks[0].Saturday ? revertDating(weeks[0].Saturday) : '';
  const sunThisWeek =
    weeks[0] && weeks[0].Sunday ? revertDating(weeks[0].Sunday) : '';
  const wedNextWeek =
    weeks[1] && weeks[1].Wednesday ? revertDating(weeks[1].Wednesday) : '';
  const satNextWeek =
    weeks[1] && weeks[1].Saturday ? revertDating(weeks[1].Saturday) : '';

  const sunNextWeek =
    weeks[1] && weeks[1].Sunday ? revertDating(weeks[1].Sunday) : '';
  const wedNextNextWeek =
    weeks[2] && weeks[2].Wednesday ? revertDating(weeks[2].Wednesday) : '';
  const satNextNextWeek =
    weeks[2] && weeks[2].Saturday ? revertDating(weeks[2].Saturday) : '';
  const sunNextNextWeek =
    weeks[2] && weeks[2].Sunday ? revertDating(weeks[2].Sunday) : '';

  thisWeek = [...wedThisWeek, ...satThisWeek, ...sunThisWeek].filter(
    item => Object.keys(item).length
  );
  nextWeek = [...wedNextWeek, ...satNextWeek, ...sunNextWeek].filter(
    item => Object.keys(item).length
  );
  nextNextWeek = [
    ...wedNextNextWeek,
    ...satNextNextWeek,
    ...sunNextNextWeek
  ].filter(item => Object.keys(item).length);

  newWeeks.push(thisWeek, nextWeek, nextNextWeek);
  response = { ...resp, weeks: newWeeks };
  return response;
};

export const convertMatchingDayToJapanese = (day, showBracket = false) => {
  switch (day.toLowerCase()) {
    case 'wednesday':
      return showBracket ? '(水)' : '水';
    case 'saturday':
      return showBracket ? '(土)' : '土';
    case 'sunday':
      return showBracket ? '(日)' : '日';
    default:
      return;
  }
};

export const generateHeaderTitle = day => {
  switch (day.toLowerCase()) {
    case 'wednesday':
      return '水曜の詳細';
    case 'saturday':
      return '土曜の詳細';
    case 'sunday':
      return '日曜の詳細';
    default:
      return;
  }
};

export const timeNow = (date, hours = 0) => {
  let now = new Date(date),
    ampm = 'am',
    d = now.getDate(),
    month = now.getMonth() + 1,
    y = now.getFullYear(),
    h = now.getHours() + hours,
    // h = now.setHours(now.getHours() + hours),
    min = now.getMinutes(),
    s = now.getSeconds();
  if (h >= 12) {
    if (h > 12) h -= 12;
    ampm = 'pm';
  }

  const options = {
    weekday: 'long',
    year: 'numeric',
    month: 'long',
    day: 'numeric',
    hour: 'numeric',
    minute: '2-digit',
    second: '2-digit'
  };

  if (d < 10) d = '0' + d;
  if (h < 10) h = '0' + h;
  if (month < 10) month = '0' + month;
  if (min < 10) min = '0' + min;
  if (s < 10) s = '0' + s;
  return y + '-' + month + '-' + d + ' ' + h + ':' + min + ':' + s;
  // return type === 1? y + '-' + month + '-' + d: now.toLocaleDateString('en-US',options);
};

export const convertLocalTimeToDate = time => {
  const jsDate = convert(time).toDate(); // Convert to JavaScript `Date` object

  const options = {
    weekday: 'long',
    year: 'numeric',
    month: 'long',
    day: 'numeric',
    hour: 'numeric',
    minute: '2-digit',
    second: '2-digit'
  };

  return jsDate.toLocaleDateString('en-US', options); // Saturday, April 28, 2018, 12:34
};

export const renderTitlePicker = (label, length = 12) => {
  if (
    label !== undefined &&
    label !== '選択してください' &&
    label.length > length
  ) {
    return label.substr(0, length) + '...';
  }
  return label;
};

export const isEmail = email => {
  return /.+@.+\.[A-Za-z]+$/.test(email);
};

// change the type of the input to password
export const changeToPassword = () => {
  setTimeout(function() {
    document.getElementById('password').setAttribute('type', 'password');
  }, 300);
};

/**
 *
 * @param {String} currentServerTime
 * @param {String} datingDateText
 * @returns
 */
export const checkDatingDayBefore = (currentServerTime, datingDateText) => {
  const currentDate = moment(currentServerTime).format('MM/DD');
  const datingDate = datingDateText
    ? (isString(datingDateText)
        ? datingDateText
        : datingDateText.toString()
      ).substring(0, 5)
    : currentDate;

  // condition:
  // 1. currentMonth is 12 and datingMonth is 1, which means datingDate is in the new year
  // 2. currentDate < datingDate
  return (
    (parseInt(currentDate.split('/')[0]) == 12 &&
      parseInt(datingDate.split('/')[0]) == 1) ||
    moment(currentDate, 'MM/DD').isBefore(moment(datingDate, 'MM/DD'))
  );
};

// set default value for items based on value
export const setDefaultValueForItems = (items, value) => {
  if (findIndex(items, e => e.checked === true) < 0) {
    items[findIndex(items, e => e.value === value)].checked = true;
  }
};

/**
 * Find element's ancester following className
 * @param {HTMLElement} el
 * @param {String} cls
 */
export const findAncestor = (el, cls) => {
  while (el && (el = el.parentElement) && !el.classList.contains(cls)) {
    //
  }
  return el;
};

// generate number array based on input data
export function generateNumberArray(
  begin,
  end,
  title = '歳',
  userAge = null,
  isPremiumUser = false,
  check = false,
  userGender = 0,
  specialTitleBegin = '',
  specialTitleEnd = ''
) {
  let array = [];
  for (let i = begin; i <= end; i++) {
    if (userAge && (!check || (check && userGender === UserGender.MALE))) {
      if (
        userAge > RegistrationFormKey.AGE_NEED_TO_CHECK_PREMIUM &&
        i <=
          parseInt(userAge) -
            RegistrationFormKey.MIN_RANGE_AFTER_40_NEED_PREMIUM &&
        !isPremiumUser
      ) {
        array.push({
          label: (i < 10 ? '0' : '') + i + '歳(プレミアムプラン限定)',
          value: i
        });
      } else {
        array.push({ label: (i < 10 ? '0' : '') + i + title, value: i });
      }
    } else {
      if (specialTitleBegin || specialTitleEnd) {
        if (specialTitleBegin && i == begin) {
          array.push({
            label: (i < 10 ? '0' : '') + i + specialTitleBegin,
            value: i
          });
        } else if (specialTitleEnd && i == end) {
          array.push({
            label: (i < 10 ? '0' : '') + i + specialTitleEnd,
            value: i
          });
        } else {
          array.push({ label: (i < 10 ? '0' : '') + i + title, value: i });
        }
      } else {
        array.push({ label: (i < 10 ? '0' : '') + i + title, value: i });
      }
    }
  }
  return array;
}

// update 'AgeTo' data array for 2nd step of 1st form
export const updateAgeToData = (ageArray, isPremiumUser, userAge) => {
  if (ageArray[0].length === 0) return [];
  const array = ageArray[0].map(e => ({
    label:
      e.value === 99 ||
      parseInt(e.value) >
        parseInt(userAge) - RegistrationFormKey.MIN_RANGE_AFTER_40_NEED_PREMIUM
        ? e.label
        : isPremiumUser
        ? e.label.substring(0, parseInt(e.label.indexOf('歳') + 1))
        : e.label.substring(0, parseInt(e.label.indexOf('歳') + 1)) +
          '(プレミアムプラン限定)',
    value: e.value
  }));
  return [array];
};

export const handleGoBackPage = container => {
  container.props.history.action === 'POP'
    ? container.props.history.push('/profile')
    : container.props.history.goBack();
};

export const validateUserRegistrationStep = (
  container,
  registrationStep,
  currentStep,
  gender = null
) => {
  //if user has not completed previous step then we navigate user to correct step
  if (registrationStep === null) {
    registrationStep = -1;
  }
  const preStep =
    gender !== null &&
    gender === UserGender.MALE &&
    currentStep === REGISTRATION_STEPS.StepSix
      ? currentStep - 2
      : currentStep - 1;
  if (registrationStep < preStep) {
    const step = registrationStep === null ? 0 : registrationStep;
    if (step < REGISTRATION_STEPS.StepSeven) {
      const stepFirstForm =
        gender !== null &&
        gender === UserGender.MALE &&
        currentStep === REGISTRATION_STEPS.StepSix
          ? step + 2
          : step + 1;
      container.props.history.push('/registration-form/step-' + stepFirstForm);
      return false;
    } else {
      const stepSecondForm =
        gender !== null &&
        gender === UserGender.FEMALE &&
        currentStep === REGISTRATION_STEPS.StepFourteenth
          ? step - REGISTRATION_STEPS.StepSeven + 2
          : step - REGISTRATION_STEPS.StepSeven + 1;
      container.props.history.push(
        '/2nd-registration-form/step-' + stepSecondForm
      );
      return false;
    }
  }
  return true;
};

export const showFeeWithComma = fee => {
  return fee != null && fee != undefined
    ? fee.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
    : fee;
};

export const bindingHeight = (
  height,
  min_height,
  max_height,
  noSpace = false
) => {
  if (height == min_height) {
    return height + `${noSpace ? '' : ' '}cm以下`;
  } else if (height == max_height) {
    return height + `${noSpace ? '' : ' '}cm以上`;
  } else if (height > 0) {
    return height + `${noSpace ? '' : ' '}cm`;
  } else {
    return '選択してください';
  }
};

export const isBeforeApprovalUser = userStatus => {
  let beforeApprovalUserStatus = [
    UserStatus.INCOMPLETE_PROFILE,
    UserStatus.AWAITING_SCREENING
  ];
  return beforeApprovalUserStatus.includes(userStatus);
};

export const getLabelComboBox = (event, array) => {
  return array.length > 0
    ? array.filter(e => e.value == event.target.value)[0].label
    : '';
};

export const getUserTypeValue = userType => {
  const lpQueryStr = queryString.parse(
    localStorage.getItem(LpQueryStr.LOCAL_STORAGE_KEY)
  );
  const queryUserType = lpQueryStr ? lpQueryStr.user_type : null;
  return userType || queryUserType;
};

export const isIOSSafari = () => {
  const ua = window.navigator.userAgent;
  const iOSMobile = !!ua.match(/iPad/i) || !!ua.match(/iPhone/i);
  const webkit = !!ua.match(/WebKit/i);
  const isSafari =
    ua &&
    ua.indexOf('CriOS') == -1 &&
    ua.indexOf('FxiOS') == -1 &&
    ua.indexOf('OPiOS') == -1;
  return iOSMobile && webkit && isSafari;
};

export const renderLeftContent = (isArrow, isWhiteMode, onCLick) => {
  let color = isWhiteMode ? Colors.BLACK : '#C0A76E';
  return isArrow ? (
    <ArrowLeft stroke={color} onClick={onCLick} />
  ) : (
    <Menu stroke={color} />
  );
};

export const renderLeftContentChat = (
  isArrow,
  isWhiteMode,
  onCLick,
  countUnread,
  isShowUnreadMessage
) => {
  let color = isWhiteMode ? Colors.BLACK : '#C0A76E';
  return isArrow ? (
    <>
      <ArrowLeftChat stroke={color} onClick={onCLick} />
      <span style={{ marginLeft: '4px', fontSize: '18px' }}>
        {countUnread && isShowUnreadMessage ? countUnread : ''}
      </span>
    </>
  ) : (
    <Menu stroke={color} />
  );
};

export const renderStyleIcon = (
  checked,
  isStroke = true,
  marginRight = false,
  activeClass = null
) => {
  const isWhiteMode = JSON.parse(localStorage.getItem('isWhiteMode'));

  const defaultStyleStroke = isWhiteMode
    ? styleSvg.isSVGStrokeBlack
    : styleSvg.isSVGStrokeWhite;

  const defaultStyleBoth = isWhiteMode
    ? styleSvg.isSVGBothBlack
    : styleSvg.isSVGBothWhite;

  return classNames(
    isStroke ? defaultStyleStroke : defaultStyleBoth,

    isStroke
      ? activeClass
        ? { [activeClass]: checked }
        : {
            [styleSvg.isSVGStrokeWhite]: checked,
            [styleSvg.marginRight]: marginRight
          }
      : { [styleSvg.isSVGBothWhite]: checked }
  );
};

export const convertDateSlot = slot => {
  return slot === DateSlot.EVENING_TIME ? '夕方' : 'お昼';
};

import React, { Component } from 'react';
import { compose } from 'recompose';
import { withNamespaces } from 'react-i18next';
import styles from './index.module.css';
import bachelorUser from '../../../assets/bachelorUser.png';
import classNames from 'classnames';

class VideoSuggestionMessage extends Component {
  render() {
    const { t, onClickVideoMessage, isWhiteMode } = this.props;

    return (
      <span className={styles.partnerTextMessage}>
        <img alt="" src={bachelorUser} className={styles.chatUserIcon} />
        <div
          className={classNames(styles.chatPartnerTextBubble, {
            [styles.textBlack]: isWhiteMode
          })}
        >
          <div className={styles.text1}>
            {t('chat-video-message-suggestion-1')}
          </div>
          <div className={styles.text1}>
            {t('chat-video-message-suggestion-2')}
          </div>
          <br />
          <div className={styles.text1}>
            {t('chat-video-message-suggestion-3')}
            <span className={styles.chatLinkText} onClick={onClickVideoMessage}>
              {t('chat-video-message-suggestion-4')}
            </span>
          </div>
        </div>
      </span>
    );
  }
}

export default compose(withNamespaces('chat', { wait: true }))(
  VideoSuggestionMessage
);

import React, { Component } from 'react';
import Textarea from 'react-textarea-autosize';
import { withNamespaces } from 'react-i18next';
import styles from './index.module.css';
import sendButton from '@assets/send-button.png';
import { Spinner } from '../../Loading';
import classNames from 'classnames';

// Used for typing messages during chat.
// Được sử dụng để gõ tin nhắn trong khi trò chuyện.
// -----------------------
class ChatTextInput extends Component {
  state = {
    text: ''
  };

  // Will show button send when typing, when remove all will hide button
  onTyping = event => {
    this.setState({
      text: event.target.value
    });
  };

  onSendChatMessage = () => {
    this.props.bachelorChatManager.sendMessage(this.state.text);
    this.props.saveChatHistory(this.state.text);
    this.setState({
      text: ''
    });
  };

  render() {
    const { t, chatRoomIsReady, isWhiteMode } = this.props;

    return (
      <span
        className={classNames(styles.chatTextInput, {
          [styles.chatTextInputWhiteMode]: isWhiteMode
        })}
      >
        <Textarea
          value={this.state.text}
          onChange={this.onTyping}
          className={classNames(styles.textInputBox, {
            [styles.textInputBoxWhiteMode]: isWhiteMode
          })}
          placeholder={t('text-input-placeholder')}
          maxRows={3}
        />
        {chatRoomIsReady ? (
          <div
            onClick={this.onSendChatMessage}
            hidden={this.state.text.trim().length === 0}
            className={styles.sendButtonText}
          >
            <img
              alt="send-button"
              className={styles.sendButtonImg}
              src={sendButton}
            />
          </div>
        ) : (
          <Spinner
            hidden={this.state.text.length === 0}
            className={styles.loadingChatContainer}
          />
        )}
      </span>
    );
  }
}

export default withNamespaces('chat', { wait: true })(ChatTextInput);

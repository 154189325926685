import { call, put, takeLatest } from 'redux-saga/effects';
import * as types from '../actions/types';
import { requestAPI } from '../services/request';
import { API } from '../services/api';
import { ResponseStatus } from '../../constants/constant';
import { store } from '../store';
import { hideLoading, showLoading } from '../actions';

function* getDatingDayAbleToChat(action) {
  let response = null;
  store.dispatch(showLoading());
  try {
    response = yield call(
      requestAPI,
      `${API.GET_DATING_DAY_ABLE_TO_CHAT}?dayOfWeek=${action.params.datingDayOfWeek}&roomId=${action.params.roomId}&datingDayId=${action.params.datingDayId}`,
      API.GET,
      action.params
    );

    if (response.status === ResponseStatus.SUCCESS) {
      yield put({
        type: types.GET_DATING_DAY_ABLE_TO_CHAT_SUCCEEDED,
        data: response.data
      });
    } else {
      yield put({
        type: types.GET_DATING_DAY_ABLE_TO_CHAT_FAILED,
        error: response
      });
    }
    if (action.onComplete) {
      action.onComplete(response);
    }
  } catch (error) {
    yield put({ type: types.GET_DATING_DAY_ABLE_TO_CHAT_FAILED, error });
  }
  store.dispatch(hideLoading());
}

export function* watchGetDatingDayAbleToChat() {
  yield takeLatest(
    types.GET_DATING_DAY_ABLE_TO_CHAT_REQUESTED,
    getDatingDayAbleToChat
  );
}

import { call, put, takeLatest } from 'redux-saga/effects';
import * as types from '../actions/types';
import { requestAPI } from '../services/request';
import { API } from '../services/api';
import { ResponseStatus } from '../../constants/constant';
import { store } from '../store';
import { hideLoading, showLoading } from '../actions';

function* getUserFeedbackDetails(action) {
  let response = null;
  const showGeneralPopup = false;
  store.dispatch(showLoading());
  try {
    response = yield call(
      requestAPI,
      API.FEEDBACK_DETAILS + `?dating_id_encoded=${action.params.id}`,
      API.GET,
      action.params,
      showGeneralPopup
    );

    if (response.status === ResponseStatus.SUCCESS) {
      yield put({
        type: types.USER_FEEDBACK_DETAILS_SUCCEEDED,
        data: response.data
      });
    } else {
      yield put({
        type: types.USER_FEEDBACK_DETAILS_FAILED,
        error: response
      });
    }
    if (action.onComplete) {
      action.onComplete(response);
    }
  } catch (error) {
    yield put({
      type: types.USER_FEEDBACK_DETAILS_FAILED,
      error
    });
  }
  store.dispatch(hideLoading());
}

function* saveUserFeedback(action) {
  let response = null;
  store.dispatch(showLoading());
  try {
    response = yield call(
      requestAPI,
      API.FEEDBACK + '/create',
      API.POST,
      action.params
    );

    if (response.status === ResponseStatus.SUCCESS) {
      yield put({
        type: types.SAVE_USER_FEEDBACK_SUCCEEDED,
        data: response.data
      });
    } else {
      yield put({
        type: types.SAVE_USER_FEEDBACK_FAILED,
        error: response
      });
    }
    if (action.onComplete) {
      action.onComplete(response);
    }
  } catch (error) {
    yield put({ type: types.SAVE_USER_FEEDBACK_FAILED, error });
  }
  store.dispatch(hideLoading());
}

function* checkSendFeedback(action) {
  let response = null;
  store.dispatch(showLoading());
  try {
    response = yield call(
      requestAPI,
      API.FEEDBACK + '/check-send-feedback',
      API.GET,
      action.params
    );

    if (response.status === ResponseStatus.SUCCESS) {
      yield put({
        type: types.CHECK_SEND_FEEDBACK_SUCCEEDED,
        data: response.data
      });
    } else {
      yield put({ type: types.CHECK_SEND_FEEDBACK_FAILED, error: response });
    }
    if (action.onComplete) {
      action.onComplete(response);
    }
  } catch (error) {
    yield put({ type: types.CHECK_SEND_FEEDBACK_FAILED, error });
  }
  store.dispatch(hideLoading());
}

function* getAvailableFeedbackFlexQuestions(action) {
  let response = null;
  const showGeneralPopup = false;
  store.dispatch(showLoading());
  try {
    response = yield call(
      requestAPI,
      API.API_FEEDBACK_FLEX_QUESTIONS,
      API.GET,
      action.params,
      showGeneralPopup
    );

    if (response.status === ResponseStatus.SUCCESS) {
      yield put({
        type: types.GET_AVAILABLE_FEEDBACK_FLEX_QUESTION_SUCCEEDED,
        data: response.data
      });
    } else {
      yield put({
        type: types.GET_AVAILABLE_FEEDBACK_FLEX_QUESTION_FAILED,
        error: response
      });
    }
    if (action.onComplete) {
      action.onComplete(response);
    }
  } catch (error) {
    yield put({
      type: types.GET_AVAILABLE_FEEDBACK_FLEX_QUESTION_FAILED,
      error
    });
  }
  store.dispatch(hideLoading());
}

export function* watchSaveUserFeedback() {
  yield takeLatest(types.SAVE_USER_FEEDBACK_REQUESTED, saveUserFeedback);
}

export function* watchCheckSendFeedback() {
  yield takeLatest(types.CHECK_SEND_FEEDBACK_REQUESTED, checkSendFeedback);
}

export function* watchGetUserFeedbackDetails() {
  yield takeLatest(
    types.USER_FEEDBACK_DETAILS_REQUESTED,
    getUserFeedbackDetails
  );
}

export function* watchGetAvailableFeedbackFlexQuestions() {
  yield takeLatest(
    types.GET_AVAILABLE_FEEDBACK_FLEX_QUESTION_REQUESTED,
    getAvailableFeedbackFlexQuestions
  );
}

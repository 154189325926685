import React from 'react';
import styles from './index.module.css';

const MessageComponent = ({ message }) => (
  <div className={styles.messageContainer}>
    <div className={styles.message}>{message}</div>
  </div>
);

export default MessageComponent;

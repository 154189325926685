import { call, put, takeLatest } from 'redux-saga/effects';
import * as types from '../actions/types';
import { requestAPI } from '../services/request';
import { API } from '../services/api';
import { ResponseStatus } from '../../constants/constant';
import { store } from '../store';
import { hideLoading, showLoading } from '../actions';

function* updateEmail(action) {
  let response = null;
  const showGeneralPopup = false;
  store.dispatch(showLoading());
  try {
    response = yield call(
      requestAPI,
      API.API_UPDATE_EMAIL,
      API.POST,
      action.params,
      showGeneralPopup
    );

    if (response.status === ResponseStatus.SUCCESS) {
      yield put({
        type: types.UPDATE_EMAIL_SUCCEEDED,
        data: response.data
      });
    } else {
      yield put({ type: types.UPDATE_EMAIL_FAILED, error: response });
    }
    if (action.onComplete) {
      action.onComplete(response);
    }
  } catch (error) {
    yield put({ type: types.UPDATE_EMAIL_FAILED, error });
  }
  store.dispatch(hideLoading());
}

export function* watchUpdateEmail() {
  yield takeLatest(types.UPDATE_EMAIL_REQUESTED, updateEmail);
}

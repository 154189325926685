import { Client } from 'twilio-chat';

class TwilioChatManager {
  constructor(options) {
    this.chatRoomData = options.chatRoomData;
    this.baseUserId = `${options.chatRoomData.user_id}`; // userId needs to be a string
    this.token = `${options.chatRoomData.user_token}`; // twilio token of user
    this.chatClient = null;
    this.connected = false;
    this.shouldIgnoreOnMessageHook = true;
    this.partnerLastConsumedMessageIndex = null;
  }

  get isConnected() {
    return this.connected;
  }

  async connect() {
    try {
      this.chatClient = await Client.create(this.token);

      this.currentUser = await this.chatClient.getUser(this.baseUserId);
      this.channelClient = await this.chatClient.getChannelByUniqueName(
        this.chatRoomData.room_id
      );
      await this._getPartnerLastConsumedMessageIndex();

      this.userClient = await this.chatClient.getUser(this.baseUserId);
      let members = await this.channelClient.getMembers();
      let currentUser = members.find(
        member => member.identity == this.baseUserId
      );
      if (currentUser) {
        await currentUser.updateAttributes({ cancelDating: false });
      }
      this.connected = true;
      console.log('Successful connection', this.currentUser); // TODO: remove
      // this.setupListeners();
    } catch (err) {
      this.currentUser = null;
      this.channelClient = null;
      this.userClient = null;
      this.connected = false;
      console.log('Error on connection', err); // TODO: remove
    }
  }

  subscribeToRoom(hooks, messageLimit = 100) {
    let { messageAdded, userUpdated, memberUpdated } = hooks;
    this.channelClient.on('memberUpdated', message => memberUpdated(message));
    this.channelClient.on('messageAdded', message => messageAdded(message));
    this.userClient.on('updated', message => userUpdated(message));
  }

  sendMessage = async message => {
    if (message.trim().length) {
      if (!(await this.isCancelDating())) {
        this.channelClient.sendMessage(message);
      } else {
        window.location.pathname = '/matchprofile';
      }
      // this.saveChatHistory(message.text);
    }
  };

  _getPartnerLastConsumedMessageIndex = async () => {
    let members = await this.channelClient.getMembers();
    if (this.baseUserId && members.length) {
      let partner = members.find(member => member.identity !== this.baseUserId);
      if (partner) {
        this.partnerLastConsumedMessageIndex = partner.lastConsumedMessageIndex;
      }
    }
  };

  async getMessages(messageLimit = 100) {
    let resp = await this.channelClient.getMessages(messageLimit);
    let messages = resp ? resp.items : [];
    return messages;
  }

  async setReadCursor(position) {
    try {
      await this.channelClient.updateLastConsumedMessageIndex(position);
      console.log('Successfully set read cursor for message id: ', position);
      return true;
    } catch (err) {
      console.log(`Error setting cursor: ${err}`);
      return false;
    }
  }

  readCursor() {
    const cursor = this.currentUser.readCursor({
      roomId: this.chatRoomData.room_id,
      userId: this.chatRoomData.user_id
    });
    return cursor;
  }

  setupListeners() {
    //    // handle when user is viewing chat room session
    //    window.addeventlistener('focus', () => {
    //      console.log('window was focused');
    //    });
    //
    //    // handle when user is not viewing chat room session
    //    window.addeventlistener('blur', () => {
    //      console.log('window was blurred');
    //    });
  }

  async cancelDating() {
    let members = await this.channelClient.getMembers();
    let currentUser = members.find(
      member => member.identity == this.baseUserId
    );
    if (currentUser) {
      await currentUser.updateAttributes({ cancelDating: true });
    }
  }

  async isCancelDating() {
    let members = await this.channelClient.getMembers();
    let partner = members.find(member => member.identity != this.baseUserId);
    return partner.attributes.cancelDating;
  }
}

export default TwilioChatManager;

import * as types from '../actions/types';

const initialState = {
  isError: false,
  chatRoomData: {},
  roomData: {},
  isRefresh: false
};

const chatRoomReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.CHAT_ROOM_REQUESTED:
      return {
        ...state,
        error: null
      };
    case types.CHAT_ROOM_REQUEST_SUCCEEDED:
      return {
        ...state,
        roomData: action.data.data || {},
        error: null
      };
    case types.CHAT_ROOM_REQUEST_FAILED:
      return {
        ...state,
        isError: true,
        roomData: {},
        error: action.error
      };
    case types.GET_TWILIO_TOKEN_REQUESTED:
      return {
        ...state,
        error: null
      };
    case types.GET_TWILIO_TOKEN_SUCCEEDED:
      let { token, identity } = action.data.data;
      return {
        ...state,
        chatRoomData: {
          ...state.chatRoomData,
          token: token,
          user_id: identity
        },
        error: null
      };
    case types.GET_TWILIO_TOKEN_FAILED:
      return {
        ...state,
        isError: true,
        chatRoomData: { ...state.chatRoomData },
        error: action.error
      };
    case types.HIDE_ERROR:
      return {
        ...state,
        isError: false
      };
    case types.ACCESS_CHAT_ROOM_REQUESTED:
      return {
        ...state,
        error: null
      };
    case types.ACCESS_CHAT_ROOM_SUCCEEDED:
      return {
        ...state,
        error: null
      };
    case types.ACCESS_CHAT_ROOM_FAILED:
      return {
        ...state,
        error: null
      };
    default:
      return state;
  }
};

export default chatRoomReducer;

import React, { Component } from 'react';
import { compose } from 'recompose';
import { withNamespaces } from 'react-i18next';
import styles from './index.module.css';

import bachelorUser from '../../../assets/bachelorUser.png';

class CafeSuggestionMessage extends Component {
  render() {
    const { t, cafeSuggestion } = this.props;
    const { text1 = '', text2 = '', cafeList = [] } = cafeSuggestion;

    return (
      <span className={styles.partnerTextMessage}>
        <img alt="" src={bachelorUser} className={styles.chatUserIcon} />
        <div className={styles.chatPartnerTextBubble}>
          <div className={styles.text1}>{text1}</div>
          <div className={styles.text2}>{text2}</div>
          {cafeList.map(cafe => (
            <>
              <br />
              <div className={styles.cafeDetail}>
                <a href={cafe.cafe_link}>
                  <u>
                    {cafe.name} ({cafe.station_name})
                  </u>
                </a>
                <br />
                {t('cafe-suggestion-message-phone')} :{' '}
                <u>
                  <a href={'tel:' + cafe.display_phone}>{cafe.display_phone}</a>
                </u>
              </div>
            </>
          ))}

          <br />
          <div className={styles.messageNotVisible}>
            ({t('cafe-suggestion-message-not-visible')})
          </div>
        </div>
      </span>
    );
  }
}

export default compose(withNamespaces('chat', { wait: true }))(
  CafeSuggestionMessage
);

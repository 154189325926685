import * as types from '../actions/types';

const initialState = {
  isError: false,
  chatMessagesData: [],
  isRefresh: false
};

const chatMessagesReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.GET_CHAT_MESSAGES_REQUESTED:
      return {
        ...state,
        error: null
      };
    case types.GET_CHAT_MESSAGES_REQUEST_SUCCEEDED:
      return {
        ...state,
        chatMessagesData: action.data,
        error: null
      };
    case types.GET_CHAT_MESSAGES_REQUEST_FAILED:
      return {
        ...state,
        isError: true,
        chatMessagesData: [],
        error: action.error
      };
    case types.HIDE_ERROR:
      return {
        ...state,
        isError: false
      };
    default:
      return state;
  }
};

export default chatMessagesReducer;

import React from 'react';
import './index.css';

const WebTemplateMobileSimulator = props => (
  <div className="contenitore">
    <div id="phone-container" className="phone-container dark">
      <div className="phone-shape small">
        <span className="button-one buttons" />
        <span className="button-two buttons" />
        <span className="button-three buttons" />
        <span className="button-four button last" />
        <div className="top-details">
          <span className="camera" />
          <span className="circle" />
          <span className="speaker" />
        </div>
        <div className="phone-screen">{props.children}</div>
        <div className="circle-button" />
      </div>
    </div>
  </div>
);

export default WebTemplateMobileSimulator;

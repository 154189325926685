import createSagaMiddleware from 'redux-saga';
import { createStore, applyMiddleware } from 'redux';
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import { composeWithDevTools } from 'redux-devtools-extension';
import { logger } from 'redux-logger';
import { rootReducer } from './reducers';
import RootSaga from './sagas/rootSagas';

const persistConfig = {
  key: 'root',
  storage,
  blacklist: [
    'servermaintain',
    'app',
    'err',
    'cafe',
    'cancelDate',
    'cancelDateByPartner',
    'chatHistory',
    'chatMessages',
    'chatRoom',
    'participation',
    'popups',
    'useCoupon',
    'userProfile',
    'drawer',
    'homescreen',
    'feedback',
    'inviteFriends',
    'cancelProfile',
    'deactiveProfile',
    'changePlan',
    'coupons',
    'rematch',
    'tempCancelTrial'
  ]
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

const sagaMiddleware = createSagaMiddleware();

const middlewares = [sagaMiddleware];

if (window.__env__.ENVIRONMENT === 'development') {
  middlewares.push(logger);
}

const store = createStore(
  persistedReducer,
  composeWithDevTools(applyMiddleware(...middlewares))
);
const persistor = persistStore(store);
sagaMiddleware.run(RootSaga);

export { store, persistor };

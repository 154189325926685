import * as types from '../actions/types';

const initialState = {
  isError: false,
  isRefresh: false,
  data: null,
  changeSuccess: false,
  scheduledPlan: null
};

const userPlanReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.GET_PLAN_REQUESTED:
      return {
        ...state,
        error: null
      };
    case types.GET_PLAN_SUCCEEDED:
      return {
        ...state,
        data: action.data.data,
        error: null
      };
    case types.GET_PLAN_FAILED:
      return {
        ...state,
        data: null,
        isError: true,
        error: action.error
      };
    case types.UPDATE_PLAN_REQUESTED:
      return {
        ...state,
        changeSuccess: false,
        error: null
      };
    case types.UPDATE_PLAN_SUCCEEDED:
      return {
        ...state,
        changeSuccess: true,
        error: null
      };
    case types.UPDATE_PLAN_FAILED:
      return {
        ...state,
        changeSuccess: false,
        isError: true,
        error: action.error
      };
    case types.VALIDATE_BEFORE_UPDATE_PLAN_REQUESTED:
      return {
        ...state,
        isLoading: true,
        error: null
      };
    case types.VALIDATE_BEFORE_UPDATE_PLAN_SUCCEEDED:
      return {
        ...state,
        isLoading: false,
        error: null
      };
    case types.VALIDATE_BEFORE_UPDATE_PLAN_FAILED:
      return {
        ...state,
        isLoading: false,
        isError: true,
        error: action.error
      };
    case types.GET_SCHEDULED_PLAN_REQUESTED:
      return {
        ...state,
        error: null
      };
    case types.GET_SCHEDULED_PLAN_SUCCEEDED:
      return {
        ...state,
        scheduledPlan: action.data.data,
        error: null
      };
    case types.GET_SCHEDULED_PLAN_FAILED:
      return {
        ...state,
        scheduledPlan: null,
        isError: true,
        error: action.error
      };

    default:
      return state;
  }
};

export default userPlanReducer;

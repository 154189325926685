import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter, Route } from 'react-router-dom';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import 'bootstrap/dist/css/bootstrap.css';
import './index.css';
import './i18n';
import App from './App';
import * as serviceWorker from './serviceWorker';
// import Counters from "./components/counters";
import { store, persistor } from './redux/store';
import withTracker from './hoc/withTracker';
import { isIOSSafari } from './utils';
import ErrorBoundary from './containers/ErrorBoundary';

ReactDOM.render(
  <Provider store={store}>
    <PersistGate loading={null} persistor={persistor}>
      <BrowserRouter>
        <ErrorBoundary>
          <Route component={withTracker(App, {})} />
        </ErrorBoundary>
      </BrowserRouter>
    </PersistGate>
  </Provider>,
  document.getElementById('root'),
  () => {
    // add class for root element if user is using safari on iphone/ipad
    // there is some UI bugs in safari related to fullscreen mode
    const root = document.getElementById('root');
    if (isIOSSafari()) {
      document.body.classList.add('isIOSSafari');
      root.classList.add('isIOSSafari');
    }
  }
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();

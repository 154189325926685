import * as types from '../actions/types';

const initialState = {
  data: null,
  planData: null,
  isError: false,
  isRefresh: false
};

const userProfileReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.USER_PROFILE_REQUESTED:
      return {
        ...state,
        error: null
      };
    case types.USER_PROFILE_SUCCEEDED:
      return {
        ...state,
        data: action.data.data,
        error: null
      };
    case types.USER_PROFILE_FAILED:
      return {
        ...state,
        data: null,
        isError: true,
        error: action.error
      };
    case types.UPDATE_PROFILE_REQUESTED:
      return {
        ...state,
        error: null
      };
    case types.UPDATE_PROFILE_SUCCEEDED:
      return {
        ...state,
        error: null
      };
    case types.UPDATE_PROFILE_FAILED:
      return {
        ...state,
        data: null,
        isError: true,
        error: action.error
      };
    case types.UPDATE_PREFERENCES_REQUESTED:
      return {
        ...state,
        error: null
      };
    case types.UPDATE_PREFERENCES_SUCCEEDED:
      return {
        ...state,
        error: null
      };
    case types.UPDATE_PREFERENCES_FAILED:
      return {
        ...state,
        data: null,
        isError: true,
        error: action.error
      };
    case types.GET_PREFERENCES_INFO_REQUESTED:
      return {
        ...state,
        error: null
      };
    case types.GET_PREFERENCES_INFO_SUCCEEDED:
      return {
        ...state,
        error: null
      };
    case types.GET_PREFERENCES_INFO_FAILED:
      return {
        ...state,
        data: null,
        isError: true,
        error: action.error
      };
    case types.GET_PLAN_SUBSCRIPTION_REQUESTED:
      return {
        ...state,
        error: null
      };
    case types.GET_PLAN_SUBSCRIPTION_SUCCEEDED:
      return {
        ...state,
        planData: action.data.data,
        error: null
      };
    case types.GET_PLAN_SUBSCRIPTION_FAILED:
      return {
        ...state,
        data: null,
        isError: true,
        error: action.error
      };
    case types.HIDE_ERROR:
      return {
        ...state,
        isError: false
      };
    default:
      return state;
  }
};

export default userProfileReducer;

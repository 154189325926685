import React from 'react';
import styles from './index.module.css';

const MessagesComponent = ({ message1, message2 }) => {
  return (
    <div className={styles.messageContainer}>
      <div className={styles.message1}>{message1}</div>
      <div className={styles.message2}>{message2}</div>
    </div>
  );
};

export default MessagesComponent;

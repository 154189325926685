import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
  DefaultPopup,
  PartnerNotFound,
  DateDecided,
  ChangePlanSucess
} from '@components/ServerPopup';
import {
  deletePopup,
  getChangeCostPlan,
  getCancelChangeCostPlan
} from '@redux/actions';
import {
  MatchProfile as MatchProfileConstants,
  GlobalPopup as GlobalPopupConstants
} from '@constants/constant';
import { compose } from 'recompose';
import { withRouter } from 'react-router-dom';

const { POPUP_PARTNER_NOT_FOUND, POPUP_DATE_DECIDED } = MatchProfileConstants;
const {
  CHANGE_COST_PLAN,
  CHANGE_COST_PLAN_DOWNGRADE,
  CHANGE_COST_PLAN_UPGRADE,
  COMPLETE_UPDATE_PROFILE
} = GlobalPopupConstants;

class ServerPopupContainer extends Component {
  onClosePopup = () => {
    const params = {
      popup_id: this.props.popup.id
    };
    this.props.deletePopup(params);
  };

  onCloseChangePlanSuccess = () => {
    this.onClosePopup();
    this.props.history.push('/profile');
  };

  onConfirmChangeCostPlan = async () => {
    this.props.getChangeCostPlan({ body: {} }, response => {
      if (response.status === 1) {
        this.onClosePopup();
        this.props.onClick(response.data);
      } else {
        this.onClosePopup();
      }
    });
  };

  onCancelChangeCostPlan = async () => {
    this.onClosePopup();
    this.props.getCancelChangeCostPlan({}, res => {});
  };

  render() {
    const { popup, type } = this.props;
    switch (type) {
      case POPUP_PARTNER_NOT_FOUND:
        return (
          <PartnerNotFound
            key={popup.id}
            popup={popup}
            onCloseClick={this.onClosePopup}
          />
        );
      case POPUP_DATE_DECIDED:
        return (
          <DateDecided
            key={popup.id}
            popup={popup}
            onCloseClick={this.onClosePopup}
          />
        );
      case CHANGE_COST_PLAN:
        return (
          <ChangePlanSucess
            key={popup.id}
            popup={popup}
            onCloseClick={this.onClosePopup}
            onButtonClick={this.onClosePopup}
          />
        );
      case CHANGE_COST_PLAN_DOWNGRADE:
        return (
          <ChangePlanSucess
            key={popup.id}
            popup={popup}
            onButtonClick={this.onConfirmChangeCostPlan}
            onCloseClick={this.onCancelChangeCostPlan}
          />
        );
      case CHANGE_COST_PLAN_UPGRADE:
        return (
          <ChangePlanSucess
            key={popup.id}
            popup={popup}
            onButtonClick={this.onConfirmChangeCostPlan}
            onCloseClick={this.onCancelChangeCostPlan}
          />
        );
      default:
        return (
          <DefaultPopup
            key={popup.id}
            popup={popup}
            onCloseClick={this.onClosePopup}
            onButtonClick={this.onClosePopup}
          />
        );
    }
  }
}

export default compose(
  withRouter,
  connect(null, {
    deletePopup,
    getChangeCostPlan,
    getCancelChangeCostPlan
  })
)(ServerPopupContainer);

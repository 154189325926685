export const CURRENT_USER_LOGIN_FACEBOOK_REQUESTED =
  'CURRENT_USER_LOGIN_FACEBOOK_REQUESTED';
export const CURRENT_USER_LOGIN_FACEBOOK_INFO_REQUESTED =
  'CURRENT_USER_LOGIN_FACEBOOK_INFO_REQUESTED';
export const CURRENT_USER_LOGIN_FACEBOOK_SUCCEEDED =
  'CURRENT_USER_LOGIN_FACEBOOK_SUCCEEDED';
export const CURRENT_USER_LOGIN_FACEBOOK_INFO_SUCCEEDED =
  'CURRENT_USER_LOGIN_FACEBOOK_INFO_SUCCEEDED';
export const CURRENT_USER_LOGIN_FACEBOOK_FAILED =
  'CURRENT_USER_LOGIN_FACEBOOK_FAILED';
export const CURRENT_USER_LOGOUT_FACEBOOK_REQUESTED =
  'CURRENT_USER_LOGOUT_FACEBOOK';
export const CURRENT_USER_LOGOUT_FACEBOOK_SUCCEEDED =
  'CURRENT_USER_LOGOUT_FACEBOOK_SUCCEEDED';
export const CURRENT_USER_LOGOUT_FACEBOOK_FAILED =
  'CURRENT_USER_LOGOUT_FACEBOOK_FAILED';
export const CURRENT_USER_SUCCESS_NOT_401 = 'CURRENT_USER_SUCCESS_NOT_401';

//SMS login
export const SMS_LOGIN_SEND_CODE_REQUESTED = 'SMS_LOGIN_SEND_CODE_REQUESTED';
export const SMS_LOGIN_SEND_CODE_SUCCEEDED = 'SMS_LOGIN_SEND_CODE_SUCCEEDED';
export const SMS_LOGIN_SEND_CODE_FAILED = 'SMS_LOGIN_SEND_CODE_FAILED';
export const SMS_LOGIN_VERIFICATION_CODE_REQUESTED =
  'SMS_LOGIN_VERIFICATION_CODE_REQUESTED';
export const SMS_LOGIN_VERIFICATION_CODE_SUCCEEDED =
  'SMS_LOGIN_VERIFICATION_CODE_SUCCEEDED';
export const SMS_LOGIN_VERIFICATION_CODE_FAILED =
  'SMS_LOGIN_VERIFICATION_CODE_FAILED';

export const HOME_SCREEN_REQUESTED = 'HOME_SCREEN_REQUESTED';
export const HOME_SCREEN_SUCCEEDED = 'HOME_SCREEN_SUCCEEDED';
export const HOME_SCREEN_FAILED = 'HOME_SCREEN_FAILED';
export const CLEAR_HOMESCREEN = 'CLEAR_HOMESCREEN';

export const CURRENT_USER_RESET_MATCH_DATA = 'CURRENT_USER_RESET_MATCH_DATA';
export const CURRENT_USER_GET_MATCH_DATA_REQUESTED =
  'CURRENT_USER_GET_MATCH_DATA_REQUESTED';
export const CURRENT_USER_GET_MATCH_DATA_SUCCEEDED =
  'CURRENT_USER_GET_MATCH_DATA_SUCCEEDED';
export const CURRENT_USER_GET_MATCH_DATA_FAILED =
  'CURRENT_USER_GET_MATCH_DATA_FAILED';
export const PARTNER_REFLECTED_COUPON_POPUP_SHOWN =
  'PARTNER_REFLECTED_COUPON_POPUP_SHOWN';
export const UPDATE_USER_PARTICIPATION_DATES =
  'UPDATE_USER_PARTICIPATION_DATES';

export const USER_PROFILE_REQUESTED = 'USER_PROFILE_REQUESTED';
export const USER_PROFILE_SUCCEEDED = 'USER_PROFILE_SUCCEEDED';
export const USER_PROFILE_FAILED = 'USER_PROFILE_FAILED';

export const UPDATE_PROFILE_REQUESTED = 'UPDATE_PROFILE_REQUESTED';
export const UPDATE_PROFILE_SUCCEEDED = 'UPDATE_PROFILE_SUCCEEDED';
export const UPDATE_PROFILE_FAILED = 'UPDATE_PROFILE_FAILED';

export const GET_PREFERENCES_INFO_REQUESTED = 'GET_PREFERENCES_INFO_REQUESTED';
export const GET_PREFERENCES_INFO_SUCCEEDED = 'GET_PREFERENCES_INFO_SUCCEEDED';
export const GET_PREFERENCES_INFO_FAILED = 'GET_PREFERENCES_INFO_FAILED';

export const UPDATE_PREFERENCES_REQUESTED = 'UPDATE_PREFERENCES_REQUESTED';
export const UPDATE_PREFERENCES_SUCCEEDED = 'UPDATE_PREFERENCES_SUCCEEDED';
export const UPDATE_PREFERENCES_FAILED = 'UPDATE_PREFERENCES_FAILED';

export const USE_COUPON_REQUESTED = 'USE_COUPON_REQUESTED';
export const USE_COUPON_SUCCEEDED = 'USE_COUPON_SUCCEEDED';
export const USE_COUPON_FAILED = 'USE_COUPON_FAILED';

export const RETRIEVE_COUPON_REQUESTED = 'RETRIEVE_COUPON_REQUESTED';
export const RETRIEVE_COUPON_SUCCEEDED = 'RETRIEVE_COUPON_SUCCEEDED';
export const RETRIEVE_COUPON_FAILED = 'RETRIEVE_COUPON_FAILED';

export const PURCHASE_COUPON_REQUESTED = 'PURCHASE_COUPON_REQUESTED';
export const PURCHASE_COUPON_SUCCEEDED = 'PURCHASE_COUPON_SUCCEEDED';
export const PURCHASE_COUPON_FAILED = 'PURCHASE_COUPON_FAILED';

export const CANCEL_DATE_REQUESTED = 'CANCEL_DATE_REQUESTED';
export const CANCEL_DATE_SUCCEEDED = 'CANCEL_DATE_SUCCEEDED';
export const CANCEL_DATE_FAILED = 'CANCEL_DATE_FAILED';
export const CANCEL_FOR_REMATCH_REQUESTED = 'CANCEL_FOR_REMATCH_REQUESTED';
export const CANCEL_FOR_REMATCH_SUCCEEDED = 'CANCEL_FOR_REMATCH_SUCCEEDED';
export const CANCEL_FOR_REMATCH_FAILED = 'CANCEL_FOR_REMATCH_FAILED';

export const CANCEL_DATE_BY_PARTNER_REQUESTED =
  'CANCEL_DATE_BY_PARTNER_REQUESTED';
export const CANCEL_DATE_BY_PARTNER_SUCCEEDED =
  'CANCEL_DATE_BY_PARTNER_SUCCEEDED';
export const CANCEL_DATE_BY_PARTNER_FAILED = 'CANCEL_DATE_BY_PARTNER_FAILED';

export const PARTICIPATE_REMATCH_REQUESTED = 'PARTICIPATE_REMATCH_REQUESTED';
export const PARTICIPATE_REMATCH_SUCCEEDED = 'PARTICIPATE_REMATCH_SUCCEEDED';
export const PARTICIPATE_REMATCH_FAILED = 'PARTICIPATE_REMATCH_FAILED';

export const POPUPS_REQUESTED = 'POPUPS_REQUESTED';
export const POPUPS_REQUEST_SUCCEEDED = 'POPUPS_REQUEST_SUCCEEDED';
export const POPUPS_REQUEST_FAILED = 'POPUPS_REQUEST_FAILED';

export const DELETE_POPUP_REQUESTED = 'DELETE_POPUP_REQUESTED';
export const DELETE_POPUP_REQUEST_SUCCEEDED = 'DELETE_POPUP_REQUEST_SUCCEEDED';
export const DELETE_POPUP_REQUEST_FAILED = 'DELETE_POPUP_REQUEST_FAILED';

export const SHOW_LOADING = 'SHOW_LOADING';
export const HIDE_LOADING = 'HIDE_LOADING';

export const SHOW_ERROR = 'SHOW_ERROR';
export const HIDE_ERROR = 'HIDE_ERROR';

export const CHAT_ROOM_REQUESTED = 'CHAT_ROOM_REQUESTED';
export const CHAT_ROOM_REQUEST_SUCCEEDED = 'CHAT_ROOM_REQUEST_SUCCEEDED';
export const CHAT_ROOM_REQUEST_FAILED = 'CHAT_ROOM_REQUEST_FAILED';

export const CHAT_HISTORY_REQUESTED = 'CHAT_HISTORY_REQUESTED';
export const CHAT_HISTORY_REQUEST_SUCCEEDED = 'CHAT_HISTORY_REQUEST_SUCCEEDED';
export const CHAT_HISTORY_REQUEST_FAILED = 'CHAT_HISTORY_REQUEST_FAILED';

export const GET_CAFE_REQUESTED = 'GET_CAFE_REQUESTED';
export const GET_CAFE_REQUEST_SUCCEEDED = 'GET_CAFE_REQUEST_SUCCEEDED';
export const GET_CAFE_REQUEST_FAILED = 'GET_CAFE_REQUEST_FAILED';

export const GET_CHAT_MESSAGES_REQUESTED = 'GET_CHAT_MESSAGES_REQUESTED';
export const GET_CHAT_MESSAGES_REQUEST_SUCCEEDED =
  'GET_CHAT_MESSAGES_REQUEST_SUCCEEDED';
export const GET_CHAT_MESSAGES_REQUEST_FAILED =
  'GET_CHAT_MESSAGES_REQUEST_FAILED';

export const GET_TWILIO_TOKEN_REQUESTED = 'GET_TWILIO_TOKEN_REQUESTED';
export const GET_TWILIO_TOKEN_SUCCEEDED = 'GET_TWILIO_TOKEN_SUCCEEDED';
export const GET_TWILIO_TOKEN_FAILED = 'GET_TWILIO_TOKEN_FAILED';

export const SET_PARTICIPATION_REQUESTED = 'SET_PARTICIPATION_REQUESTED';
export const SET_PARTICIPATION_SUCCEEDED = 'SET_PARTICIPATION_SUCCEEDED';
export const SET_PARTICIPATION_FAILED = 'SET_PARTICIPATION_FAILED';

export const CANCEL_PARTICIPATE_REQUESTED = 'CANCEL_PARTICIPATE_REQUESTED';
export const CANCEL_PARTICIPATE_SUCCEEDED = 'CANCEL_PARTICIPATE_SUCCEEDED';
export const CANCEL_PARTICIPATE_FAILED = 'CANCEL_PARTICIPATE_FAILED';

export const CANCEL_SAMPLE_PARTICIPANT_REQUESTED =
  'CANCEL_SAMPLE_PARTICIPANT_REQUESTED';
export const CANCEL_SAMPLE_PARTICIPANT_SUCCEEDED =
  'CANCEL_SAMPLE_PARTICIPANT_SUCCEEDED';
export const CANCEL_SAMPLE_PARTICIPANT_FAILED =
  'CANCEL_SAMPLE_PARTICIPANT_FAILED';

export const GET_RECOMMENDATIONS_REQUESTED = 'GET_RECOMMENDATIONS_REQUESTED';
export const GET_RECOMMENDATIONS_SUCCEEDED = 'GET_RECOMMENDATIONS_SUCCEEDED';
export const GET_RECOMMENDATIONS_FAILED = 'GET_RECOMMENDATIONS_FAILED';

export const GET_AWAITING_PARTICIPANT_DATING_DAYS_REQUESTED =
  'GET_AWAITING_PARTICIPANT_DATING_DAYS_REQUESTED';
export const GET_AWAITING_PARTICIPANT_DATING_DAYS_SUCCEEDED =
  'GET_AWAITING_PARTICIPANT_DATING_DAYS_SUCCEEDED';
export const GET_AWAITING_PARTICIPANT_DATING_DAYS_FAILED =
  'GET_AWAITING_PARTICIPANT_DATING_DAYS_FAILED';

export const GET_OPEN_DATES_REQUESTED = 'GET_OPEN_DATES_REQUESTED';
export const GET_OPEN_DATES_SUCCEEDED = 'GET_OPEN_DATES_SUCCEEDED';
export const GET_OPEN_DATES_FAILED = 'GET_OPEN_DATES_FAILED';

export const GET_OPEN_DATES_STATUS_REQUESTED =
  'GET_OPEN_DATES_STATUS_REQUESTED';
export const GET_OPEN_DATES_STATUS_SUCCEEDED =
  'GET_OPEN_DATES_STATUS_SUCCEEDED';
export const GET_OPEN_DATES_STATUS_FAILED = 'GET_OPEN_DATES_STATUS_FAILED';

export const REQUEST_PARTICIPATE_REQUESTED = 'REQUEST_PARTICIPATE_REQUESTED';
export const REQUEST_PARTICIPATE_SUCCEEDED = 'REQUEST_PARTICIPATE_SUCCEEDED';
export const REQUEST_PARTICIPATE_FAILED = 'REQUEST_PARTICIPATE_FAILED';

export const SET_PARTICIPATION_FAILED_NEED_ACTION =
  'SET_PARTICIPATION_FAILED_NEED_ACTION';

export const SHOW_MAINTAIN = 'SHOW_MAINTAIN';

export const CURRENT_USER_CHANGE_COST_PLAN_REQUESTED =
  'CURRENT_USER_CHANGE_COST_PLAN_REQUESTED';
export const CURRENT_USER_CHANGE_COST_PLAN_SUCCEEDED =
  'CURRENT_USER_CHANGE_COST_PLAN_SUCCEEDED';
export const CURRENT_USER_CHANGE_COST_PLAN_FAILED =
  'CURRENT_USER_CHANGE_COST_PLAN_FAILED';
export const CURRENT_USER_CANCEL_COST_PLAN_REQUESTED =
  'CURRENT_USER_CANCEL_COST_PLAN_REQUESTED';

export const SHOW_TOOLTIPS = 'SHOW_TOOLTIPS';
export const HIDE_TOOLTIPS = 'HIDE_TOOLTIPS';

export const VALIDATE_DEACTIVATE_REQUESTED = 'VALIDATE_DEACTIVATE_REQUESTED';
export const VALIDATE_DEACTIVATE_SUCCEEDED = 'VALIDATE_DEACTIVATE_SUCCEEDED';
export const VALIDATE_DEACTIVATE_FAILED = 'VALIDATE_DEACTIVATE_FAILED';

export const DEACTIVE_PROFILE_REQUESTED = 'DEACTIVE_PROFILE_REQUESTED';
export const DEACTIVE_PROFILE_SUCCEEDED = 'DEACTIVE_PROFILE_SUCCEEDED';
export const DEACTIVE_PROFILE_FAILED = 'DEACTIVE_PROFILE_FAILED';

export const OPEN_DRAWER = 'OPEN_DRAWER';
export const CLOSE_DRAWER = 'CLOSE_DRAWER';

export const CANCEL_PROFILE_REQUESTED = 'CANCEL_PROFILE_REQUESTED';
export const CANCEL_PROFILE_SUCCEEDED = 'CANCEL_PROFILE_SUCCEEDED';
export const CANCEL_PROFILE_FAILED = 'CANCEL_PROFILE_FAILED';

export const GET_PLAN_SUBSCRIPTION_REQUESTED =
  'GET_PLAN_SUBSCRIPTION_REQUESTED';
export const GET_PLAN_SUBSCRIPTION_SUCCEEDED =
  'GET_PLAN_SUBSCRIPTION_SUCCEEDED';
export const GET_PLAN_SUBSCRIPTION_FAILED = 'GET_PLAN_SUBSCRIPTION_FAILED';

export const GET_PLAN_REQUESTED = 'GET_PLAN_REQUESTED';
export const GET_PLAN_SUCCEEDED = 'GET_PLAN_REQUESTED_SUCCEEDED';
export const GET_PLAN_FAILED = 'GET_PLAN_REQUESTED_FAILED';

export const UPDATE_PLAN_REQUESTED = 'UPDATE_PLAN_REQUESTED';
export const UPDATE_PLAN_SUCCEEDED = 'UPDATE_PLAN_SUCCEEDED';
export const UPDATE_PLAN_FAILED = 'UPDATE_PLAN_FAILED';

export const ADD_REQUEST_TIME_CHANGE_REQUESTED =
  'ADD_REQUEST_TIME_CHANGE_REQUESTED';
export const ADD_REQUEST_TIME_CHANGE_SUCCEEDED =
  'ADD_REQUEST_TIME_CHANGE_SUCCEEDED';
export const ADD_REQUEST_TIME_CHANGE_FAILED = 'ADD_REQUEST_TIME_CHANGE_FAILED';

export const UPDATE_REQUEST_TIME_CHANGE_REQUESTED =
  'UPDATE_REQUEST_TIME_CHANGE_REQUESTED';
export const UPDATE_REQUEST_TIME_CHANGE_SUCCEEDED =
  'UPDATE_REQUEST_TIME_CHANGE_SUCCEEDED';
export const UPDATE_REQUEST_TIME_CHANGE_FAILED =
  'UPDATE_REQUEST_TIME_CHANGE_FAILED';

export const SAVE_USER_FEEDBACK_REQUESTED = 'SAVE_USER_FEEDBACK_REQUESTED';
export const SAVE_USER_FEEDBACK_SUCCEEDED = 'SAVE_USER_FEEDBACK_SUCCEEDED';
export const SAVE_USER_FEEDBACK_FAILED = 'SAVE_USER_FEEDBACK_FAILED';

export const CHECK_SEND_FEEDBACK_REQUESTED = 'CHECK_SEND_FEEDBACK_REQUESTED';
export const CHECK_SEND_FEEDBACK_SUCCEEDED = 'CHECK_SEND_FEEDBACK_SUCCEEDED';
export const CHECK_SEND_FEEDBACK_FAILED = 'CHECK_SEND_FEEDBACK_FAILED';

export const USER_FEEDBACK_DETAILS_REQUESTED =
  'USER_FEEDBACK_DETAILS_REQUESTED';
export const USER_FEEDBACK_DETAILS_SUCCEEDED =
  'USER_FEEDBACK_DETAILS_SUCCEEDED';
export const USER_FEEDBACK_DETAILS_FAILED = 'USER_FEEDBACK_DETAILS_FAILED';

export const INVITE_FRIENDS_DATA_REQUESTED = 'INVITE_FRIENDS_DATA_REQUESTED';
export const INVITE_FRIENDS_DATA_SUCCEEDED = 'INVITE_FRIENDS_DATA_SUCCEEDED';
export const INVITE_FRIENDS_DATA_FAILED = 'INVITE_FRIENDS_DATA_FAILED';

//Match profile
export const GET_MATCH_PROFILE_DETAIL_REQUESTED =
  'GET_MATCH_PROFILE_DETAIL_REQUESTED';
export const GET_MATCH_PROFILE_DETAIL_SUCCEEDED =
  'GET_MATCH_PROFILE_DETAIL_SUCCEEDED';
export const GET_MATCH_PROFILE_DETAIL_FAILED =
  'GET_MATCH_PROFILE_DETAIL_FAILED';

export const MATCH_PROFILE_INFO_REQUEST = 'MATCH_PROFILE_INFO_REQUEST';
export const MATCH_PROFILE_INFO_SUCCEEDED = 'MATCH_PROFILE_INFO_SUCCEEDED';
export const MATCH_PROFILE_INFO_FAILED = 'MATCH_PROFILE_INFO_FAILED';

export const APPLY_COUPON_REQUESTED = 'APPLY_COUPON_REQUESTED';
export const APPLY_COUPON_SUCCEEDED = 'APPLY_COUPON_SUCCEEDED';
export const APPLY_COUPON_FAILED = 'APPLY_COUPON_FAILED';

export const EXCHANGE_COUPON_REQUESTED = 'EXCHANGE_COUPON_REQUESTED';
export const EXCHANGE_COUPON_SUCCEDDED = 'EXCHANGE_COUPON_SUCCEDDED';
export const EXCHANGE_COUPON_FAILED = 'EXCHANGE_COUPON_FAILED';

export const COUPON_LIST_REQUEST = 'COUPON_LIST_REQUEST';
export const COUPON_LIST_SUCCEEDED = 'COUPON_LIST_SUCCEEDED';
export const COUPON_LIST_FAILED = 'COUPON_LIST_FAILED';
export const COUPON_LIST_RESET = 'COUPON_LIST_RESET';
export const COUPON_LIST_DECREMENT = 'COUPON_LIST_DECREMENT';

export const GET_COUPON_PACKAGE_REQUESTED = 'GET_COUPON_PACKAGE_REQUESTED';
export const GET_COUPON_PACKAGE_SUCCEEDED = 'GET_COUPON_PACKAGE_SUCCEEDED';
export const GET_COUPON_PACKAGE_FAILED = 'GET_COUPON_PACKAGE_FAILED';
export const PURCHASE_COUPON_PACKAGE_REQUEST =
  'PURCHASE_COUPON_PACKAGE_REQUEST';
export const PURCHASE_COUPON_PACKAGE_SUCCEEDED =
  'PURCHASE_COUPON_PACKAGE_SUCCEEDED';
export const PURCHASE_COUPON_PACKAGE_FAILED = 'PURCHASE_COUPON_PACKAGE_FAILED';

export const REMATCH_REQUESTED = 'REMATCH_REQUESTED';
export const REMATCH_SUCCEEDED = 'REMATCH_SUCCEEDED';
export const REMATCH_FAILED = 'REMATCH_FAILED';

export const EVALUATION_INFO_REQUEST = 'EVALUATION_INFO_REQUEST';
export const EVALUATION_INFO_SUCCEEDED = 'EVALUATION_INFO_SUCCEEDED';
export const EVALUATION_INFO_FAILED = 'EVALUATION_INFO_FAILED';

export const EVALUATION_LIST_REQUEST = 'EVALUATION_LIST_REQUEST';
export const EVALUATION_LIST_SUCCEEDED = 'EVALUATION_LIST_SUCCEEDED';
export const EVALUATION_LIST_FAILED = 'EVALUATION_LIST_FAILED';

// registration form
//upload image
export const STORE_IMAGE_REQUESTED = 'STORE_IMAGE_REQUESTED';
export const STORE_IMAGE_SUCCEDDED = 'STORE_IMAGE_SUCCEDDED';
export const STORE_IMAGE_FAILED = 'STORE_IMAGE_FAILED';
//STEP1
export const REGISTRATION_FORM_GET_STEP_ONE_REQUESTED =
  'REGISTRATION_FORM_GET_STEP_ONE_REQUESTED';
export const REGISTRATION_FORM_GET_STEP_ONE_SUCCEDDED =
  'REGISTRATION_FORM_GET_STEP_ONE_SUCCEDDED';
export const REGISTRATION_FORM_GET_STEP_ONE_FAILED =
  'REGISTRATION_FORM_GET_STEP_ONE_FAILED';
export const REGISTRATION_FORM_POST_STEP_ONE_REQUESTED =
  'REGISTRATION_FORM_POST_STEP_ONE_REQUESTED';
export const REGISTRATION_FORM_POST_STEP_ONE_SUCCEDDED =
  'REGISTRATION_FORM_POST_STEP_ONE_SUCCEDDED';
export const REGISTRATION_FORM_POST_STEP_ONE_FAILED =
  'REGISTRATION_FORM_POST_STEP_ONE_FAILED';

export const SET_USER_INFO_WHEN_API_GET_NOT_CALL =
  'SET_USER_INFO_WHEN_API_GET_NOT_CALL';

//STEP 1 (2nd)
export const SECOND_REGISTRATION_FORM_GET_STEP_ONE_REQUESTED =
  'SECOND_REGISTRATION_FORM_GET_STEP_ONE_REQUESTED';
export const SECOND_REGISTRATION_FORM_GET_STEP_ONE_SUCCEDDED =
  'SECOND_REGISTRATION_FORM_GET_STEP_ONE_SUCCEDDED';
export const SECOND_REGISTRATION_FORM_GET_STEP_ONE_FAILED =
  'SECOND_REGISTRATION_FORM_GET_STEP_ONE_FAILED';
export const SECOND_REGISTRATION_FORM_POST_STEP_ONE_REQUESTED =
  'SECOND_REGISTRATION_FORM_POST_STEP_ONE_REQUESTED';
export const SECOND_REGISTRATION_FORM_POST_STEP_ONE_SUCCEDDED =
  'SECOND_REGISTRATION_FORM_POST_STEP_ONE_SUCCEDDED';
export const SECOND_REGISTRATION_FORM_POST_STEP_ONE_FAILED =
  'SECOND_REGISTRATION_FORM_POST_STEP_ONE_FAILED';

//STEP 2
export const REGISTRATION_FORM_GET_STEP_TWO_REQUESTED =
  'REGISTRATION_FORM_GET_STEP_TWO_REQUESTED';
export const REGISTRATION_FORM_GET_STEP_TWO_SUCCEDDED =
  'REGISTRATION_FORM_GET_STEP_TWO_SUCCEDDED';
export const REGISTRATION_FORM_GET_STEP_TWO_FAILED =
  'REGISTRATION_FORM_GET_STEP_TWO_FAILED';
export const REGISTRATION_FORM_POST_STEP_TWO_REQUESTED =
  'REGISTRATION_FORM_POST_STEP_TWO_REQUESTED';
export const REGISTRATION_FORM_POST_STEP_TWO_SUCCEDDED =
  'REGISTRATION_FORM_POST_STEP_TWO_SUCCEDDED';
export const REGISTRATION_FORM_POST_STEP_TWO_FAILED =
  'REGISTRATION_FORM_POST_STEP_TWO_FAILED';

//STEP 2 (2nd)
export const SECOND_REGISTRATION_FORM_GET_STEP_TWO_REQUESTED =
  'SECOND_REGISTRATION_FORM_GET_STEP_TWO_REQUESTED';
export const SECOND_REGISTRATION_FORM_GET_STEP_TWO_SUCCEDDED =
  'SECOND_REGISTRATION_FORM_GET_STEP_TWO_SUCCEDDED';
export const SECOND_REGISTRATION_FORM_GET_STEP_TWO_FAILED =
  'SECOND_REGISTRATION_FORM_GET_STEP_TWO_FAILED';
export const SECOND_REGISTRATION_FORM_POST_STEP_TWO_REQUESTED =
  'SECOND_REGISTRATION_FORM_POST_STEP_TWO_REQUESTED';
export const SECOND_REGISTRATION_FORM_POST_STEP_TWO_SUCCEDDED =
  'SECOND_REGISTRATION_FORM_POST_STEP_TWO_SUCCEDDED';
export const SECOND_REGISTRATION_FORM_POST_STEP_TWO_FAILED =
  'SECOND_REGISTRATION_FORM_POST_STEP_TWO_FAILED';

//STEP 3
export const REGISTRATION_FORM_GET_STEP_THREE_REQUESTED =
  'REGISTRATION_FORM_GET_STEP_THREE_REQUESTED';
export const REGISTRATION_FORM_GET_STEP_THREE_SUCCEDDED =
  'REGISTRATION_FORM_GET_STEP_THREE_SUCCEDDED';
export const REGISTRATION_FORM_GET_STEP_THREE_FAILED =
  'REGISTRATION_FORM_GET_STEP_THREE_FAILED';
export const REGISTRATION_FORM_POST_STEP_THREE_REQUESTED =
  'REGISTRATION_FORM_POST_STEP_THREE_REQUESTED';
export const REGISTRATION_FORM_POST_STEP_THREE_SUCCEDDED =
  'REGISTRATION_FORM_POST_STEP_THREE_SUCCEDDED';
export const REGISTRATION_FORM_POST_STEP_THREE_FAILED =
  'REGISTRATION_FORM_POST_STEP_THREE_FAILED';

//STEP 3 (2nd)
export const SECOND_REGISTRATION_FORM_GET_STEP_THREE_REQUESTED =
  'SECOND_REGISTRATION_FORM_GET_STEP_THREE_REQUESTED';
export const SECOND_REGISTRATION_FORM_GET_STEP_THREE_SUCCEDDED =
  'SECOND_REGISTRATION_FORM_GET_STEP_THREE_SUCCEDDED';
export const SECOND_REGISTRATION_FORM_GET_STEP_THREE_FAILED =
  'SECOND_REGISTRATION_FORM_GET_STEP_THREE_FAILED';
export const SECOND_REGISTRATION_FORM_POST_STEP_THREE_REQUESTED =
  'SECOND_REGISTRATION_FORM_POST_STEP_THREE_REQUESTED';
export const SECOND_REGISTRATION_FORM_POST_STEP_THREE_SUCCEDDED =
  'SECOND_REGISTRATION_FORM_POST_STEP_THREE_SUCCEDDED';
export const SECOND_REGISTRATION_FORM_POST_STEP_THREE_FAILED =
  'SECOND_REGISTRATION_FORM_POST_STEP_THREE_FAILED';

//STEP 4
export const REGISTRATION_FORM_GET_STEP_FOUR_REQUESTED =
  'REGISTRATION_FORM_GET_STEP_FOUR_REQUESTED';
export const REGISTRATION_FORM_GET_STEP_FOUR_SUCCEDDED =
  'REGISTRATION_FORM_GET_STEP_FOUR_SUCCEDDED';
export const REGISTRATION_FORM_GET_STEP_FOUR_FAILED =
  'REGISTRATION_FORM_GET_STEP_FOUR_FAILED';
export const REGISTRATION_FORM_POST_STEP_FOUR_REQUESTED =
  'REGISTRATION_FORM_POST_STEP_FOUR_REQUESTED';
export const REGISTRATION_FORM_POST_STEP_FOUR_SUCCEDDED =
  'REGISTRATION_FORM_POST_STEP_FOUR_SUCCEDDED';
export const REGISTRATION_FORM_POST_STEP_FOUR_FAILED =
  'REGISTRATION_FORM_POST_STEP_FOUR_FAILED';

//STEP 4 (2nd)
export const SECOND_REGISTRATION_FORM_GET_STEP_FOUR_REQUESTED =
  'SECOND_REGISTRATION_FORM_GET_STEP_FOUR_REQUESTED';
export const SECOND_REGISTRATION_FORM_GET_STEP_FOUR_SUCCEDDED =
  'SECOND_REGISTRATION_FORM_GET_STEP_FOUR_SUCCEDDED';
export const SECOND_REGISTRATION_FORM_GET_STEP_FOUR_FAILED =
  'SECOND_REGISTRATION_FORM_GET_STEP_FOUR_FAILED';
export const SECOND_REGISTRATION_FORM_POST_STEP_FOUR_REQUESTED =
  'SECOND_REGISTRATION_FORM_POST_STEP_FOUR_REQUESTED';
export const SECOND_REGISTRATION_FORM_POST_STEP_FOUR_SUCCEDDED =
  'SECOND_REGISTRATION_FORM_POST_STEP_FOUR_SUCCEDDED';
export const SECOND_REGISTRATION_FORM_POST_STEP_FOUR_FAILED =
  'SECOND_REGISTRATION_FORM_POST_STEP_FOUR_FAILED';

//STEP5
export const REGISTRATION_FORM_GET_STEP_FIVE_REQUESTED =
  'REGISTRATION_FORM_GET_STEP_FIVE_REQUESTED';
export const REGISTRATION_FORM_GET_STEP_FIVE_SUCCEDDED =
  'REGISTRATION_FORM_GET_STEP_FIVE_SUCCEDDED';
export const REGISTRATION_FORM_GET_STEP_FIVE_FAILED =
  'REGISTRATION_FORM_GET_STEP_FIVE_FAILED';
export const REGISTRATION_FORM_POST_STEP_FIVE_REQUESTED =
  'REGISTRATION_FORM_POST_STEP_FIVE_REQUESTED';
export const REGISTRATION_FORM_POST_STEP_FIVE_SUCCEDDED =
  'REGISTRATION_FORM_POST_STEP_FIVE_SUCCEDDED';
export const REGISTRATION_FORM_POST_STEP_FIVE_FAILED =
  'REGISTRATION_FORM_POST_STEP_FIVE_FAILED';

//STEP 5 (2nd)
export const SECOND_REGISTRATION_FORM_GET_STEP_FIVE_REQUESTED =
  'SECOND_REGISTRATION_FORM_GET_STEP_FIVE_REQUESTED';
export const SECOND_REGISTRATION_FORM_GET_STEP_FIVE_SUCCEDDED =
  'SECOND_REGISTRATION_FORM_GET_STEP_FIVE_SUCCEDDED';
export const SECOND_REGISTRATION_FORM_GET_STEP_FIVE_FAILED =
  'SECOND_REGISTRATION_FORM_GET_STEP_FIVE_FAILED';
export const SECOND_REGISTRATION_FORM_POST_STEP_FIVE_REQUESTED =
  'SECOND_REGISTRATION_FORM_POST_STEP_FIVE_REQUESTED';
export const SECOND_REGISTRATION_FORM_POST_STEP_FIVE_SUCCEDDED =
  'SECOND_REGISTRATION_FORM_POST_STEP_FIVE_SUCCEDDED';
export const SECOND_REGISTRATION_FORM_POST_STEP_FIVE_FAILED =
  'SECOND_REGISTRATION_FORM_POST_STEP_FIVE_FAILED';

//STEP6
export const REGISTRATION_FORM_GET_STEP_SIX_REQUESTED =
  'REGISTRATION_FORM_GET_STEP_SIX_REQUESTED';
export const REGISTRATION_FORM_GET_STEP_SIX_SUCCEDDED =
  'REGISTRATION_FORM_GET_STEP_SIX_SUCCEDDED';
export const REGISTRATION_FORM_GET_STEP_SIX_FAILED =
  'REGISTRATION_FORM_GET_STEP_SIX_FAILED';
export const REGISTRATION_FORM_POST_STEP_SIX_REQUESTED =
  'REGISTRATION_FORM_POST_STEP_SIX_REQUESTED';
export const REGISTRATION_FORM_POST_STEP_SIX_SUCCEDDED =
  'REGISTRATION_FORM_POST_STEP_SIX_SUCCEDDED';
export const REGISTRATION_FORM_POST_STEP_SIX_FAILED =
  'REGISTRATION_FORM_POST_STEP_SIX_FAILED';

//Step 6 (2nd)
export const SECOND_REGISTRATION_FORM_GET_STEP_SIX_REQUESTED =
  'SECOND_REGISTRATION_FORM_GET_STEP_SIX_REQUESTED';
export const SECOND_REGISTRATION_FORM_GET_STEP_SIX_SUCCEDDED =
  'SECOND_REGISTRATION_FORM_GET_STEP_SIX_SUCCEDDED';
export const SECOND_REGISTRATION_FORM_GET_STEP_SIX_FAILED =
  'SECOND_REGISTRATION_FORM_GET_STEP_SIX_FAILED';
export const SECOND_REGISTRATION_FORM_POST_STEP_SIX_REQUESTED =
  'SECOND_REGISTRATION_FORM_POST_STEP_SIX_REQUESTED';
export const SECOND_REGISTRATION_FORM_POST_STEP_SIX_SUCCEDDED =
  'SECOND_REGISTRATION_FORM_POST_STEP_SIX_SUCCEDDED';
export const SECOND_REGISTRATION_FORM_POST_STEP_SIX_FAILED =
  'SECOND_REGISTRATION_FORM_POST_STEP_SIX_FAILED';

//step7
export const REGISTRATION_FORM_GET_STEP_SEVEN_REQUESTED =
  'REGISTRATION_FORM_GET_STEP_SEVEN_REQUESTED';
export const REGISTRATION_FORM_GET_STEP_SEVEN_SUCCEDDED =
  'REGISTRATION_FORM_GET_STEP_SEVEN_SUCCEDDED';
export const REGISTRATION_FORM_GET_STEP_SEVEN_FAILED =
  'REGISTRATION_FORM_GET_STEP_SEVEN_FAILED';
export const REGISTRATION_FORM_POST_STEP_SEVEN_REQUESTED =
  'REGISTRATION_FORM_POST_STEP_SEVEN_REQUESTED';
export const REGISTRATION_FORM_POST_STEP_SEVEN_SUCCEDDED =
  'REGISTRATION_FORM_POST_STEP_SEVEN_SUCCEDDED';
export const REGISTRATION_FORM_POST_STEP_SEVEN_FAILED =
  'REGISTRATION_FORM_POST_STEP_SEVEN_FAILED';

//Step7 (2nd)
export const SECOND_REGISTRATION_FORM_GET_STEP_SEVEN_REQUESTED =
  'SECOND_REGISTRATION_FORM_GET_STEP_SEVEN_REQUESTED';
export const SECOND_REGISTRATION_FORM_GET_STEP_SEVEN_SUCCEDDED =
  'SECOND_REGISTRATION_FORM_GET_STEP_SEVEN_SUCCEDDED';
export const SECOND_REGISTRATION_FORM_GET_STEP_SEVEN_FAILED =
  'SECOND_REGISTRATION_FORM_GET_STEP_SEVEN_FAILED';
export const SECOND_REGISTRATION_FORM_POST_STEP_SEVEN_REQUESTED =
  'SECOND_REGISTRATION_FORM_POST_STEP_SEVEN_REQUESTED';
export const SECOND_REGISTRATION_FORM_POST_STEP_SEVEN_SUCCEDDED =
  'SECOND_REGISTRATION_FORM_POST_STEP_SEVEN_SUCCEDDED';
export const SECOND_REGISTRATION_FORM_POST_STEP_SEVEN_FAILED =
  'SECOND_REGISTRATION_FORM_POST_STEP_SEVEN_FAILED';

//Step 8
export const REGISTRATION_FORM_GET_STEP_EIGHT_REQUESTED =
  'REGISTRATION_FORM_GET_STEP_EIGHT_REQUESTED';
export const REGISTRATION_FORM_GET_STEP_EIGHT_SUCCEDDED =
  'REGISTRATION_FORM_GET_STEP_EIGHT_SUCCEDDED';
export const REGISTRATION_FORM_GET_STEP_EIGHT_FAILED =
  'REGISTRATION_FORM_GET_STEP_EIGHT_FAILED';
export const REGISTRATION_FORM_POST_STEP_EIGHT_REQUESTED =
  'REGISTRATION_FORM_POST_STEP_EIGHT_REQUESTED';
export const REGISTRATION_FORM_POST_STEP_EIGHT_SUCCEDDED =
  'REGISTRATION_FORM_POST_STEP_EIGHT_SUCCEDDED';
export const REGISTRATION_FORM_POST_STEP_EIGHT_FAILED =
  'REGISTRATION_FORM_POST_STEP_EIGHT_FAILED';

//Step 8 (2nd)

export const UPDATE_PREFECTURE_AND_AREA_REQUESTED =
  'UPDATE_PREFECTURE_AND_AREA_REQUESTED';
export const UPDATE_PREFECTURE_AND_AREA_SUCCEDDED =
  'UPDATE_PREFECTURE_AND_AREA_SUCCEDDED';
export const UPDATE_PREFECTURE_AND_AREA_FAILED =
  'UPDATE_PREFECTURE_AND_AREA_FAILED';

export const CONDITION_CHANGE_PREFECTURE_AND_AREA_REQUESTED =
  'CONDITION_CHANGE_PREFECTURE_AND_AREA_REQUESTED';
export const CONDITION_CHANGE_PREFECTURE_AND_AREA_SUCCEDDED =
  'CONDITION_CHANGE_PREFECTURE_AND_AREA_SUCCEDDED';
export const CONDITION_CHANGE_PREFECTURE_AND_AREA_FAILED =
  'CONDITION_CHANGE_PREFECTURE_AND_AREA_FAILED';

export const SECOND_REGISTRATION_FORM_GET_STEP_EIGHT_REQUESTED =
  'SECOND_REGISTRATION_FORM_GET_STEP_EIGHT_REQUESTED';
export const SECOND_REGISTRATION_FORM_GET_STEP_EIGHT_SUCCEDDED =
  'SECOND_REGISTRATION_FORM_GET_STEP_EIGHT_SUCCEDDED';
export const SECOND_REGISTRATION_FORM_GET_STEP_EIGHT_FAILED =
  'SECOND_REGISTRATION_FORM_GET_STEP_EIGHT_FAILED';
export const SECOND_REGISTRATION_FORM_POST_STEP_EIGHT_REQUESTED =
  'SECOND_REGISTRATION_FORM_POST_STEP_EIGHT_REQUESTED';
export const SECOND_REGISTRATION_FORM_POST_STEP_EIGHT_SUCCEDDED =
  'SECOND_REGISTRATION_FORM_POST_STEP_EIGHT_SUCCEDDED';
export const SECOND_REGISTRATION_FORM_POST_STEP_EIGHT_FAILED =
  'SECOND_REGISTRATION_FORM_POST_STEP_EIGHT_FAILED';

export const REACTIVATION_RE_APPROVE_REQUEST =
  'REACTIVATION_RE_APPROVE_REQUEST';
export const REACTIVATION_RE_APPROVE_SUCCEEDED =
  'REACTIVATION_RE_APPROVE_SUCCEEDED';
export const REACTIVATION_RE_APPROVE_FAILED = 'REACTIVATION_RE_APPROVE_FAILED';

export const GET_CARDS_REQUESTED = 'GET_CARDS_REQUESTED';
export const GET_CARDS_SUCCEEDED = 'GET_CARDS_SUCCEEDED';
export const GET_CARDS_FAILED = 'GET_CARDS_FAILED';
export const CREATE_CARD_REQUESTED = 'CREATE_CARD_REQUESTED';
export const CREATE_CARD_SUCCEEDED = 'CREATE_CARD_SUCCEEDED';
export const CREATE_CARD_FAILED = 'CREATE_CARD_FAILED';
export const SET_DEFAULT_CARD_REQUESTED = 'SET_DEFAULT_CARD_REQUESTED';
export const SET_DEFAULT_CARD_SUCCEEDED = 'SET_DEFAULT_CARD_SUCCEEDED';
export const SET_DEFAULT_CARD_FAIL = 'SET_DEFAULT_CARD_FAIL';

export const UPDATE_GLOBAL_ERROR_POPUP = 'UPDATE_GLOBAL_ERROR_POPUP';
export const RESET_GLOBAL_ERROR_POPUP = 'RESET_GLOBAL_ERROR_POPUP';

export const UPDATE_EMAIL_REQUESTED = 'UPDATE_EMAIL_REQUESTED';
export const UPDATE_EMAIL_SUCCEEDED = 'UPDATE_EMAIL_SUCCEEDED';
export const UPDATE_EMAIL_FAILED = 'UPDATE_EMAIL_FAILED';

export const GET_MY_PAGE_INFO_REQUESTED = 'GET_MY_PAGE_INFO_REQUESTED';
export const GET_MY_PAGE_INFO_SUCCEEDED = 'GET_MY_PAGE_INFO_SUCCEEDED';
export const GET_MY_PAGE_INFO_FAILED = 'GET_MY_PAGE_INFO_FAILED';

export const GET_CURRENT_SERVER_TIME_REQUESTED =
  'GET_CURRENT_SERVER_TIME_REQUESTED';
export const GET_CURRENT_SERVER_TIME_SUCCEEDED =
  'GET_CURRENT_SERVER_TIME_SUCCEEDED';
export const GET_CURRENT_SERVER_TIME_FAILED = 'GET_CURRENT_SERVER_TIME_FAILED';

export const GET_DATING_DAY_ABLE_TO_CHAT_REQUESTED =
  'GET_DATING_DAY_ABLE_TO_CHAT_REQUESTED';
export const GET_DATING_DAY_ABLE_TO_CHAT_SUCCEEDED =
  'GET_DATING_DAY_ABLE_TO_CHAT_SUCCEEDED';
export const GET_DATING_DAY_ABLE_TO_CHAT_FAILED =
  'GET_DATING_DAY_ABLE_TO_CHAT_FAILED';

export const GET_USER_GLOBAL_INFO_REQUESTED = 'GET_USER_GLOBAL_INFO_REQUESTED';
export const GET_USER_GLOBAL_INFO_SUCCEEDED = 'GET_USER_GLOBAL_INFO_SUCCEEDED';
export const GET_USER_GLOBAL_INFO_FAILD = 'GET_USER_GLOBAL_INFO_FAILD';

export const ACCESS_CHAT_ROOM_REQUESTED = 'ACCESS_CHAT_ROOM_REQUESTED';
export const ACCESS_CHAT_ROOM_SUCCEEDED = 'ACCESS_CHAT_ROOM_SUCCEEDED';
export const ACCESS_CHAT_ROOM_FAILED = 'ACCESS_CHAT_ROOM_FAILED';

export const UPDATE_DATING_STATUS_REQUESTED = 'UPDATE_DATING_STATUS_REQUESTED';
export const UPDATE_DATING_STATUS_SUCCEEDED = 'UPDATE_DATING_STATUS_SUCCEEDED';
export const UPDATE_DATING_STATUS_FAILED = 'UPDATE_DATING_STATUS_FAILED';

export const UPDATE_MULTIPLE_DATING_STATUS_REQUESTED =
  'UPDATE_MULTIPLE_DATING_STATUS_REQUESTED';
export const UPDATE_MULTIPLE_DATING_STATUS_SUCCEEDED =
  'UPDATE_MULTIPLE_DATING_STATUS_SUCCEEDED';
export const UPDATE_MULTIPLE_DATING_STATUS_FAILED =
  'UPDATE_MULTIPLE_DATING_STATUS_FAILED';

export const UPDATE_TEMP_CANCEL_TRIAL_REQUESTED =
  'UPDATE_TEMP_CANCEL_TRIAL_REQUESTED';
export const UPDATE_TEMP_CANCEL_TRIAL_SUCCEEDED =
  'UPDATE_TEMP_CANCEL_TRIAL_SUCCEEDED';
export const UPDATE_TEMP_CANCEL_TRIAL_FAILED =
  'UPDATE_TEMP_CANCEL_TRIAL_FAILED';

export const GET_USER_INFO_FB_PIXEL_REQUESTED =
  'GET_USER_INFO_FB_PIXEL_REQUESTED';
export const GET_USER_INFO_FB_PIXEL_SUCCEEDED =
  'GET_USER_INFO_FB_PIXEL_SUCCEEDED';
export const GET_USER_INFO_FB_PIXEL_FAILD = 'GET_USER_INFO_FB_PIXEL_FAILD';
export const SET_FIRST_INIT_FB_PIXEL_GENDER = 'SET_FIRST_INIT_FB_PIXEL_GENDER';

export const VALIDATE_SELECTING_REQUESTED = 'VALIDATE_SELECTING_REQUESTED';
export const VALIDATE_SELECTING_SUCCEEDED = 'VALIDATE_SELECTING_SUCCEEDED';
export const VALIDATE_SELECTING_FAILED = 'VALIDATE_SELECTING_FAILED';

export const SEND_EXCEPTION_NOTICE_REQUESTED =
  'SEND_EXCEPTION_NOTICE_REQUESTED';
export const SEND_EXCEPTION_NOTICE_SUCCEEDED =
  'SEND_EXCEPTION_NOTICE_SUCCEEDED';
export const SEND_EXCEPTION_NOTICE_FAILED = 'SEND_EXCEPTION_NOTICE_FAILED';

export const VALIDATE_BEFORE_UPDATE_PLAN_REQUESTED =
  'VALIDATE_BEFORE_UPDATE_PLAN_REQUESTED';
export const VALIDATE_BEFORE_UPDATE_PLAN_SUCCEEDED =
  'VALIDATE_BEFORE_UPDATE_PLAN_SUCCEEDED';
export const VALIDATE_BEFORE_UPDATE_PLAN_FAILED =
  'VALIDATE_BEFORE_UPDATE_PLAN_FAILED';

export const SUBSCRIPTION_PAUSED_REQUEST = 'SUBSCRIPTION_PAUSED_REQUEST';
export const SUBSCRIPTION_PAUSED_REQUEST_SUCCEEDED =
  'SUBSCRIPTION_PAUSED_REQUEST_SUCCEEDED';
export const SUBSCRIPTION_PAUSED_REQUEST_FAILED =
  'SUBSCRIPTION_PAUSED_REQUEST_FAILED';

export const UPDATE_USER_MOTIVATED_DATING_REQUESTED =
  'UPDATE_USER_MOTIVATED_DATING_REQUESTED';
export const UPDATE_USER_MOTIVATED_DATING_SUCCEEDED =
  'UPDATE_USER_MOTIVATED_DATING_SUCCEEDED';
export const UPDATE_USER_MOTIVATED_DATING_FAILED =
  'UPDATE_USER_MOTIVATED_DATING_FAILED';

export const GET_SCHEDULED_PLAN_REQUESTED = 'GET_SCHEDULED_PLAN_REQUESTED';
export const GET_SCHEDULED_PLAN_SUCCEEDED = 'GET_SCHEDULED_PLAN_SUCCEEDED';
export const GET_SCHEDULED_PLAN_FAILED = 'GET_SCHEDULED_PLAN_FAILED';

export const GET_AVAILABLE_FEEDBACK_FLEX_QUESTION_REQUESTED =
  'GET_AVAILABLE_FEEDBACK_FLEX_QUESTION_REQUESTED';
export const GET_AVAILABLE_FEEDBACK_FLEX_QUESTION_SUCCEEDED =
  'GET_AVAILABLE_FEEDBACK_FLEX_QUESTION_SUCCEEDED';
export const GET_AVAILABLE_FEEDBACK_FLEX_QUESTION_FAILED =
  'GET_AVAILABLE_FEEDBACK_FLEX_QUESTION_FAILED';

import { requestAPI } from './request';
import i18n from 'i18next';
import { store } from '../store';
import { homescreen } from '../actions';

import { getUserLanguage } from '../../helper/LanguageHelper';

window.__env__ = window.__env__ || {};

export const API = {
  POST: 'post',
  GET: 'get',
  PUT: 'put',
  BASE_URL: window.__env__.BASE_URL,
  GET_TIME: 'api/v2/current-time',
  SEND_VERIFICATION_CODE: 'api/v2/send-verification-code',
  VERIFY_CODE: 'api/v2/verify-code',
  API_LOGIN: 'api/v2/social-login',
  //sms login
  API_SMS_SEND_CODE: 'api/v2/send-verification-code',
  API_SMS_VERIFICATION_CODE: 'api/v2/verify-code',
  API_LOGIN_FACEBOOK: 'api/v2/auth/login/facebook',
  API_FACEBOOK_CALLBACK: 'api/v2/facebook/callback',
  API_LOGIN_LINE: 'api/v2/auth/login/line',
  API_LINE_CALLBACK: 'api/v2/line/callback',
  API_WEBHOOK_STRIPE: 'api/v2/webhook/stripe',

  HOME_SCREEN: 'api/v2/home-screen',
  MATCH_DETAIL: 'api/v2/dating/get-matched-details',
  MATCH_PROFILE: 'api/v2/user/match-profile',
  ADD_REQUEST_TIME: 'api/v2/dating/add-request-time',
  UPDATE_REQUEST_TIME: 'api/v2/dating/update-request-time',
  SET_LANGUAGE: 'api/v2/user/set-language',
  SET_PARTICIPATION: 'api/v2/date/participate',
  REFRESH_TOKEN: 'api/v2/generate-token',
  CANCEL_DATE: 'api/v2/dating/cancel-date',
  CANCELLED_BY_PARTNER: 'api/v2/dating/cancelled-by-partner',
  PARTICIPATE_REMATCHING: 'api/v2/participation/request-participate-rematch',
  CANCELLED_FOR_REMATCH: 'api/v2/participation/cancel-participate-rematch',
  DEVICE_TOKEN: 'api/v2/user/save-device-token',
  CUSTOMER_SUPPORT: 'api/v2/notify-admin',
  API_PARTICIPATION: 'api/v2/participation',
  DOCUMENT_UPLOAD: 'api/v2/user/upload-required-document',
  API_LOGOUT: '/api/v2/user/logout',
  DELETE_POPUP: '/api/v2/popup-delete',
  CANCEL_DATE_PARTNER: '/api/v2/dating/cancel-date-by-partner',
  GET_BODY_SHAPE: '/api/v2/dating/get-body-shape',
  GET_PREFRENCES: '/api/v2/dating/get-important-preferences',
  FEEDBACK: '/api/v2/feedback',
  // FEEDBACK: '/api/v2/dating/save-feedback-dating',
  FEEDBACK_DETAILS: '/api/v2/feedback/get-feedback-info',
  // FEEDBACK_DETAILS: '/api/v2/dating/feedback-details',
  GET_FEEDBACK: 'api/v2/get-previous-date-data',
  GET_USER_PROFILE: '/api/v2/user/get-profile',
  ACTIVE_PROFILE: '/api/v2/user/active-profile',
  USE_COUPON: '/api/v2/user/use-coupon',
  USE_IMAGE: '/api/v2/user/store-image',
  GET_POPUP: '/api/v2/get-popup',
  GET_DEACTIVATE_OPTIONS: 'api/v2/cancelDeactivateOptions',
  GET_CAFE: '/api/v2/dating-place/cafes-at-same-station',
  GET_NOTIFICATIONS: '/api/v2/notifications',
  GET_CHAT_ROOM: '/api/v2/chat/get-room',
  GET_TWILIO_TOKEN: '/api/v2/chat/generate-token',
  // GET_TWILIO_TOKEN: '/api/v2/chat/generate-twilio-token',
  SAVE_CHAT_HISTORY: '/api/v2/chat/send-message',
  GET_CHAT_MESSAGE: '/api/v2/chat/messages',
  // GET_CHAT_MESSAGE: '/api/v2/twilio/message',
  GET_CHAT_ROOM_V2: '/api/v2/chatkit/getRoomWithPartnerId', // This is API get Chat Room for web app.
  CONFIRM_CHANGE_COST_PLAN: '/api/v2/user/confirm-change-cost-plan',
  CANCEL_CHANGE_COST_PLAN: '/api/v2/user/cancel-change-cost-plan',
  DEACTIVE_PROFILE: '/api/v2/user/deactivate-profile',
  INVITE_FRIENDS: '/api/v2/user/get-user-invitation',
  MATCH_PROFILE_INFO: '/api/v2/get-match-profile-info-data',
  COUPON_LIST: '/api/v2/user/coupon',
  COUPON_PACKAGE: '/api/v2/user/coupon-package',
  EXCHANGE_COUPON: '/api/v2/user/coupon/exchange',
  APPLY_COUPON: '/api/v2/user/coupon/apply',
  EXTEND_TRIAL_BY_TRIAL_SUGGESTION:
    '/api/v2/user/extend-trial-by-trial-suggestion',
  REMATCH: '/api/v2/rematch',
  DATING_REPORT: 'api/v2/dating-report/dating-report-info',
  DATING_HISTORY_REPORT: '/api/v2/dating-report-history',
  DATING: '/api/v2/dating',
  //Match info
  MATCH_INFO: '/api/v2/user/match-info',

  //COUPON
  API_COUPON: '/coupon',

  // cancel/deactivation account
  VALIDATE_DEACTIVATE_CANCEL_ACCOUNT:
    '/api/v2/cancel-deactivate/validate-deactivate-cancel-account',
  CANCEL_ACCOUNT: '/api/v2/cancel-deactivate/cancel-account',
  DEACTIVATE_ACCOUNT: '/api/v2/cancel-deactivate/deactivate-account',
  CANCEL_DEACTIVATE_COMPLETE_DATING:
    '/api/v2/cancel-deactivate/completed-dating',
  UPDATE_TEMP_CANCEL_TRIAL:
    '/api/v2/cancel-deactivate/update-temp-cancel-trial',

  //reactive-reapprove-request
  API_REACTIVATE_RE_APPROVE: '/api/v2/user/status/reactive-reapprove-request',
  API_REACTIVATE_REAPPROVE: '/api/v2/user/status/reactive-reapprove-request',
  //registration form
  API_REGISTRATION_FORM: '/api/v2/user/registration',

  //Profile & preferences
  API_GET_MYPAGE_INFO: '/api/v2/user/get-mypage-info',
  API_GET_PROFILE_INFO: '/api/v2/user/get-profile-info',
  API_GET_PLAN_SUBSCRIPTION: '/api/v2/user/get-plan-subscription',
  API_UPDATE_PROFILE: '/api/v2/user/update-profile',
  API_GET_PREFERENCE: '/api/v2/user/get-preference-info',
  API_UPDATE_PREFERENCE: '/api/v2/user/update-preference',
  //Card
  API_CARDS: '/api/v2/user/cards',
  API_STRIPE: 'https://api.stripe.com/v1/',
  // API_STRIPE: 'https://api.stripe.com/v1/tokens'

  //Email Update
  API_UPDATE_EMAIL: '/api/v2/user/update-email',

  //global current server time
  API_GET_CURRENT_SERVER_TIME: '/api/v2/current-server-time',

  //get dating day able to chat
  GET_DATING_DAY_ABLE_TO_CHAT: '/api/v2/chat/get-chat-dating-day',

  //Plan
  API_PLAN: '/api/v2/user/plan',

  //chat room
  API_ACCESS_CHAT_ROOM: '/api/v2/chat/room',

  //User
  API_GLOBAL_USER: '/api/v2/user',
  //Plan
  API_GET_PLAN: '/api/v2/user/plan',
  API_CHANGE_PLAN: '/api/v2/user/plan/change/',
  //FB pixel
  API_GET_USER_INFO_FB_PIXEL: '/api/v2/user/get-user-info-for-fb-pixel',
  //Exception
  API_EXCEPTION: '/api/v2/exception',
  //Motivated dating
  API_UPDATE_SEEN_MOTIVATED_DATING: '/api/v2/user/update-seen-motivated-dating',

  //Subscription
  API_SUBSCRIPTION_PAUSED_REQUEST:
    '/api/v2/cancel-deactivate/pause-subscription',
  //Check condition change prefecture
  API_CONDITION_CHANGE_PREFECTURE_AND_AREA:
    '/api/v2/user/condition-change-prefecture-and-area',
  //change prefecture and area
  API_CHANGE_PREFECTURE_AND_AREA: '/api/v2/user/change-prefecture-and-area',

  //Feedback flex question
  API_FEEDBACK_FLEX_QUESTIONS: '/api/v2/feedback/get-available-flex-questions'
};

export const requestSetLanguage = (params, timeout) =>
  requestAPI(API.SET_LANGUAGE, API.POST, params, timeout);

export const requestHomeScreen = (lngId = 1) => {
  const i18nMap = {
    1: 'en',
    2: 'jp'
  };

  const languageId = getUserLanguage();

  const homescreenAction = homescreen({}, response => {
    i18n.changeLanguage(i18nMap[languageId]);
  });
  store.dispatch(homescreenAction);
};

import { call, put, takeLatest } from 'redux-saga/effects';
import * as types from '../actions/types';
import { requestAPI } from '../services/request';
import { API } from '../services/api';
import { ResponseStatus } from '../../constants/constant';
import { store } from '../store';
import { hideLoading, showLoading } from '../actions';

function* getCouponsData(action) {
  let response = null;
  store.dispatch(showLoading());
  try {
    response = yield call(requestAPI, API.COUPON_LIST, API.GET, action.params);

    if (response.status === ResponseStatus.SUCCESS) {
      yield put({ type: types.COUPON_LIST_SUCCEEDED, data: response.data });
    } else {
      yield put({ type: types.COUPON_LIST_FAILED, error: response });
    }
    if (action.onComplete) {
      action.onComplete(response);
    }
  } catch (error) {
    yield put({ type: types.COUPON_LIST_FAILED, error });
  }
  store.dispatch(hideLoading());
}

function* getCouponPackages(action) {
  let response = null;
  store.dispatch(showLoading());
  try {
    response = yield call(
      requestAPI,
      API.COUPON_PACKAGE,
      API.GET,
      action.params
    );

    if (response.status === ResponseStatus.SUCCESS) {
      yield put({
        type: types.GET_COUPON_PACKAGE_SUCCEEDED,
        data: response.data
      });
    } else {
      yield put({ type: types.GET_COUPON_PACKAGE_FAILED, error: response });
    }
    if (action.onComplete) {
      action.onComplete(response);
    }
  } catch (error) {
    yield put({ type: types.GET_COUPON_PACKAGE_FAILED, error });
  }
  store.dispatch(hideLoading());
}

function* retrieveUserCoupon(action) {
  let response = null;
  store.dispatch(showLoading());
  try {
    response = yield call(
      requestAPI,
      API.COUPON_LIST + `?${action.params.via}`,
      API.GET,
      action.params
    );

    if (response.status === ResponseStatus.SUCCESS) {
      yield put({ type: types.COUPON_LIST_SUCCEEDED, data: response.data });
    } else {
      yield put({ type: types.COUPON_LIST_FAILED, error: response });
    }
    if (action.onComplete) {
      action.onComplete(response);
    }
  } catch (error) {
    yield put({ type: types.COUPON_LIST_FAILED, error });
  }
  store.dispatch(hideLoading());
}

function* postExchangeCouponsData(action) {
  let response = null;
  store.dispatch(showLoading());
  try {
    response = yield call(
      requestAPI,
      API.EXCHANGE_COUPON + `/${action.params.type}`,
      API.POST,
      action.params.data
    );

    if (response.status === ResponseStatus.SUCCESS) {
      yield put({ type: types.EXCHANGE_COUPON_SUCCEDDED, data: response.data });
    } else {
      yield put({ type: types.EXCHANGE_COUPON_FAILED, error: response });
    }
    if (action.onComplete) {
      action.onComplete(response);
    }
  } catch (error) {
    yield put({ type: types.EXCHANGE_COUPON_FAILED, error });
  }
  store.dispatch(hideLoading());
}

function* useCoupon(action) {
  let response = null;
  store.dispatch(showLoading());
  try {
    response = yield call(
      requestAPI,
      API.APPLY_COUPON + `/${action.params.type}`,
      API.POST,
      action.params.data
    );

    if (response.status === ResponseStatus.SUCCESS) {
      yield put({ type: types.USE_COUPON_SUCCEEDED, data: response.data });
    } else {
      yield put({ type: types.USE_COUPON_FAILED, error: response });
    }
    if (action.onComplete) {
      action.onComplete(response);
    }
  } catch (error) {
    yield put({ type: types.USE_COUPON_FAILED, error });
  }
  store.dispatch(hideLoading());
}

function* purchaseCoupon(action) {
  let response = null;
  store.dispatch(showLoading());
  try {
    response = yield call(
      requestAPI,
      API.COUPON_PACKAGE + `/purchase/${action.params.coupon_package_id}`,
      API.POST,
      action.params,
      false //showGeneralPopup
    );

    if (response.status === ResponseStatus.SUCCESS) {
      yield put({ type: types.PURCHASE_COUPON_SUCCEEDED, data: response.data });
    } else {
      yield put({ type: types.PURCHASE_COUPON_FAILED, error: response });
    }
    if (action.onComplete) {
      action.onComplete(response);
    }
  } catch (error) {
    yield put({ type: types.PURCHASE_COUPON_FAILED, error });
  }
  store.dispatch(hideLoading());
}

export function* watchGetCouponsData() {
  yield takeLatest(types.COUPON_LIST_REQUEST, getCouponsData);
}

export function* watchExchangeCouponsData() {
  yield takeLatest(types.EXCHANGE_COUPON_REQUESTED, postExchangeCouponsData);
}

export function* watchApplyCoupon() {
  yield takeLatest(types.USE_COUPON_REQUESTED, useCoupon);
}

export function* watchRetrieveUserCoupon() {
  yield takeLatest(types.RETRIEVE_COUPON_REQUESTED, retrieveUserCoupon);
}

export function* watchPurchaseCoupon() {
  yield takeLatest(types.PURCHASE_COUPON_REQUESTED, purchaseCoupon);
}

export function* watchGetCouponPackages() {
  yield takeLatest(types.GET_COUPON_PACKAGE_REQUESTED, getCouponPackages);
}

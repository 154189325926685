import React, { Component } from 'react';
import styles from './index.module.css';

import PartnerTextMessage from '@components/ChatFeature/PartnerTextMessage';
import UserTextMessage from '@components/ChatFeature/UserTextMessage';
import CafeInformationMessage from '@components/ChatFeature/CafeInformationMessage';
import CafeSuggestionMessage from '@components/ChatFeature/CafeSuggestionMessage';
import ParkSuggestionMessage from '@components/ChatFeature/ParkSuggestionMessage';
import VideoSuggestionMessage from '@components/ChatFeature/VideoSuggestionMessage';
import ChatLink from '@components/ChatFeature/ChatLink';
import { ChatFeature as ChatFeatureConstants } from '@constants/constant';
import { ThreeDots } from '../../Loading';

const {
  USER_TYPE_BASE_USER,
  USER_TYPE_PARTNER_USER,
  USER_TYPE_CAFE_INFO,
  USER_TYPE_CAFE_SUGGESTION,
  USER_TYPE_PARK_SUGGESTION,
  USER_TYPE_VIDEO_SUGGESTION,
  USER_TYPE_CAFE_SUGGESTION_LINK,
  USER_TYPE_VIDEO_SUGGESTION_LINK
} = ChatFeatureConstants;
//
// Shows the interaction of a chat room session.
// -----------------------
class ChatRoom extends Component {
  constructor(props) {
    super(props);
    this.scrollToRef = React.createRef();
  }

  componentDidMount() {
    this.scrollToLatestMessage();
  }

  componentDidUpdate() {
    if (this.props.shouldScrollToLatestMessage) {
      this.scrollToLatestMessage();
    }
  }

  scrollToLatestMessage() {
    this.scrollToRef.current.scrollIntoView();
  }

  handleOnScroll = event => {
    if (event.target.scrollTop === 0) {
      this.props.fetchMoreMessages();
    }
  };

  render() {
    const { chatRoomIsReady, messages, partnerData } = this.props;
    const isWhiteMode = JSON.parse(localStorage.getItem('isWhiteMode'));
    return (
      <div className={styles.chatRoom} onScroll={this.handleOnScroll}>
        {messages.map((message, idx) => {
          if (!message) {
            return null;
          }
          switch (message.userType) {
            case USER_TYPE_BASE_USER:
              return (
                <UserTextMessage
                  isWhiteMode={isWhiteMode}
                  key={message.id}
                  message={message.text}
                  read={message.read}
                  onUnreadClick={message.onUnreadClick}
                  createDate={message.createDate}
                  createTime={message.createTime}
                />
              );
            case USER_TYPE_PARTNER_USER:
              return (
                <PartnerTextMessage
                  isWhiteMode={isWhiteMode}
                  key={message.id}
                  avatar={message.avatar}
                  message={message.text}
                  shouldBlurIcon={false}
                  createDate={message.createDate}
                  createTime={message.createTime}
                />
              );
            case USER_TYPE_CAFE_INFO:
              const cafeInfo = message.data;
              return (
                <CafeInformationMessage
                  key={idx}
                  cafeInfo={cafeInfo}
                  partnerData={partnerData}
                  isWhiteMode={isWhiteMode}
                />
              );
            case USER_TYPE_CAFE_SUGGESTION:
              const cafeSuggestion = message.data;
              return (
                <CafeSuggestionMessage
                  key={idx}
                  isWhiteMode={isWhiteMode}
                  cafeSuggestion={cafeSuggestion}
                />
              );
            case USER_TYPE_PARK_SUGGESTION:
              const parkSuggestion = message.data;
              return (
                <ParkSuggestionMessage
                  key={'ParkSuggestionMessage_' + idx}
                  isWhiteMode={isWhiteMode}
                  parkSuggestion={parkSuggestion}
                />
              );
            case USER_TYPE_VIDEO_SUGGESTION:
              const { onClickVideoMessage } = message.data;
              return (
                <VideoSuggestionMessage
                  key={'VideoSuggestionMessage_' + idx}
                  isWhiteMode={isWhiteMode}
                  onClickVideoMessage={onClickVideoMessage}
                />
              );
            case USER_TYPE_CAFE_SUGGESTION_LINK:
              const { text, onChatLinkClick } = message.data;
              return (
                <ChatLink
                  key={idx}
                  text={text}
                  isWhiteMode={isWhiteMode}
                  onChatLinkClick={onChatLinkClick}
                />
              );
            case USER_TYPE_VIDEO_SUGGESTION_LINK:
              const { videoMessage, onVideoLinkClick } = message.data;
              return (
                <ChatLink
                  key={idx}
                  isWhiteMode={isWhiteMode}
                  text={videoMessage}
                  onChatLinkClick={onVideoLinkClick}
                />
              );
            default:
              return null;
          }
        })}
        {!chatRoomIsReady && (
          <div className={styles.loadingMessageContainer}>
            <ThreeDots />
          </div>
        )}
        <span key="scroll" className={styles.scrollTo} ref={this.scrollToRef} />
      </div>
    );
  }
}
export default ChatRoom;

import * as types from '../actions/types';

const initialState = {
  isError: false,
  error: null
};

const tempCancelTrialReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.UPDATE_TEMP_CANCEL_TRIAL_REQUESTED:
      return {
        ...state,
        isError: false,
        error: null
      };
    case types.UPDATE_TEMP_CANCEL_TRIAL_SUCCEEDED:
      return {
        ...state,
        isError: false,
        error: null
      };
    case types.UPDATE_TEMP_CANCEL_TRIAL_FAILED:
      return {
        ...state,
        isError: true,
        error: action.error
      };
    default:
      return state;
  }
};

export default tempCancelTrialReducer;

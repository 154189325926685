import React from 'react';
import ReactSVG from 'react-svg';
import loadingSpinner from '../../assets/loading-spinner.svg';
import styles from './index.module.css';
import './styles.css';
import classNames from 'classnames';
import { ReactComponent as Loading } from '@assets/loading-spinner.svg';
import { Colors } from '../../constants/constant';

const LoadingSpinner = ({ className, isWhiteMode }) => (
  <div
    className={classNames({
      [styles.overlay]: true,
      [styles.overlayWhite]: isWhiteMode,
      [styles.overlayWhiteBlur]: isWhiteMode
    })}
  >
    <div
      className={classNames(styles.wrapper, {
        [className]: className,
        [styles.wrapperWhiteMode]: isWhiteMode
      })}
    >
      <Loading fill={isWhiteMode ? Colors.WHITE : '#5f4122'} />
    </div>
  </div>
);

const Spinner = ({ hidden, className }) =>
  hidden ? null : (
    <div className={className}>
      <ReactSVG src={loadingSpinner} />
    </div>
  );

const ThreeDots = () => (
  <div className="lds-ellipsis">
    <div />
    <div />
    <div />
    <div />
  </div>
);

export { LoadingSpinner, Spinner, ThreeDots };

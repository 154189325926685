import React, { Component } from 'react';
import { compose } from 'recompose';
import { withNamespaces } from 'react-i18next';
import DefaultPopup from '../DefaultPopup';
import Popup from '@components/Popup';
import {
  HowCanIBeSelectedTitleComponent,
  HowCanIBeSelectedMessagesComponent
} from '@components/MatchProfile/HowCanIBeSelected';

// TODO: Move me
class HowCanIBeSelectedPopup extends Component {
  render() {
    const { t } = this.props;
    return (
      <Popup
        visible={this.props.visible}
        TitleComponent={
          <HowCanIBeSelectedTitleComponent
            title={t('dialog-how-can-i-be-selected-title')}
          />
        }
        MessagesComponent={
          <HowCanIBeSelectedMessagesComponent
            message1={t('dialog-how-can-i-be-selected-message-1')}
            message2={t('dialog-how-can-i-be-selected-message-2')}
          />
        }
        okButtonTitle={t('dialog-how-can-i-be-selected-button')}
        onOkClicked={this.props.onOkClicked}
        onCancelClicked={this.props.onCancelClicked}
      />
    );
  }
}

const HowCanIBeSelectedPopupWithNamespace = compose(
  withNamespaces('matchprofile', { wait: true })
)(HowCanIBeSelectedPopup);

class PartnerNotFound extends Component {
  state = {
    show: true,
    showHowCanIBeSelectedPopup: false
  };

  onButtonClick = () => {
    this.props.onCloseClick();
  };

  onLinkClick = () => {
    this.setState({
      show: false,
      showHowCanIBeSelectedPopup: true
    });
  };

  onCancelClick = () => {
    this.props.onCloseClick();
  };

  renderHowCanIBeSelectedPopup = () => {
    return (
      <HowCanIBeSelectedPopupWithNamespace
        visible={this.state.showHowCanIBeSelectedPopup}
        onOkClicked={this.onButtonClick}
        onCancelClicked={this.onCancelClick}
      />
    );
  };

  renderMessage = () => {
    const message = (this.props.popup && this.props.popup.message) || '';
    if (message.length === 0) {
      return null;
    }
    const messages = message.split(/\\n/g);
    const maxLength = messages.length;
    return messages.map((msg, index) => {
      if (msg.length && index + 1 === maxLength) {
        if (message.includes('どうしたら選ばれやすくなるの？')) {
          return (
            <>
              <ins onClick={this.onLinkClick}>{msg}</ins>
            </>
          );
        }
        return msg;
      }
      if (msg.length) {
        return (
          <>
            {msg}
            <br />
          </>
        );
      }
      return <br />;
    });
  };

  render() {
    const { popup } = this.props;
    return (
      <>
        <DefaultPopup
          show={this.state.show}
          popup={popup}
          onButtonClick={this.onButtonClick}
          onCloseClick={this.props.onCloseClick}
          renderMessage={this.renderMessage}
        />
        {this.renderHowCanIBeSelectedPopup()}
      </>
    );
  }
}

export default PartnerNotFound;
